import React, { useContext } from 'react'
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input, FormText, Modal, ModalHeader, ModalBody, CardTitle, UncontrolledTooltip } from 'reactstrap'
import tagimage from "../assets/img/tagimage.png"
import BasicEditForm from "../components/BasicEditForm"
import { Machine, assign } from 'xstate';
import { useMachine } from '@xstate/react';
import axios from "axios";
import { CheckCircleIcon, CloseCircleIcon, InformationOutlineIcon } from 'mdi-react'
import LoadingContainer from '../components/LoadingContainer'
var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');

const DeactivateContext = React.createContext(null);

function invokeDeactivate(context) {
    return axios({
        method: 'post',
        data: context.formData,
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/deactivate`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    });
}

const deactivatMachine = Machine({
    id: 'deactivateMachine',
    initial: 'welcome',
    states: {
        welcome: {
            on: { NEXT: 'selecting_data' }
        },
        selecting_data: {
            initial: 'filling',
            states: {
                filling: {
                    on: { CONFIRM: 'confirming' }
                },
                confirming: {
                    on: { CANCEL: 'filling' }
                }
            },
            on: { PREV: 'welcome', NEXT: 'sending_data', TYPING: { actions: 'typing' } }
        },
        sending_data: {
            invoke: {
                id: 'sending-data',
                src: invokeDeactivate,
                onDone: 'success',
                onError: { target: 'error', actions: assign({ errorData: (context, event) => event.data.response.data }) }
            }
        },
        error: {
            on: {
                RETRY: 'selecting_data'
            }
        },
        success: {}
    }
}, {
        actions: {
            typing: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: event.value.toUpperCase() }) })
        }
    })

function DeactivateTag(props) {
    const [state, send] = useMachine(deactivatMachine)
    return (
        <DeactivateContext.Provider value={{ state, send }}>
            <div style={{ backgroundColor: 'lightgrey', display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ width: '98%%', maxWidth: '400px', height: '75%', minHeight: '600px', display: 'flex', flexDirection: 'column' }}>
                    <div className="p-5" style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexDirection: 'column', color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)' }}>
                        <img src={tagimage} alt="tag" style={{ height: '90px', objectFit: 'contain' }} />
                        <div style={{ fontSize: '18px' }} className="font-weight-light mt-4">Programar Inactividad</div>
                    </div>
                    {state.matches('welcome') &&
                        <WelcomeView />}
                    {state.matches('selecting_data') &&
                        <FormView />}
                    {state.matches('success') &&
                        <SuccessView />}
                    {state.matches('sending_data') &&
                        <LoadingView />}
                    {state.matches('error') &&
                        <ErrorView />}
                </Card>
            </div >
        </DeactivateContext.Provider >
    )
}

function WelcomeView(props) {
    const { send } = useContext(DeactivateContext)
    return (
        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
            <p className="font-weight-light" style={{ color: 'dimgray', display: 'flex', alignItems: 'center', flex: 1, textAlign: 'justify', textJustify: 'inter-word', fontSize: '18px', paddingLeft: '7%', paddingRight: '7%' }}>
                Sabemos que su rutina puede cambiar constantemente dentro del hospital.
                <br /><br />
                Si su rotación actual no es en ninguno de los departamentos con Aidy, por favor notifíquelo en esta aplicación.
                <br /><br />
                Esto evitará que su Tag aparezca como inactivo o sin batería en las pantallas de retroalimentación.
            </p>
            <Button onClick={() => send('NEXT')} size="lg" color="success" block>Continuar</Button>
        </CardBody>
    )
}

function LoadingView(props) {
    return (
        <CardBody className="p-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <LoadingContainer isLoading={true}>
                <div style={{ width: '100%', height: '100%' }}></div>
            </LoadingContainer>
        </CardBody>
    )
}

function SuccessView(props) {
    const { state } = useContext(DeactivateContext)
    const { formData } = state.context
    return (
        <CardBody className="p-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <CheckCircleIcon className="mb-5" size={45} style={{ color: 'green' }} />
            <React.Fragment>
                <p className="font-weight-bold mb-2" style={{ fontSize: '18px' }}>Programación Exitosa</p>
                <p className="text-center font-weight-light">Las notificaciones del Aidy {formData.TAG_ADDRESS} estarán desactivadas hasta el {moment(formData.PERIOD).format("DD MMMM")} </p>
            </React.Fragment>
        </CardBody>
    )
}

function ErrorView(props) {
    const { state, send } = useContext(DeactivateContext)
    const { errorData } = state.context
    return (
        <CardBody className="p-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <CloseCircleIcon className="mb-5" size={45} style={{ color: 'red' }} />
            <React.Fragment>
                <p className="font-weight-bold mb-2" style={{ fontSize: '18px' }}>{errorData.name}</p>
                <p className="text-center font-weight-light">{errorData.message}</p>
                <Button color="primary" onClick={() => send('RETRY')}>Reintentar</Button>
            </React.Fragment>
        </CardBody>
    )
}

function FormView(props) {
    const { state, send } = useContext(DeactivateContext)
    const { formData = {} } = state.context;

    return (
        <CardBody className="pt-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
            {state.matches({ selecting_data: 'confirming' }) &&
                <ConfirmDeactivation isOpen={true} />
            }
            <BasicEditForm onValidSubmit={() => send('CONFIRM')} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Row form>
                    <Col xs={6}>
                        <FormGroup>
                            <Label>Nombre(s)</Label>
                            <Input required type="text" value={formData.NAME || ""} name="NAME" autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label>Apellido(s)</Label>
                            <Input required type="text" value={formData.LAST_NAME || ""} name="LAST_NAME" autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>Número de Aidy</div>
                                <div>
                                    <span style={{ textDecoration: "underline", color: "blue" }} href="#" id="UncontrolledTooltipExample"><InformationOutlineIcon className="icon" size={18} /></span>
                                    <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                                        Número de 12 dígitos<br />
                                        Se encuentra en la parte inferior de su Aidy
                                    </UncontrolledTooltip>
                                </div>
                            </Label>
                            <Input required type="text" value={formData.TAG_ADDRESS || ""} name="TAG_ADDRESS" autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                            <FormText color="muted">Ej. 77aa60XXXXXX</FormText>
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>Periodo</div>
                                <div>
                                    <span style={{ textDecoration: "underline", color: "blue" }} href="#" id="UncontrolledTooltipExample2"><InformationOutlineIcon className="icon" size={18} /></span>
                                    <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample2">
                                        Tiempo que estará sin actividad en unidades Aidy
                                    </UncontrolledTooltip>
                                </div>
                            </Label>
                            <Input required type="select" value={formData.PERIOD || ""} name="PERIOD" autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }}>
                                <option value="" disabled>Seleccionar</option>
                                <option value={moment().add(1, 'week').format("YYYY-MM-DD")}>1 semana</option>
                                <option value={moment().add(2, 'week').format("YYYY-MM-DD")}>2 semanas</option>
                                <option value={moment().add(3, 'week').format("YYYY-MM-DD")}>3 semanas</option>
                                <option value={moment().add(1, 'month').format("YYYY-MM-DD")}>1 mes</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>Razón</Label>
                            <Input required type="textarea" value={formData.REASON || ""} name="REASON" autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Button onClick={() => send('PREV')} size="lg" color="secondary" block>Atras</Button>
                    </Col>
                    <Col xs={6}>
                        <Button size="lg" color="success" block>Continuar</Button>
                    </Col>
                </Row>

            </BasicEditForm>
        </CardBody>
    )
}

function ConfirmDeactivation(props) {
    const { state, send } = useContext(DeactivateContext)
    const { formData } = state.context
    return (
        <Modal isOpen={props.isOpen} size="sm" centered>
            <ModalHeader>
                <CardTitle>Confirmar Inactividad</CardTitle>
            </ModalHeader>
            <ModalBody>
                <p className="text-center"><b>Este Aidy no aparecerá</b> en las listas de inactividad en las <b>Pantallas de Retroalimentación</b> hasta después de {moment(formData.PERIOD).format("DD MMMM")}<br />¿Desea confirmar la información?</p>
                <Row>
                    <Col xs={6}>
                        <Button block onClick={() => send('CANCEL')}>Cancelar</Button>
                    </Col>
                    <Col xs={6}>
                        <Button color="primary" block onClick={() => send('NEXT')}>Confirmar</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default DeactivateTag
