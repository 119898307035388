import React, { useEffect, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import { HorizontalBar } from "react-chartjs-2";
import CardBody from "reactstrap/lib/CardBody";
import LoadingContainer from "../../../../components/LoadingContainer";

var moment = require("moment-timezone");
require("moment/locale/es");
moment.locale("es");
moment.tz.setDefault("America/Monterrey");

function ComplianceDepartmentCard(props) {
  const { data, loading = false, oportunity = null, view = "percentage" } = props;

  const [maximo, setMaximo] = useState();
  useEffect(() => {
    if (view == "percentage") setMaximo(100);
    else
      switch (oportunity) {
        case "in":
        case "out":
          setMaximo(Math.max(...data.map((element) => element.VISITS)) + 10);
          break;
        default:
          setMaximo(Math.max(...data.map((element) => element.VISITS)) * 2 + 10);
      }
  }, [data, view, oportunity]);

  return (
    <Card>
      <CardHeader className="card-header-divider">Departamentos</CardHeader>
      <CardBody>
        <LoadingContainer isLoading={loading}>
          <div style={{ height: `${(data ? data.length * 65 : 300) + 40}px`, minHeight: "300px" }}>
            <HorizontalBar
              data={{
                datasets: [
                  {
                    data: data.map((element) => {
                      if (view == "percentage")
                        switch (oportunity) {
                          case "in":
                            return parseFloat(element.IN).toFixed(2);
                          case "out":
                            return parseFloat(element.OUT).toFixed(2);
                          default:
                            return parseFloat(element.TOTAL).toFixed(2);
                        }
                      else
                        switch (oportunity) {
                          case "in":
                            return parseFloat(element.COUNT_IN);
                          case "out":
                            return parseFloat(element.COUNT_OUT);
                          default:
                            return parseFloat(element.COUNT_IN) + parseFloat(element.COUNT_OUT);
                        }
                    }),
                    backgroundColor: "#24c7a6",
                    label: "Con Higiene",
                  },
                  {
                    data: data.map((element) => {
                      if (view == "percentage")
                        switch (oportunity) {
                          case "in":
                            return (100 - parseFloat(element.IN)).toFixed(2);
                          case "out":
                            return (100 - parseFloat(element.OUT)).toFixed(2);
                          default:
                            return (100 - parseFloat(element.TOTAL)).toFixed(2);
                        }
                      else
                        switch (oportunity) {
                          case "in":
                            return parseFloat(element.VISITS) - parseFloat(element.COUNT_IN);
                          case "out":
                            return parseFloat(element.VISITS) - parseFloat(element.COUNT_OUT);
                          default:
                            return 2 * parseFloat(element.VISITS) - (parseFloat(element.COUNT_IN) + parseFloat(element.COUNT_OUT));
                        }
                    }),
                    backgroundColor: "#E5E5E5",
                    label: "Sin Higiene",
                  },
                ],
                labels: data.map((element) => element.DEPARTMENT_NAME),
              }}
              height={null}
              width={null}
              options={{
                //aspectRatio: 1.5,
                maintainAspectRatio: false,
                legend: {
                  labels: { fontSize: 10, boxWidth: 20 },
                  //display: false
                },
                scales: {
                  xAxes: [
                    {
                      scaleLabel: {
                        padding: 10,
                        fontSize: 10,
                        display: true,
                        labelString: view == "percentage" ? "Apego Higiene de Manos (%)" : "Oportunidades Higiene de Manos",
                      },
                      gridLines: {
                        tickMarkLength: 0,
                      },
                      ticks: {
                        display: false,
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        min: 0,
                        max: maximo,
                      },
                      stacked: true,
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        display: false,
                        tickMarkLength: 5,
                      },
                      ticks: {
                        fontSize: 11,
                      },
                      stacked: true,
                    },
                  ],
                },
              }}
            />
          </div>
        </LoadingContainer>
      </CardBody>
    </Card>
  );
}

export default ComplianceDepartmentCard;
