// url: `${process.env.REACT_APP_API_ENDPOINT}/api/gateways_positions/${props.gateway.ID_POS_GATEWAY}`
//url: `${process.env.REACT_APP_API_ENDPOINT}/api/departments/${props.match.params.idDepartment}/gateways/${props.gateway.BLE_ADDRESS}/reset`,
import React, { useContext, useState, useEffect } from 'react'
import { FormGroup, Input, Label, Row, Col, Modal, ModalHeader, ModalBody, CardTitle, Button } from 'reactstrap'
import axios from "axios";
import { LoadingContainer } from '../../../../../components/LoadingContainer';
import DepartmentsContext from '../DepartmentsContext'
import { useQuery, queryCache } from 'react-query'
import StatusChart from './StatusChart';

async function fetchGateway(key, ID_GATEWAY) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/gateways/${ID_GATEWAY}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

function sendOTA(ID_GATEWAY) {
    axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/gateways/${ID_GATEWAY}/OTA`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
}

function DepartmentGatewaysEdit(props) {
    const [id, setID] = useState(props.ID_GATEWAY);
    const { status, data = {}, error, isFetching, refetch } = useQuery(['gateway', id], fetchGateway)

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader toggle={props.onCancel}>
                <CardTitle>Configurar Gateway</CardTitle>
            </ModalHeader>
            {error ?
                <ModalBody>
                    <p className="text-center mt-5 text-bold">{error.name}</p>
                    <p className="text-center mb-5">{error.message}</p>
                </ModalBody>
                :
                <LoadingContainer message={"Cargando..."} isLoading={status == "loading"}>
                    <ModalBody>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>Gateway Address</Label>
                                    <CardTitle>{data.BLE_ADDRESS || ""}</CardTitle>
                                </FormGroup>
                            </Col >
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>Firmware</Label>
                                    <CardTitle>{data.FIRMWARE || ""}</CardTitle>
                                </FormGroup>
                            </Col >
                        </Row>
                        <hr />
                        <StatusChart ID_GATEWAY={data.ID_GATEWAY}/>
                        <hr/>
                        <Label>Comandos MQTT</Label>
                        <Row>
                            <Col sm={6}>
                                <Button className="mb-2" color="primary" block onClick={() => sendOTA(data.ID_GATEWAY)}>OTA</Button>
                            </Col>
                            <Col sm={6}>
                                <Button className="mb-2" color="warning" block>Reset</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </LoadingContainer>}
        </Modal>
    )
}

export default DepartmentGatewaysEdit
