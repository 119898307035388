import React, { useState, useEffect } from 'react'
import { BasicEditForm } from '../../../../components/BasicEditForm'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import useRequestReducer from '../../../../hooks/useRequestReducer';
import { Container, Button, Card, CardTitle, CardHeader, CardBody, Col, Label, FormGroup, FormFeedback, Input } from 'reactstrap'
import CreatableSelect from 'react-select/lib/Creatable';
import { Can } from '../../../../AbilityContext'

function ResourcesEdit(props) {
    const [newValue, setNewValue] = useState('');
    const [originalData, setOriginalData] = useState({});
    const [formData, setFormData] = useState({});
    const [getData, dispatchGet] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/resources/${props.match.params.id}`
    });

    useEffect(() => {
        if (getData.response) {
            setOriginalData(getData.response.data)
            setOriginalData({
                ...getData.response.data, PERMISSIONS: JSON.parse(getData.response.data.PERMISSIONS).map(element => {
                    return { label: element, value: element }
                })
            });
            setFormData({
                ...getData.response.data, PERMISSIONS: JSON.parse(getData.response.data.PERMISSIONS).map(element => {
                    return { label: element, value: element }
                })
            })
        }
    }, [getData.response])

    const [updateData, dispatchUpdate] = useRequestReducer({
        method: 'put',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/resources/${props.match.params.id}`
    });

    const [deleteData, dispatchDelete] = useRequestReducer({
        method: 'delete',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/resources/${props.match.params.id}`
    });

    useEffect(() => {
        if (deleteData.response) {
            if (deleteData.response.status === 200) {
                props.history.push({ pathname: `/resources` })
            }
        }
    }, [deleteData.response])

    useEffect(() => {
        dispatchGet({ type: 'SEND_REQUEST' });
    }, [updateData.response])

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        })
    };

    //Select Related
    const createOption = label => ({
        label,
        value: label,
    });

    const handleSelectorInputChange = inputValue => {
        setNewValue(inputValue)
    };

    const handleKeyDown = (event) => {
        if (!newValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setFormData({ ...formData, PERMISSIONS: [...formData.PERMISSIONS, createOption(newValue)] });
                setNewValue("");
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    return (
        <Container fluid>
            <Card>
                <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', minHeight: '70px' }}>
                    <CardHeader className="card-header-divider" >
                        <CardTitle>
                            Editar Resource
                            {!getData.loading ? <Can I="delete" a="RESOURCES"><Button color="danger" className="float-right" onClick={() => dispatchDelete('SEND_REQUEST')}>Eliminar</Button></Can> : null}
                        </CardTitle>
                    </CardHeader>
                </div>
                <CardBody>
                    <LoadingContainer 
                    isLoading={getData.loading || updateData.loading || deleteData.loading}
                    hasError={getData.error!==null}
                    onRetry={()=>dispatchGet({type:'SEND_REQUEST'})}>
                        <BasicEditForm
                            onValidSubmit={() => dispatchUpdate({ type: 'SEND_REQUEST', request: { data: { ...formData, PERMISSIONS: JSON.stringify(formData.PERMISSIONS.map(element => element.value)) } } })} >
                            <Can I="update" a="RESOURCES" passThrough>
                                {can =>
                                    <fieldset disabled={!can}>
                                        <FormGroup row>
                                            <Label sm={3} className="text-sm-right">Nombre</Label>
                                            <Col sm={8} lg={6}><Input type="text" name="NAME" value={formData.NAME || ""} onChange={handleInputChange} required />
                                                <FormFeedback>Es necesario proporcionar un Nombre al Resource</FormFeedback></Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={3} className="text-sm-right">Permisos</Label>
                                            <Col sm={8} lg={6}>
                                                <CreatableSelect
                                                    placeholder="Escriba las opciones y presione Enter"
                                                    components={{
                                                        DropdownIndicator: null
                                                    }}
                                                    isClearable
                                                    isMulti
                                                    menuIsOpen={false}
                                                    onChange={(value) => setFormData({ ...formData, PERMISSIONS: value })}
                                                    onInputChange={handleSelectorInputChange}
                                                    onKeyDown={handleKeyDown}
                                                    value={formData.PERMISSIONS}
                                                />
                                                <FormFeedback>Indique los Permisos</FormFeedback></Col>
                                        </FormGroup>
                                        {JSON.stringify(originalData) !== JSON.stringify(formData) ?
                                            <FormGroup>
                                                <Can I="update" a="RESOURCES"><Button block color="primary">Guardar Cambios</Button></Can>
                                            </FormGroup>
                                            : null}
                                    </fieldset>}
                            </Can>
                        </BasicEditForm>
                    </LoadingContainer>
                </CardBody>
            </Card>
        </Container>
    )
}

export default ResourcesEdit