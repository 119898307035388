import React, { useEffect } from 'react'
import { Card, CardHeader, CardBody, CardTitle, Button } from 'reactstrap'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import axios from "axios";
import TableSearch from '../../../../components/TableSearch';
import { useQuery} from 'react-query'
import { LoadingContainer } from '../../../../components/LoadingContainer';
var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');

async function fetchDepartmentsList() {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/departments`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

const fetchMachine = Machine({
    id: 'fetch',
    initial: 'idle',
    states: {
        idle: {
            on: {
                ADD: 'adding'
            }
        },
        adding: {}
    },
    on: {
        DATA_CHANGED: { target: '.idle', actions: assign({ listData: (context, event) => event.data }) },
        CANCEL: '.idle',
        SUCCESS: '.idle'
    }
});


function DepartmentsList(props) {
    const { status, data,  isFetching, refetch } = useQuery('departments', fetchDepartmentsList, { retry: false })
    const [state, send] = useMachine(fetchMachine, { context: { listData: data } })
    const { listData = [] } = state.context;

    useEffect(() => {
        send('DATA_CHANGED', { data: data })
    }, [data])


    return (
        <Card>
            {/*state.matches('adding') &&
                <UsersCreate isOpen={true} onSuccess={(id) => { send('SUCCESS'); queryCache.invalidateQueries('departments'); props.history.push({ pathname: `/departments/${id}` }) }} onCancel={() => send('CANCEL')} />*/}
            <CardHeader >
                <CardTitle>
                    Departamentos
                    {/*listData.length > 0 && <Button disabled={!state.matches('idle') || isFetching || error != null} color="success" className="float-right" onClick={() => send('ADD')}>Nueva</Button>*/}
                    {isFetching &&
                        <div className="float-right mr-2">...</div>
                    }
                </CardTitle>
            </CardHeader>
            {status === "loading" ?
                <CardBody>
                    <LoadingContainer isLoading={true}>
                        <div style={{ minHeight: '470px' }}></div>
                    </LoadingContainer>
                </CardBody>
                : null}
            {status === "error" ?
                <CardBody>
                    <LoadingContainer hasError={true} onRetry={() => refetch({ force: true })}>
                        <div style={{ minHeight: '470px' }}></div>
                    </LoadingContainer>
                </CardBody>
                : null}
            {status === "success" ?
                <div>
                    {listData.length === 0 ?
                        <CardBody>
                            <div style={{ height: '150px', top: '0px', bottom: '0px', left: '0px', right: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <p>Agregar un Departamento</p>
                                <Button disabled={!state.matches('idle')} className="float-right" color="success" onClick={() => send('ADD')}>Agregar</Button>
                            </div>
                        </CardBody> :
                        <TableSearch
                            height="65vh"
                            keyField="ID_DEPARTMENT"
                            placeholder="Buscar Departamento"
                            data={listData || []}
                            columns={[{
                                text: 'Nombre',
                                dataField: 'NAME',
                                sort: true,
                                formatExtraData: props.match.params.id,
                                formatter: (data, row, index, extra) => {
                                    if (row.ID_DEPARTMENT === parseInt(extra, 10))
                                        return (<b>{`${row.NAME}`}</b>)
                                    else return row.NAME
                                }
                            },{
                                text:'Detalles',
                                classes:'text-right font-weight-light',
                                headerClasses:'text-right',
                                dataField:'GATEWAYS',
                                formatter:(data,row)=>{
                                    return <div>{row.ZONES | 0} Zonas<br/>{row.GATEWAYS | 0} Gateways<br/>{row.DISPENSERS | 0} Dispensadores</div>
                                }
                            }]}
                            selectRow={{
                                mode: 'radio',
                                hideSelectColumn: true,
                                clickToSelect: true,
                                onSelect: (row, isSelect, rowIndex, e) => {
                                    props.history.push({ pathname: `/departments/${row.ID_DEPARTMENT}/` })
                                }
                            }}
                        />}
                </div> : null}
        </Card>
    )
}

export default DepartmentsList
