import React, { useContext,useState,useEffect } from 'react'
import { FormGroup, Input, Label, Row, Col, Modal, ModalHeader, ModalBody, CardTitle, Button, ButtonGroup } from 'reactstrap'
import Map from '../../../../../components/Map';
import { Scatter } from 'react-chartjs-2';
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import axios from "axios";
import { ArrowUpIcon, ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon } from 'mdi-react'
import { LoadingContainer } from '../../../../../components/LoadingContainer';
import { BasicEditForm } from '../../../../../components/BasicEditForm';
import DepartmentsContext from '../DepartmentsContext'

function invokePost(context) {
    return axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/departments/${context.formData.ID_DEPARTMENT}/dispensers`,
        data: context.formData,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    });
}

const addMachine = Machine({
    initial: 'selecting_data',
    states: {
        selecting_data: {
            on: {
                TYPING: { actions: 'typing' },
                CONFIRM_DATA: 'sending_data',
                SELECT_CHANGE: { actions: 'select_changed' },
                INCREMENT: { actions: 'increment' },
                DECREMENT: { actions: 'decrement' },
            }
        },
        sending_data: {
            invoke: {
                id: 'sending-data',
                src: 'postData',
                onDone: {
                    target: '',
                    actions: (context, event) => { context.onSuccess(event.data.data.insertId) } //Close Modal
                },
                onError: { target: 'error', actions: [assign({ errorData: (context, event) => event.data.response.data })] }
            }
        },
        error: {
            on: {
                RETRY: 'selecting_data'
            }
        }
    }
}, {
        actions: {
            typing: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: event.value }) }),
            select_changed: assign({
                formData: (context, event) => {
                    const value = parseInt(event.value, 10)
                    return { ...context.formData, [event.name]: value }
                }
            }),
            increment: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: parseFloat(context.formData[event.name]) + .1 }) }),
            decrement: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: parseFloat(context.formData[event.name]) - .1 }) })
        },
        services: {
            postData: invokePost
        }
    })


function DepartmentDispenserNewModal(props) {
    const { state: stateDepartment } = useContext(DepartmentsContext)
    const { originalData: department } = stateDepartment.context;
    const [state, send] = useMachine(addMachine, { context: { onSuccess: props.onSuccess, formData: { ID_DEPARTMENT: department.ID_DEPARTMENT, ID_EVENT_TYPE: 1, X: department.MAP_SIZE_X / 2 | 0, Y: department.MAP_SIZE_Y / 2 | 0 } } })
    const { formData, errorData = {} } = state.context

    const [locations, setLocations] = useState([]);
    useEffect(() => {
        const arrayOfLocations = [];
        for (var x = 0; x < department.MAP_SIZE_X; x += 1) {
            for (var y = 0; y < department.MAP_SIZE_Y; y += 1) {
                arrayOfLocations.push({ x: x, y: y });
            }
        }
        setLocations(arrayOfLocations);
    }, [department])

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader toggle={props.onCancel}>
                <CardTitle>Configurar Dispensador</CardTitle>
            </ModalHeader>
            {state.matches('error') ?
                <ModalBody>
                    <p className="text-center mt-5 text-bold">{errorData.name}</p>
                    <p className="text-center mb-5">{errorData.message}</p>
                    <Button block color="primary" onClick={() => send('RETRY')}>Reintentar</Button>
                </ModalBody>
                :
                <LoadingContainer message={state.matches("sending_data") ? "Configurando dispensador..." : "Cargando..."} isLoading={state.matches('loading') || state.matches('sending_data')}>
                    {state.matches('sending_data') ?
                        <div style={{ minHeight: '150px' }}>
                        </div> : null}
                    {state.matches('selecting_data') ?
                        <ModalBody>
                            <BasicEditForm onValidSubmit={() => send('CONFIRM_DATA')}>
                                <Row form>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>Dispenser Address</Label>
                                            <Input type="text" name="BLE_ADDRESS" value={formData.BLE_ADDRESS || ""} onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} required autoComplete="off" />
                                        </FormGroup>
                                    </Col >
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Tipo de Dispensador</Label>
                                            <br />
                                            <ButtonGroup style={{ display: 'flex', alignSelf: 'center' }}>
                                                <Button style={{ flex: 1 }} onClick={(event) => { send('TYPING', { value: 1, name: "ID_EVENT_TYPE" }) }} active={formData.ID_EVENT_TYPE === 1}>Alcohol</Button>
                                                <Button style={{ flex: 1 }} onClick={(event) => { send('TYPING', { value: 2, name: "ID_EVENT_TYPE" }) }} active={formData.ID_EVENT_TYPE === 2}>Jabón</Button>
                                            </ButtonGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>Posición</Label>
                                            <div style={{ display: 'flex', alignItems: 'center', maxWidth: '450px', marginRight: 'auto', marginLeft: 'auto' }}>
                                                <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                                                    <div className="mb-3" style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Button onClick={(event) => { send('DECREMENT', { name: "X" }) }}><ArrowLeftIcon className="icon" size={15} /></Button>
                                                        <Input required className="mx-2" type="number" name="X" style={{ maxWidth: '80px' }} value={formData.X || ""} onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} autoComplete="off" />
                                                        <Button onClick={(event) => { send('INCREMENT', { name: "X" }) }}><ArrowRightIcon className="icon" size={15} /></Button>
                                                    </div>
                                                    <div>
                                                        <Map sizex={department.MAP_SIZE_X} sizey={department.MAP_SIZE_Y} mapImageURL={department.MAP_IMAGE_URL}>
                                                            <Scatter
                                                                getElementAtEvent={
                                                                    element => {
                                                                        if (element.length > 0) {
                                                                            send('TYPING', { value: locations[element[0]._index].x, name: "X" })
                                                                            send('TYPING', { value: locations[element[0]._index].y, name: "Y" })
                                                                        }
                                                                    }}
                                                                data={{
                                                                    datasets: [{
                                                                        label: 'My First dataset',
                                                                        data: locations,
                                                                        pointBackgroundColor: 'rgba(0,0,0,0)',
                                                                        pointBorderColor: 'rgba(0,0,0,0)',
                                                                        pointBorderWidth: 1,
                                                                        pointRadius: 3,
                                                                        pointHoverRadius: 6,
                                                                        pointHoverBackgroundColor: 'rgba(1,1,1,1)',
                                                                        pointHoverBorderColor: 'rgba(1,1,1,1)',
                                                                        fill: false,
                                                                        showLine: false,
                                                                    }, {
                                                                        label: 'Position',
                                                                        data: [{ x: formData.X, y: formData.Y }],
                                                                        pointRadius: 6,
                                                                        pointBackgroundColor: 'rgba(1,1,1,1)',
                                                                        pointBorderColor: 'rgba(1,1,1,1)',
                                                                    }]
                                                                }} options={{
                                                                    animation: false,
                                                                    legend: { display: false },
                                                                    scales: {
                                                                        xAxes: [{
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                display: false, stepSize: 1.0, beginAtZero: true, max: department.MAP_SIZE_X, min: 0
                                                                            },
                                                                            gridLines: {
                                                                                color: 'rgba(100,100,100,.2)',
                                                                                tickMarkLength: 0
                                                                            }
                                                                        }],
                                                                        yAxes: [{
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                display: false, stepSize: 1.0, beginAtZero: true, max: department.MAP_SIZE_Y, min: 0
                                                                            },
                                                                            gridLines: {
                                                                                color: 'rgba(100,100,100,.2)',
                                                                                tickMarkLength: 0
                                                                            }
                                                                        }]
                                                                    },
                                                                    maintainAspectRatio: false
                                                                }} />
                                                        </Map>
                                                    </div>
                                                </div>
                                                <div className="ml-3" style={{ paddingTop: '48px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button onClick={(event) => { send('INCREMENT', { name: "Y" }) }}><ArrowUpIcon className="icon" size={15} /></Button>
                                                    <Input required className="my-2" type="number" name="Y" style={{ maxWidth: '80px' }} value={formData.Y || ""} onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} autoComplete="off" />
                                                    <Button onClick={(event) => { send('DECREMENT', { name: "Y" }) }}><ArrowDownIcon className="icon" size={15} /></Button>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Button block color="success">Posicionar Dispensador</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </BasicEditForm>
                        </ModalBody> : null}
                </LoadingContainer>}
        </Modal>
    )
}

export default DepartmentDispenserNewModal
