import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment-timezone";
import { ArrowLeftDropCircleOutlineIcon, ArrowRightDropCircleOutlineIcon } from "mdi-react";
moment.locale("es");
moment.tz.setDefault("America/Monterrey");

function DateSelector({ send, state }) {
  const { context } = state;
  const { filter } = context;
  const { fecha, mode } = filter;
  return (
    <div style={{ backgroundColor: "whitesmoke", height: "40px", lineHeight: "40px" }}>
      <Row>
        <Col xs={2} className="text-left" style={{ paddingLeft: "10px", paddingRight: "0px" }}>
          <ArrowLeftDropCircleOutlineIcon style={{ margin: "8px", cursor: "pointer" }} onClick={() => send("PREV_DATE")} />
        </Col>
        <Col xs={8} className="text-center">
          {(() => {
            switch (mode) {
              case "day":
                return `${moment(fecha).format("DD MMM YYYY")}`;
              case "week":
                return `${moment(fecha).format("DD MMM")} - ${moment(fecha).add(1, "week").format("DD MMM")} `;
              case "month":
                return `${moment(fecha).format("MMM YYYY")}`;
              case "year":
                return `${moment(fecha).format("YYYY")}`;
              default:
                break;
            }
          })()}
        </Col>
        <Col xs={2} className="text-right" style={{ paddingLeft: "0px", paddingRight: "10px" }}>
          <ArrowRightDropCircleOutlineIcon style={{ margin: "8px", cursor: "pointer" }} onClick={() => send("NEXT_DATE")} />
        </Col>
      </Row>
    </div>
  );
}
export default DateSelector;
