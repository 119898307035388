import React, { useState, useEffect } from 'react'
import { BasicEditForm } from '../../../../components/BasicEditForm'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import useRequestReducer from '../../../../hooks/useRequestReducer';
import { Container, Button, ButtonGroup, Card, CardTitle, CardHeader, CardBody, Col, Label, FormGroup, FormFeedback, Input } from 'reactstrap'

function RolesNew(props) {
    const [formData, setFormData] = useState({ PERMISSIONS: {} });
    const [resources, setRecursos] = useState([]);
    const [resourcesData] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/resources`
    });

    useEffect(() => {
        if (resourcesData.response)
            setRecursos(resourcesData.response.data);
    }, [resourcesData.response])

    const [createData, dispatchCreate] = useRequestReducer({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/roles/`
    });

    useEffect(() => {
        if (createData.response) {
            if (createData.response.status === 200) {
                props.history.push({ pathname: `/roles/${createData.response.data.insertId}` })
            }
        }
    }, [createData.response])

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        })
    };

    const selectedPermission = (recurso, permiso) => {
        let original = formData.PERMISSIONS;
        if (original.hasOwnProperty(recurso)) {
            const indexPermiso = original[recurso].indexOf(permiso);
            if (indexPermiso < 0) {
                original[recurso].push(permiso);
            } else {
                original[recurso].splice(indexPermiso, 1);
            }
        }
        else {
            original = { ...original, [recurso]: [permiso] }
        }
        setFormData({ ...formData, PERMISSIONS: original })
    }

    return (
        <Container fluid>
            <Card>
                <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', minHeight: '70px' }}>
                    <CardHeader className="card-header-divider" >
                        <CardTitle>
                            Nuevo Rol
                        </CardTitle>
                    </CardHeader>
                </div>
                <CardBody>
                    <LoadingContainer isLoading={createData.loading}>
                        <BasicEditForm
                            onValidSubmit={() => dispatchCreate({ type: 'SEND_REQUEST', request: { data: { ...formData, PERMISSIONS: JSON.stringify(formData.PERMISSIONS) } } })} >
                            <FormGroup row>
                                <Label sm={3} className="text-sm-right">Nombre</Label>
                                <Col sm={8} lg={6}><Input type="text" name="NAME" value={formData.NAME || ""} onChange={handleInputChange} required />
                                    <FormFeedback>Es necesario proporcionar un Nombre al Rol</FormFeedback></Col>
                            </FormGroup>
                            <CardTitle>Permisos</CardTitle>
                            <hr />
                            {resources.map(element => {
                                return (<div key={element.ID_RECURSO}>
                                    <FormGroup row>
                                        <Label sm={3} className="text-sm-right">{element.NAME}</Label>
                                        <Col sm={8} lg={6}>
                                            <ButtonGroup>
                                                {JSON.parse(element.PERMISSIONS).map(permiso => {
                                                    return <Button key={`${permiso}${element.ID_RECURSO}`}
                                                        onClick={() => selectedPermission(element.NAME, permiso)}
                                                        active={(formData.PERMISSIONS[element.NAME] || []).includes(permiso) || false}
                                                    >{permiso}
                                                    </Button>
                                                })}
                                            </ButtonGroup>
                                        </Col>
                                    </FormGroup>
                                </div>)
                            })}
                            <FormGroup>
                                <Button block color="success">Nuevo Rol</Button>
                            </FormGroup>
                        </BasicEditForm>
                    </LoadingContainer>
                </CardBody>
            </Card>
        </Container>
    )
}

export default RolesNew
