import React, { useEffect,useState } from 'react'
import { Card, CardHeader, CardBody, CardTitle, Button } from 'reactstrap'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import axios from "axios";
import TableSearch from '../../../../components/TableSearch';
import { AccountCheckIcon, AccountAlertIcon, AccountIcon, BellOffIcon } from 'mdi-react'
import { useQuery, queryCache } from 'react-query'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import UsersCreate from './UsersCreate'
var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');

async function fetchUsersList() {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/users`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

const fetchMachine = Machine({
    id: 'fetch',
    initial: 'idle',
    states: {
        idle: {
            on: {
                ADD: 'adding'
            }
        },
        adding: {}
    },
    on: {
        DATA_CHANGED: { target: '.idle', actions: assign({ listData: (context, event) => event.data }) },
        CANCEL: '.idle',
        SUCCESS: '.idle'
    }
});


function UsersList(props) {
    const [refetchActive, setRefetchActive] = useState(true);
    const { status, data, error, isFetching, refetch } = useQuery('users', fetchUsersList, { retry: false, refetchOnWindowFocus: refetchActive })
    const [state, send] = useMachine(fetchMachine, { context: { listData: data } })
    const { listData = [] } = state.context;

    useEffect(() => {
        send('DATA_CHANGED', { data: data })
    }, [data])

    useEffect(() => {
        if (state.value == 'idle') {
            setRefetchActive(true)
        }
        else {
            setRefetchActive(false)
        }
    }, [state.value])

    return (
        <Card>
            {state.matches('adding') &&
                <UsersCreate isOpen={true} onSuccess={(id) => { send('SUCCESS'); queryCache.invalidateQueries('users'); props.history.push({ pathname: `/users/${id}` }) }} onCancel={() => send('CANCEL')} />}
            <CardHeader >
                <CardTitle>
                    Usuarios
                    {listData.length > 0 && <Button disabled={!state.matches('idle') || isFetching || error != null} color="success" className="float-right" onClick={() => send('ADD')}>Nueva</Button>}
                    {isFetching &&
                        <div className="float-right mr-2">...</div>
                    }
                </CardTitle>
            </CardHeader>
            {status === "loading" ?
                <CardBody>
                    <LoadingContainer isLoading={true}>
                        <div style={{ minHeight: '470px' }}></div>
                    </LoadingContainer>
                </CardBody>
                : null}
            {status === "error" ?
                <CardBody>
                    <LoadingContainer hasError={true} onRetry={() => refetch({ force: true })}>
                        <div style={{ minHeight: '470px' }}></div>
                    </LoadingContainer>
                </CardBody>
                : null}
            {status === "success" ?
                <div>
                    {listData.length === 0 ?
                        <CardBody>
                            <div style={{ height: '150px', top: '0px', bottom: '0px', left: '0px', right: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <p>Agregar un Usuario</p>
                                <Button disabled={!state.matches('idle')} className="float-right" color="success" onClick={() => send('ADD')}>Agregar</Button>
                            </div>
                        </CardBody> :
                        <TableSearch
                            height="65vh"
                            keyField="ID_USER"
                            placeholder="Buscar Usuario"
                            data={listData || []}
                            columns={[{
                                text: 'Estatus',
                                dataField: 'LAST_RAW_DATA_DATE',
                                sort: true,
                                classes: 'text-center',
                                formatter: (data, row, index, extra) => {
                                    if (row.BLE_ADDRESS) {
                                        if (!row.ALERT_DELAY || moment(row.ALERT_DELAY) < moment()) {
                                            if (moment(row.LAST_RAW_DATA_DATE) < moment().subtract(7, 'days')) {
                                                return <AccountAlertIcon style={{ color: 'orange' }} />
                                            }
                                            else {
                                                if (row.LAST_RAW_DATA_DATE)
                                                    return <AccountCheckIcon style={{ color: 'forestgreen' }} />
                                                else
                                                    return <AccountIcon style={{ color: 'olivedrab' }} />
                                            }
                                        }
                                        else {
                                            return <BellOffIcon style={{ color: 'lightgray' }} />
                                        }
                                    }
                                    else {
                                        return <AccountIcon style={{ color: 'lightgray' }} />
                                    }
                                }
                            }, {
                                text: 'Nombre',
                                dataField: 'NAME',
                                sort: true,
                                formatExtraData: props.match.params.id,
                                formatter: (data, row, index, extra) => {
                                    if (row.ID_USER === parseInt(extra, 10))
                                        return (<b>{`${row.NAME} ${row.LAST_NAME}`}</b>)
                                    else return `${row.NAME} ${row.LAST_NAME}`
                                }
                            }, {
                                text: 'Apellido',
                                dataField: 'LAST_NAME',
                                sort: true,
                                classes: 'd-none',
                                headerClasses: 'd-none'
                            }, {
                                dataField: 'BLE_ADDRESS',
                                text: 'Tag',
                                sort: true,
                                classes: 'd-none d-sm-table-cell text-right',
                                headerClasses: 'd-none d-sm-table-cell text-right',
                                headerStyle: () => {
                                    return { width: "1px" };
                                },
                                formatExtraData: props.match.params.id,
                                formatter: (data, row, index, extra) => {
                                    if (row.ID_USER === parseInt(extra, 10))
                                        return (<b>{data}</b>)
                                    else
                                        return data
                                }
                            }]}
                            selectRow={{
                                mode: 'radio',
                                hideSelectColumn: true,
                                clickToSelect: true,
                                onSelect: (row, isSelect, rowIndex, e) => {
                                    props.history.push({ pathname: `/users/${row.ID_USER}/` })
                                }
                            }}
                        />}
                </div> : null}
        </Card>
    )
}

export default UsersList
