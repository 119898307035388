import React, { useContext } from 'react'
import { Row, Col,  CardTitle, Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input} from 'reactstrap'
import axios from "axios";
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import UsersContext from './UsersContext'
var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');

function invokeUpdate(context) {
    const { ID_USER } = context;
    return axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/users/${ID_USER}`,
        data: context,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    });
}

const updateMachine = Machine({
    initial: 'confirming',
    states: {
        confirming: {
            on: {
                TYPING: { actions: 'typing' },
                CONFIRM_UPDATE: 'sending_data'
            }
        },
        sending_data: {
            invoke: {
                id: 'sending-data',
                src: invokeUpdate,
                onDone: {
                    actions: (context, event) => context.onSuccess() //Close Modal
                },
                onError: 'error'
            }
        },
        error: {
            on: {
                RETRY: 'confirming'
            }
        }
    }
}, {
        actions: {
            typing: assign((context, event) => ({ ...context, [event.name]: event.value })),
        }
    })

function ConfirmHideAlertsModal(props) {
    const { state: feedbackState} = useContext(UsersContext)
    const { formData } = feedbackState.context;
    const [state, send] = useMachine(updateMachine, { context: { ...formData, ALERT_DELAY: moment().add(1, 'week').format("YYYY-MM-DD"), onSuccess: props.onSuccess } })

    return (
        <Modal isOpen={props.isOpen} size="sm" centered>
            <ModalHeader>
                <CardTitle>Desactivar Alertas</CardTitle>
            </ModalHeader>
            <LoadingContainer isLoading={state.matches('sending_data')}>
                {state.matches('sending_data') ?
                    <div style={{ minHeight: '150px' }}>
                    </div> : null}
                {state.matches('error') ?
                    <ModalBody>
                        <p className="text-center mt-5 mb-5">Error</p>
                        <Button block color="primary" onClick={() => send('RETRY')}>Reintentar</Button>
                    </ModalBody>
                    : null}
                {state.matches('confirming') ?
                    <ModalBody>
                        <p className="text-center mb-0">Al desactivar las alertas, <b>este Usuario no aparecerá</b> en las listas de Tags inactivos en las <b>Pantallas de Retroalimentación</b>.</p>
                        <FormGroup row>
                            <Label className="mb-0 mt-0" xs={4}>Desactivar por:</Label>
                            <Col xs={8}>
                                <Input required type="select" name="ALERT_DELAY" value={state.context.ALERT_DELAY} onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} autoComplete="off">
                                    <option value={moment().add(1, 'week').format("YYYY-MM-DD")}>1 semana</option>
                                    <option value={moment().add(2, 'week').format("YYYY-MM-DD")}>2 semanas</option>
                                    <option value={moment().add(1, 'month').format("YYYY-MM-DD")}>1 mes</option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <p className="text-center mb-5">Las alertas se reactivarán automaticamente el <b>{state.context.ALERT_DELAY}</b></p>
                        <Row>
                            <Col xs={6}>
                                <Button block onClick={props.onCancel}>Cancelar</Button>
                            </Col>
                            <Col xs={6}>
                                <Button color="primary" block onClick={() => send('CONFIRM_UPDATE')}>Aceptar</Button>
                            </Col>
                        </Row>
                    </ModalBody> : null}
            </LoadingContainer>
        </Modal>
    )
}

export default ConfirmHideAlertsModal
