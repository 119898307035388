
import "../../assets/css/app.css"

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import  UserMenu  from "../UserMenu";
import { Spring, animated } from 'react-spring'

export class DashboardLayout extends Component {
    state = {
        lsOpen: false,
        lsOpenClasses: false
    }

    toggleSidebar = () => {
        let current = !this.state.lsOpen;
        this.setState({ lsOpen: current });
    }

    render() {
        return (
            <div className="be-wrapper be-fixed-sidebar">
                {/*Top Bar*/}
                <nav className="navbar navbar-expand fixed-top be-top-header">
                    <div className="container-fluid" style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)' }}>
                        {/* Navbar Header */}
                        <div className="be-navbar-header">

                            {/* Brand Logo */}
                            <Link to="/" className="navbar-brand" />
                        </div>

                        {/* Main topbar elements */}
                        <div className="be-right-navbar">
                            {/* User Menu */}
                            <ul className="nav navbar-nav float-right be-user-nav">
                                <UserMenu />
                            </ul>

                            {/* Page title */}
                            {/*<div className="page-title"><span></span></div>*/}

                            {/* Icons Menu */}
                            {/*<ul className="nav navbar-nav be-icons-nav float-right"></ul>*/}
                        </div>

                        {/* Left navbar (Used only when there is no left sidebar) 
                        <a href="index.html" data-toggle="collapse" data-target="#be-navbar-collapse" className="be-toggle-top-header-menu collapsed">No Sidebar Left</a>
                        <div id="be-navbar-collapse" className="navbar-collapse collapse">
                            /* Default bootstrap navbar 
                        </div>
                        */}

                    </div>
                </nav>

                {/* Left sidebar */}
                <div className="be-left-sidebar">
                    <div className="left-sidebar-wrapper">
                        <a role="button" className={this.state.lsOpen === true ? "left-sidebar-toggle open" : "left-sidebar-toggle"} onClick={this.toggleSidebar}>Menú</a>
                        {<div className={this.state.lsOpenClasses === true ? "left-sidebar-spacer open" : "left-sidebar-spacer"}>
                            <div className="left-sidebar-scroll">
                                <div className="left-sidebar-content" style={{ overflowY: 'scroll' }}>
                                    <Spring native force
                                        config={{ tension: 200, friction: 26, clamp:true}}
                                        from={{ height: 0 }}
                                        to={{ height: this.state.lsOpen ? 'auto' : 0 }}
                                        onStart={() => {
                                            if (this.state.lsOpen && !this.state.lsOpenClasses) {
                                                this.setState({ lsOpenClasses: true })
                                            }
                                        }}
                                        onRest={() => {
                                            if (!this.state.lsOpen && this.state.lsOpenClasses) {
                                                this.setState({ lsOpenClasses: false })
                                            }
                                        }}>
                                        {props => (
                                            <animated.div style={props}>
                                                {this.props.left_bar}
                                            </animated.div>
                                        )}
                                    </Spring>
                                </div>
                            </div>
                        </div>}
                        <div className="progress-widget">
                            {this.props.left_bottom}
                        </div>
                    </div>
                </div>
                {/* Main Content */}
                <div className="be-content">
                    <div className="mt-5">
                        {this.props.content}
                    </div>
                </div>

                {/* Right sidebar */}
                <nav className="be-right-sidebar">

                </nav>
            </div>
        )
    }
}

export default DashboardLayout

