import React, { Component } from 'react'
import "../assets/css/app.css"
import { Spring, animated } from 'react-spring'

export class SubMenu extends Component {
    state = {
        showMenu: false,
        showMenuClasses: false
    }

    toggleMenu = () => {
        let current = !this.state.showMenu
        this.setState({ showMenu: current })
    }

    render() {
        return (
            <li className={this.state.showMenuClasses === true ? "parent open" : "parent"}>
                <a role="button" onClick={this.toggleMenu}>{this.props.icon}<span>{this.props.title}</span></a>
                <Spring native force
                    config={{ tension: 200, friction: 26, clamp: true }}
                    from={{ height: 0, overflow: "hidden" }}
                    to={{ height: this.state.showMenu ? 'auto' : 0, overflow: "hidden" }}
                    onStart={() => {
                        if (this.state.showMenu && !this.state.showMenuClasses) {
                            this.setState({ showMenuClasses: true })
                        }
                    }}
                    onRest={() => {
                        if (!this.state.showMenu && this.state.showMenuClasses) {
                            this.setState({ showMenuClasses: false })
                        }
                    }}>
                    {props => (
                        <animated.ul style={props} className="sub-menu">
                            {this.props.children}
                        </animated.ul>
                    )}
                </Spring>
            </li>
        )
    }
}

export default SubMenu

/*
<li className={this.state.showMenu === true ? "parent open" : "parent"}>
                <a role="button" onClick={this.toggleMenu}>{this.props.icon}<span>{this.props.title}</span></a>
                <ul className="sub-menu">
                    {this.props.children}
                </ul>
            </li>
*/