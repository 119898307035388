import React, { useState, useEffect, useContext } from 'react'
import Map from '../../../../../components/Map';
import { Scatter } from 'react-chartjs-2';
import { CardHeader, CardTitle, CardBody, Modal, Button } from 'reactstrap'
import DepartmentsGatewaysEdit from './DepartmentGatewaysEdit'
import DepartmentsContext from '../DepartmentsContext';


function DepartmentGateways(props) {
    const { state, send } = useContext(DepartmentsContext)
    const { originalData: department = {} } = state.context;
    const [gateways, setGateways] = useState([]);
    const [colors, setColors] = useState([]);
    const [selection, setSelection] = useState();

    useEffect(() => {
        if (department.gateways) {
            let result = department.gateways;
            let dataForGraph = [];
            let colorsForGraph = [];
            for (let i = 0; i < result.length; i++) {
                dataForGraph.push({
                    ...result[i],
                    x: result[i].X,
                    y: result[i].Y,
                    label: result[i].BLE_ADDRESS,
                    id: result[i].ID_GATEWAY
                })
                if (result[i].ESTATUS === 'OFFLINE')
                    colorsForGraph.push('rgba(255,0,0,1)')
                else if(result[i].ESTATUS === 'BUFFERING')
                    colorsForGraph.push('rgba(255,206,43,1)')
                else
                    colorsForGraph.push('rgba(36 , 199 , 166,1)')
            }
            setGateways(dataForGraph);
            setColors(colorsForGraph);
        }
    }, [department.gateways])

    return (<div>
        <React.Fragment>
            <CardHeader>
                <CardTitle>Gateways
                        <Button color="success" className="float-right" onClick={() => send('ADD_GATEWAY')}>Agregar</Button>
                </CardTitle>
            </CardHeader>
            <CardBody>

                <div style={{ maxWidth: '500px', marginRight: 'auto', marginLeft: 'auto' }}>
                    <Map sizex={department.MAP_SIZE_X} sizey={department.MAP_SIZE_Y} mapImageURL={department.MAP_IMAGE_URL}>
                        <Scatter
                            getElementAtEvent={
                                element => {
                                    if (element.length > 0) {
                                        send('EDIT_GATEWAY',{...gateways[element[0]._index]})
                                    }
                                    /*setSelection(gateways[element[0]._index])
                                    : setSelection(null)*/
                                }}
                            data={{
                                datasets: [{
                                    label: 'My First dataset',
                                    data: gateways,
                                    pointBorderColor: 'rgba(2 , 0 , 135,1)',
                                    pointBorderWidth: 1,
                                    pointRadius: 6,
                                    pointHoverRadius: 5,
                                    fill: false,
                                    showLine: false,
                                    pointBackgroundColor: colors
                                }]
                            }} options={{
                                tooltips: {
                                    yAlign: 'bottom',
                                    callbacks: {
                                        label: function (tooltipItem, data) {
                                            var label = data.datasets[0].data[tooltipItem.index].label || '';
                                            return label;
                                        }
                                    }
                                },
                                animation: false,
                                legend: { display: false },
                                scales: {
                                    xAxes: [{
                                        type: 'linear',
                                        ticks: {
                                            display: false, stepSize: 1.0, beginAtZero: true, max: department.MAP_SIZE_X, min: 0
                                        },
                                        gridLines: {
                                            color: 'rgba(100,100,100,.2)',
                                            tickMarkLength: 0
                                        }
                                    }],
                                    yAxes: [{
                                        type: 'linear',
                                        ticks: {
                                            display: false, stepSize: 1.0, beginAtZero: true, max: department.MAP_SIZE_Y, min: 0
                                        },
                                        gridLines: {
                                            color: 'rgba(100,100,100,.2)',
                                            tickMarkLength: 0
                                        }
                                    }]
                                },
                                maintainAspectRatio: false
                            }} />
                    </Map>
                </div>
            </CardBody>
        </React.Fragment>
        {/*selection ?
            <Modal isOpen={selection} centered>
                <DepartmentsGatewaysEdit
                    {...props}
                    gateway={selection}
                    onClose={() => {
                        setSelection(null)
                        // dispatchGatewaysData({ type: 'SEND_REQUEST' })
                    }}
                />
                </Modal> : null*/}
    </div >
    )
}

export default DepartmentGateways
