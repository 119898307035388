import React, { useState, useEffect } from 'react'
import { Button, Col, Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Input, Container } from 'reactstrap'
import useRequestReducer from '../../../../hooks/useRequestReducer'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { BasicEditForm } from '../../../../components/BasicEditForm'
import { Can } from '../../../../AbilityContext'

function TagsEdit(props) {
    const [formData, setFormData] = useState({})
    const [state] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/tags/${props.match.params.id}`
    });
    const [updateState, dispatchUpdate] = useRequestReducer({
        method: 'put',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/tags/${props.match.params.id}`,
        body: formData
    });
    const [deleteState, dispatchDelete] = useRequestReducer({
        method: 'delete',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/tags/${props.match.params.id}`
    });

    useEffect(() => {
        if (state.response)
            setFormData(state.response.data);
    }, [state.response])

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        })
    };

    return (
        <Container fluid>
            <Card>
                <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', minHeight: '70px' }}>
                    <CardHeader className="card-header-divider">
                        <CardTitle>Editar Tag
                            {!state.loading ? <Can I="delete" a="TAGS"><Button color="danger" className="float-right" onClick={() => dispatchDelete('SEND_REQUEST')}>Eliminar</Button></Can> : null}
                        </CardTitle>
                    </CardHeader>
                </div>
                <CardBody>
                    <LoadingContainer isLoading={state.loading || updateState.loading || deleteState.loading}>
                        <BasicEditForm onValidSubmit={() => { dispatchUpdate({ type: 'SEND_REQUEST', action: { data: formData } }) }}>
                            <Can I="update" a="TAGS" passThrough>
                                {can => <fieldset disabled={!can}>
                                    <FormGroup row>
                                        <Label sm={3} className="text-sm-right">BLE Address</Label>
                                        <Col sm={8} lg={6}><Input required type="text" name="BLE_ADDRESS" value={formData.BLE_ADDRESS || ""} onChange={handleInputChange} /></Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={3} className="text-sm-right">Hardware</Label>
                                        <Col sm={8} lg={6}><Input required type="text" name="HARDWARE" value={formData.HARDWARE || ""} onChange={handleInputChange} /></Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={3} className="text-sm-right">Firmware</Label>
                                        <Col sm={8} lg={6}><Input required type="text" name="FIRMWARE" value={formData.FIRMWARE || ""} onChange={handleInputChange} /></Col>
                                    </FormGroup>
                                    {!state.response || (JSON.stringify(state.response.data) === JSON.stringify(formData)) ? null :
                                        <Can I="update" a="TAGS">
                                            <FormGroup>
                                                <Button block color="primary">Guardar Cambios</Button>
                                            </FormGroup>
                                        </Can>}
                                </fieldset>}
                            </Can>
                        </BasicEditForm>
                    </LoadingContainer>
                </CardBody>
            </Card>
        </Container>
    )
}

export default TagsEdit

