import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import EventsList from './EventsList'
import EventsEdit from './EventsEdit'


function Events(props) {
    return (
        <Container fluid style={{ position: 'relative' }}>
            <Row>
                <Col xs={12} lg={6} xl={5}>
                    <Route exact path='/events/' key={'/events'}>
                        {({ match }) => {
                            return (
                                <CSSTransition
                                    in={match != null}
                                    timeout={300}
                                    classNames={!match ? `card-view` : `card-view-back`}
                                    unmountOnExit
                                >
                                    <div className={`background d-block d-lg-none`}>
                                        <EventsList {...props} />
                                    </div>
                                </CSSTransition>
                            )
                        }}
                    </Route>
                    <Route exact path='/events/:id' key={'/feedback2'}>
                        {({ match }) => {
                            return (
                                <div className={`background d-none d-lg-block`}>
                                    <EventsList {...props} />
                                </div>
                            )
                        }}
                    </Route>
                </Col>
                <Col xs={12} lg={6} xl={7}>
                    <Route exact path='/events/:id' key={`/events/:id`}>
                        {({ match }) => {
                            return (
                                <CSSTransition
                                    in={match != null}
                                    timeout={300}
                                    classNames={match ? "card-view" : "card-view-back"}
                                    unmountOnExit
                                >
                                    <div className="background">
                                        <EventsEdit {...props} />
                                    </div>
                                </CSSTransition>
                            )
                        }}
                    </Route>
                </Col>
            </Row>
        </Container>
    )
}


export default Events
