import React from 'react'
import { CardTitle, Modal, ModalHeader, Button, ModalBody, FormGroup, Input, Label } from 'reactstrap'
import {  queryCache } from 'react-query'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import axios from 'axios'
import { BasicEditForm } from '../../../../components/BasicEditForm';
import { LoadingContainer } from '../../../../components/LoadingContainer';

function invokePost({ formData }) {
    return axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/feedback`,
        data: formData,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
}

const addMachine = Machine({
    initial: 'idle',
    states: {
        idle: {
            on: {
                SUBMIT: { target: 'submitting' },
                TYPING: { actions: 'typing' }
            }
        },
        submitting: {
            invoke: {
                id: 'sending-data',
                src: invokePost,
                onDone: {
                    actions: (context, event) => {
                        queryCache.invalidateQueries('todos')//Invalidate affected Cache
                        context.onSuccess(event.data.data.insertId)//Close Modal
                    }
                },
                onError: 'error'
            }
        },
        error: {
            on: {
                RETRY: 'idle'
            }
        }
    }
},
    {
        actions: {
            typing: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: event.value }) }),
        }
    })

function FeedbackAddModal(props) {
    const [state, send] = useMachine(addMachine, { context: { formData: {}, onSuccess: props.onSuccess, onCancel: props.onCancel } })

    const { formData } = state.context

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader toggle={props.onCancel}>
                <CardTitle>Nueva Pantalla</CardTitle>
            </ModalHeader>
            <LoadingContainer isLoading={state.matches('submitting')}>
                {state.matches('submitting') ?
                    <div style={{ minHeight: '150px' }}>
                    </div> : null}
                {state.matches('error') ?
                    <ModalBody>
                        <p className="text-center mt-5 mb-5">Error</p>
                        <Button block color="primary" onClick={() => send('RETRY')}>Reintentar</Button>
                    </ModalBody>
                    : null}
                {state.matches('idle') ?
                    <ModalBody>
                    <BasicEditForm onValidSubmit={() => send('SUBMIT')}>
                        <FormGroup>
                            <Label>Nombre</Label>
                            <Input name="NAME" value={formData.NAME || ""} type="text" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                        </FormGroup>
                        <Button block color="success">Crear Pantalla</Button>
                    </BasicEditForm>
                </ModalBody> : null}
            </LoadingContainer>
        </Modal>
    )
}

export default FeedbackAddModal


