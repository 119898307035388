import React from 'react'
import {  ButtonGroup,  CardTitle, CardBody, Button, Modal, ModalHeader, ModalBody} from 'reactstrap'
import axios from "axios";
import TableSearch from '../../../../components/TableSearch';
import { LoadingContainer } from '../../../../components/LoadingContainer';
import ButtonLabel from '../../../../components/ButtonLabel';
import { CircleSlice8Icon, CircleOutlineIcon, ChevronRightIcon, ChartBarIcon, TrophyIcon, CalendarTodayIcon, CalendarWeekIcon, CalendarMultipleIcon, CalendarBlankIcon } from 'mdi-react'
import { useQuery } from 'react-query'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';


const types = [{
    icon: <ChartBarIcon />,
    type: 'history',
    NAME: 'Historial',
    DESCRIPTION: 'Muestra el porcentaje de Higiene de Manos histórico durante el periodo seleccionado, y dentro de la población seleccionada'
}, {
    icon: <ChartBarIcon />,
    type: 'department',
    NAME: 'Departamento',
    DESCRIPTION: 'Muestra el porcentaje de Higiene de Manos agrupado por Departamentos durante el periodo seleccionado, y dentro de la población seleccionada'
}, {
    icon: <ChartBarIcon />,
    type: 'profession',
    NAME: 'Profesión',
    DESCRIPTION: 'Muestra el porcentaje de Higiene de Manos agrupado por Profesión durante el periodo seleccionado, y dentro de la población seleccionada'
}, {
    icon: <TrophyIcon />,
    type: 'top',
    NAME: 'Top',
    DESCRIPTION: 'Muestra los mejores 10 individuos con el mejor porcentaje de Higiene de Manos durante el periodo seleccionado, y dentro de la población seleccionada'
}]

const periods = [{
    icon: <CalendarTodayIcon />,
    period: 'day',
    NAME: 'Diario',
    DESCRIPTION: 'Muestra 24 barras representando cada una de las horas del día en transcurso.'
}, {
    period: 'week',
    icon: <CalendarWeekIcon />,
    NAME: 'Semanal',
    DESCRIPTION: 'Muestra 7 barras representando cada una de los días de la semana en transcurso'
}, {
    icon: <CalendarBlankIcon />,
    period: 'month',
    NAME: 'Mensual',
    DESCRIPTION: 'Muestra 28-31 barras representando cada una de los días del mes en transcurso'
}, {
    icon: <CalendarMultipleIcon />,
    period: 'year',
    NAME: 'Anual',
    DESCRIPTION: 'Muestra 12 barras representando cada una de los meses del año en transcurso'
}]


const addSlideMachine = Machine({
    initial: 'selecting_type',
    states: {
        selecting_type: {
            on: {
                TYPE_SELECTED: { target: 'selecting_period', actions: assign({ type: (context, event) => event.data }) }
            }
        },
        selecting_period: {
            on: {
                PERIOD_SELECTED: { target: 'selecting_filters', actions: assign({ period: (context, event) => event.data }) }
            }
        },
        selecting_filters: {
            initial: 'picking',
            states: {
                picking: {
                    on: {
                        SELECT_DEPARTMENT: 'department',
                        SELECT_PROFESSION: 'profession'
                    }
                },
                department: {
                    on: {
                        SELECT: {
                            actions: assign({
                                department_filter: (context, event) => {
                                    if (context.department_filter) {
                                        if (context.department_filter.includes(event.data))
                                            return context.department_filter.filter((element) => element !== event.data)
                                        else
                                            return [...context.department_filter, event.data]
                                    } else {
                                        return [event.data]
                                    }
                                }
                            })
                        },
                        SELECT_ALL: {
                            actions: assign({ department_filter: [] })
                        },
                        BACK_BTN: 'picking'
                    }
                },
                profession: {
                    on: {
                        SELECT: {
                            actions: assign({
                                profession_filter: (context, event) => {
                                    if (context.profession_filter) {
                                        if (context.profession_filter.includes(event.data))
                                            return context.profession_filter.filter((element) => element !== event.data)
                                        else
                                            return [...context.profession_filter, event.data]
                                    } else {
                                        return [event.data]
                                    }
                                }
                            })
                        },
                        SELECT_ALL: {
                            actions: assign({ profession_filter: [] })
                        },
                        BACK_BTN: 'picking'
                    }
                }
            },
            on: {
                FILTERS_SELECTED: 'confirming'
            }
        },
        confirming: {}
    },
    on: {
        TYPE_BTN: '.selecting_type',
        PERIOD_BTN: '.selecting_period',
        FILTERS_BTN: '.selecting_filters'
    }
})

async function fetchDepartments(key) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/departments`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

async function fetchProfessions(key) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/profession_types`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

function AddSlideModal(props) {
    const [state, send] = useMachine(addSlideMachine, { context: { department_filter: [], profession_filter: [] } })
    const { status: status_departments, data: departments, refetch: refetch_departments } = useQuery('departments', fetchDepartments, { retry: false })
    const { status: status_profession, data: professions, refetch: refetch_professions } = useQuery('profession_types', fetchProfessions, { retry: false })
    const { department_filter, profession_filter } = state.context
    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader toggle={props.onCancel}>
                <CardTitle>Agregar Gráfica</CardTitle>
            </ModalHeader>
            <ButtonGroup style={{ display: 'flex' }}>
                <Button color="primary" style={{ flex: 1 }} active={state.matches('selecting_type')} onClick={() => send('TYPE_BTN')}>1.Tipo</Button>
                <Button color="primary" style={{ flex: 1 }} active={state.matches('selecting_period')} disabled={state.matches('selecting_type')} onClick={() => send('PERIOD_BTN')}>2.Periodo</Button>
                <Button color="primary" style={{ flex: 1 }} active={state.matches('selecting_filters')} disabled={state.matches('selecting_type') || state.matches('selecting_period')} onClick={() => send('FILTERS_BTN')}>3.Filtros</Button>
                <Button color="primary" style={{ flex: 1 }} active={state.matches('confirming')} disabled={state.matches('selecting_type') || state.matches('selecting_period') || state.matches('selecting_filters')}>4.Confirmar</Button>
            </ButtonGroup>
            {state.matches('selecting_type') &&
                <div>
                    <p className="text-center mt-2">Seleccione un tipo para continuar</p>
                    <TableSearch
                        keyField="type"
                        hideSearch
                        placeholder="Buscar Pantalla"
                        data={types}
                        columns={[{
                            text: 'Tipo',
                            dataField: 'type',
                            sort: true,
                            formatter: (value, row) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div className="ml-2 mr-3">{row.icon}</div>
                                        <div>
                                            <div className="mb-1" style={{ fontSize: '15px' }}>{row.NAME}</div>
                                            <div className="font-weight-light">{row.DESCRIPTION}</div>
                                        </div>
                                    </div>
                                )
                            }
                        }, {
                            name: '',
                            isDummyField: true,
                            headerStyle: () => {
                                return { width: "1px" };
                            },
                            formatter: () => {
                                return (
                                    <ChevronRightIcon />
                                )
                            }
                        }]}
                        selectRow={{
                            mode: 'radio',
                            hideSelectColumn: true,
                            clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => {
                                send('TYPE_SELECTED', { data: row })
                            }
                        }}
                    /></div>}
            {state.matches('selecting_period') &&
                <div>
                    <p className="text-center mt-2">Seleccione un periodo para continuar</p>
                    <TableSearch
                        keyField="period"
                        hideSearch
                        placeholder="Buscar Pantalla"
                        data={periods}
                        columns={[{
                            text: 'Tipo',
                            dataField: 'period',
                            sort: true,
                            formatter: (value, row) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div className="ml-2 mr-3">{row.icon}</div>
                                        <div>
                                            <div className="mb-1" style={{ fontSize: '15px' }}>{row.NAME}</div>
                                            <div className="font-weight-light">{row.DESCRIPTION}</div>
                                        </div>
                                    </div>
                                )
                            }
                        }, {
                            name: '',
                            isDummyField: true,
                            headerStyle: () => {
                                return { width: "1px" };
                            },
                            formatter: () => {
                                return (
                                    <ChevronRightIcon />
                                )
                            }
                        }]}
                        selectRow={{
                            mode: 'radio',
                            hideSelectColumn: true,
                            clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => {
                                send('PERIOD_SELECTED', { data: row })
                            }
                        }}
                    />
                </div>}
            {state.matches('selecting_filters') &&
                <div>
                    {status_departments === 'loading' || status_profession === 'loading' ?
                        <LoadingContainer isLoading={true}>
                            <div style={{ minHeight: '300px' }}>
                            </div>
                        </LoadingContainer> : null}
                    {status_departments === 'error' || status_profession === 'error' ?
                        <ModalBody>
                            <p className="text-center mt-5 mb-5">Error</p>
                            <Button block color="primary" onClick={() => { refetch_departments(); refetch_professions(); }}>Reintentar</Button>
                        </ModalBody> : null}
                    {status_departments === 'success' || status_profession === 'success' ?
                        <div>
                            {state.matches({ selecting_filters: 'picking' }) &&
                                <ModalBody>
                                    <p className="text-center mt-2">Seleccione los filtros</p>
                                    <ButtonLabel isButton={true} block onClick={() => send('SELECT_DEPARTMENT')}>
                                        <div className="mt-2 mb-3">
                                            <div className="mb-1 font-weight-normal" style={{ fontSize: '15px' }}>Departamentos</div>
                                            <div className="font-weight-light">{department_filter.length === 0 || department_filter.length === departments.length ? "Todos los Departamentos" : `${department_filter.length} Departamento(s)`}</div>
                                        </div>
                                    </ButtonLabel>
                                    <ButtonLabel isButton={true} block onClick={() => send('SELECT_PROFESSION')}>
                                        <div className="mt-2 mb-3">
                                            <div className="mb-1 font-weight-normal" style={{ fontSize: '15px' }}>Tipo de Usuario</div>
                                            <div className="font-weight-light">{profession_filter.length === 0 || profession_filter.length === professions.length ? "Todos los Tipos de Usuario" : `${profession_filter.length} Tipo(s) de Usuario`}</div>
                                        </div>
                                    </ButtonLabel>
                                    <Button className="mt-5" block color="primary" onClick={() => send('FILTERS_SELECTED')}>Continuar</Button>
                                </ModalBody>}
                            {state.matches({ selecting_filters: 'department' }) &&
                                <div>
                                    <ModalBody>
                                        <div className="mt-2" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button onClick={() => send('BACK_BTN')}>Atras</Button>
                                            <p>Seleccionar Departamentos</p>
                                            <Button onClick={() => send('SELECT_ALL')}>Todos</Button>
                                        </div>
                                    </ModalBody>
                                    <TableSearch
                                        keyField="ID_DEPARTMENT"
                                        hideSearch
                                        placeholder="Buscar Departamento"
                                        data={departments}
                                        columns={[{
                                            dataField: 'ID_TV',
                                            text: '',
                                            headerStyle: () => {
                                                return { width: "1px" };
                                            },
                                            formatExtraData: department_filter,
                                            headerClasses: ' text-center',
                                            formatter: (value, row, index, extra) => {
                                                if (!extra)
                                                    return <CircleSlice8Icon style={{ color: 'green', display: 'flex' }} size={15} />
                                                if (extra) {
                                                    if (extra.length === 0)
                                                        return <CircleSlice8Icon style={{ color: 'green', display: 'flex' }} size={15} />
                                                }
                                                if ((extra || []).includes(row.ID_DEPARTMENT))
                                                    return <CircleSlice8Icon style={{ color: 'green', display: 'flex' }} size={15} />
                                                else
                                                    return <CircleOutlineIcon style={{ color: 'grey', display: 'flex' }} size={15} />
                                            }
                                        }, {
                                            text: 'Tipo',
                                            dataField: 'NAME',
                                            sort: true
                                        }]}
                                        selectRow={{
                                            mode: 'radio',
                                            hideSelectColumn: true,
                                            clickToSelect: true,
                                            onSelect: (row, isSelect, rowIndex, e) => {
                                                send('SELECT', { data: row.ID_DEPARTMENT })
                                            }
                                        }}
                                    />
                                </div>
                            }
                            {state.matches({ selecting_filters: 'profession' }) &&
                                <div>
                                    <ModalBody>
                                        <div className="mt-2" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button onClick={() => send('BACK_BTN')}>Atras</Button>
                                            <p>Seleccionar Profesión</p>
                                            <Button onClick={() => send('SELECT_ALL')}>Todos</Button>
                                        </div>
                                    </ModalBody>
                                    <TableSearch
                                        keyField="ID_PROFESSION_TYPE"
                                        hideSearch
                                        placeholder="Buscar Profesión"
                                        data={professions}
                                        columns={[{
                                            dataField: 'ID_TV',
                                            text: '',
                                            headerStyle: () => {
                                                return { width: "1px" };
                                            },
                                            formatExtraData: profession_filter,
                                            headerClasses: 'text-center',
                                            formatter: (value, row, index, extra) => {
                                                if (!extra)
                                                    return <CircleSlice8Icon style={{ color: 'green', display: 'flex' }} size={15} />
                                                if (extra) {
                                                    if (extra.length === 0)
                                                        return <CircleSlice8Icon style={{ color: 'green', display: 'flex' }} size={15} />
                                                }
                                                if ((extra || []).includes(row.ID_PROFESSION_TYPE))
                                                    return <CircleSlice8Icon style={{ color: 'green', display: 'flex' }} size={15} />
                                                else
                                                    return <CircleOutlineIcon style={{ color: 'grey', display: 'flex' }} size={15} />
                                            }
                                        }, {
                                            text: 'Tipo',
                                            dataField: 'NAME',
                                            sort: true
                                        }]}
                                        selectRow={{
                                            mode: 'radio',
                                            hideSelectColumn: true,
                                            clickToSelect: true,
                                            onSelect: (row, isSelect, rowIndex, e) => {
                                                send('SELECT', { data: row.ID_PROFESSION_TYPE })
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                        : null}
                </div>}
            {state.matches('confirming') &&
                <CardBody>
                    <p className="text-center mt-2">Confirme la información para continuar:</p>
                    <div className="text-center font-weight-light">Tipo:</div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="">{state.context.type.icon}</div>
                        <div className="mb-1" style={{ fontSize: '15px' }}>{state.context.type.NAME}</div>
                    </div>
                    <div className="text-center font-weight-light mt-1">Periodo:</div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="">{state.context.period.icon}</div>
                        <div className="mb-1" style={{ fontSize: '15px' }}>{state.context.period.NAME}</div>
                    </div>
                    <Button className="mt-3" block color="success" onClick={() => {
                        const {type,period,profession_filter=[],department_filter=[]}=state.context;
                        const dataToSubmit = {
                            type: type.type,
                            period: period.period,
                            filters: {
                                ID_DEPARMENT: [...department_filter],
                                ID_PROFESSION_TYPE: [...profession_filter]
                            }
                        }
                        props.onSuccess(dataToSubmit)
                    }}>
                        Agregar
                    </Button>
                </CardBody>}
        </Modal>
    )
}

export default AddSlideModal
