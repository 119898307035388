import React from 'react'
import { ResponsiveHeatMap, ResponsiveHeatMapCanvas } from '@nivo/heatmap'
import { Container, CardSubtitle, Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import moment from 'moment-timezone'
moment.locale('es');
moment.tz.setDefault("America/Monterrey");

function Heatmap(props) {
    const data = [];
    let unique = [];
    for (var i = 0; i < 4; i++) {
        const this_turn = props.data.filter(element => element.SHIFT == i)
        unique = [...new Set(this_turn.map(item => item.CALENDAR_DATE))]
        let final = { SHIFT: this_turn[i].SHIFT_NAME }
        for (var j = 0; j < this_turn.length; j++) {
            final[moment(this_turn[j].CALENDAR_DATE).format('ddd DD')] = this_turn[j].TOTAL_COMPLIANCE ? this_turn[j].TOTAL_COMPLIANCE.toFixed(1) : undefined
        }
        data.push(final)
    }

    return (
        <Container style={{ height: '100%' }}>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {props.title ?
                    <CardHeader>
                        <CardTitle className="text-center">
                            <b>{props.title}</b>
                        </CardTitle>
                    </CardHeader> : null}
                {props.subtitle ? props.subtitle.map((sub, index) => {
                    return <CardSubtitle className="text-center text-muted" key={index}>{sub}</CardSubtitle>
                }) : null}
                <CardBody style={{ display: 'flex', flexDirection: 'row', height: '50%', flex: 1, width: '90%', alignSelf: 'center' }}>
                    <ResponsiveHeatMap
                        data={data}
                        keys={
                            unique.map(element => moment(element).format('ddd DD'))
                        }
                        maxValue={60}
                        minValue={0}
                        padding={5}
                        indexBy="SHIFT"
                        forceSquare={true}
                        margin={{ top: 30, left: 60 }}
                        axisTop={{ orient: 'top', tickSize: 5, tickPadding: 5 }}
                        axisRight={null}
                        axisBottom={null}
                        cellOpacity={1}
                        cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
                        cellBorderWidth={'1px'}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
                        colors="RdYlGn"
                        nanColor="white"
                        theme={{
                            fontSize: '1.1vw'
                        }}
                        defs={[
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(0, 0, 0, 0.1)',
                                rotation: -45,
                                lineWidth: 4,
                                spacing: 7
                            }
                        ]}
                        fill={[{ id: 'lines' }]}
                        hoverTarget="cell"
                        cellHoverOthersOpacity={0.25}
                    />
                    <div className="p-2" style={{
                        fontSize: '1.1vw',
                        marginTop:'30px',
                        outline: '1px solid black',
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: '#bd9f63',
                        'background-image': 'linear-gradient(0deg, #a50026, #d3322b, #f16d43, #fcab63, #fedc8c, #f9f7ae, #d7ee8e, #a4d86f, #64bc61, #23964f, #23964f)'
                    }}>
                        <div>60%</div>
                        <div>0%</div>
                    </div>
                </CardBody>
            </Card>
        </Container>
        /*<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1, height: '80%', width: '100%' }}>
                
            </div>
        </div>*/
    )
}

export default Heatmap
