import React, { useState, useEffect } from 'react'
import { HorizontalBar } from 'react-chartjs-2';
import { LoadingContainer } from '../../../../../components/LoadingContainer';
import useRequestReducer from '../../../../../hooks/useRequestReducer';
import { Card, CardHeader, CardTitle, CardSubtitle, CardBody, Button } from 'reactstrap'


function DepartmentZonesEdit(props) {
    const [detail, setDetail] = useState({})
    const [detailData, dispatchDetailData] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/diego/lastDayStatus/${props.zone}`
    });

    const [statusArray, setStatusArray] = useState([]);
    const [statusArrayData, dispatchStatusArrayData] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/diego/allStatus/${props.zone}`
    })

    useEffect(() => {
        if (detailData.response)
            setDetail(detailData.response.data)
    }, [detailData.response])

    useEffect(() => {
        if (statusArrayData.response)
            setStatusArray(statusArrayData.response.data)
    }, [statusArrayData.response])

    useEffect(() => {
        setDetail({});
        setStatusArray([]);
        dispatchDetailData({ type: "SEND_REQUEST", request: { url: `${process.env.REACT_APP_API_ENDPOINT}/api/diego/lastDayStatus/${props.zone}` } })
        dispatchStatusArrayData({ type: "SEND_REQUEST", request: { url: `${process.env.REACT_APP_API_ENDPOINT}/api/diego/allStatus/${props.zone}` } })
    }, [props.zone])

    return (
        <Card>
            <LoadingContainer isLoading={detailData.loading}>
                <CardHeader className="card-header-divider">
                    <CardTitle>{props.zone}
                        <Button className="float-right" outline color="danger" onClick={props.onClose}>Cancelar</Button>
                    </CardTitle>
                    <CardSubtitle>{`${(100 * Number(detail.onlineTime) / (Number(detail.onlineTime) + Number(detail.offlineTime))).toFixed(2)}% (${(detail.offlineTime / 60).toFixed(2)} minutos Offline)`}</CardSubtitle>
                </CardHeader>
                <CardBody>
                    <HorizontalBar
                        data={{
                            datasets: statusArray.map(element => {
                                return {
                                    data: [element.duration],
                                    label: `${element.date}`,
                                    backgroundColor: element.status === 0 ? 'rgba(255,0,0,1)' : 'rgba(0,255,0,1)'
                                }
                            })
                        }}
                        options={{
                            height: '100px',
                            animation: false,
                            reponsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                mode: 'single'
                            },
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        display: false,
                                        max: 86400,
                                    },
                                    stacked: true,

                                }],
                                yAxes: [{
                                    ticks: {
                                        display: false,
                                    },
                                    stacked: true,

                                }]
                            }
                        }}
                    />
                </CardBody>
            </LoadingContainer>
        </Card>
    )
}


export default DepartmentZonesEdit
