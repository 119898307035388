import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Button } from 'reactstrap'
import { useQuery } from 'react-query'
import {ReplayIcon} from 'mdi-react'
import axios from "axios";
import { LoadingContainer } from '../../../../components/LoadingContainer';
import Map from '../../../../components/Map';
import { useSize } from 'react-hook-size';
import { Bubble } from 'react-chartjs-2';
var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');

async function fetchData(key, ID_IN_OUT) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/events/${ID_IN_OUT}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}



function EventsEdit(props) {
    const [width, setWidth] = React.useState(0);

    const measuredRef = React.useCallback(node => {
        if (node !== null) {
            setWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const { status, data, refetch, error } = useQuery(['events', props.match.params.id], fetchData, { retry: false, enabled: props.match.params.id })

    const [seconds, setSeconds] = React.useState(0);

    React.useEffect(() => {
        setSeconds(0)
        if (data) {
            const interval = setInterval(() => {
                setSeconds(seconds => seconds + 10 < JSON.parse(data.DETAILS).length ? seconds + 1 : seconds);
            }, 100);
            return () => clearInterval(interval);
        }
    }, [data]);

    return (
        <React.Fragment>
            <div className="d-xs-block d-lg-none mb-2"><Link to="/events">{"< Ver Todos los Eventos"}</Link></div>
            <Card>
                <CardHeader className="card-header-divider">
                    <CardTitle>
                        Evento
                    </CardTitle>
                </CardHeader>
                {(status === "loading") &&
                    <LoadingContainer isLoading={true}>
                        <div style={{ minHeight: '400px' }}></div>
                    </LoadingContainer>}
                {(status === "error") &&
                    <LoadingContainer
                        hasError={true}
                        onRetry={() => {
                            refetch({ force: true })
                        }}>
                        <div style={{ minHeight: '400px' }}></div>
                    </LoadingContainer>}
                {(status === "success") &&
                    <CardBody>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>Usuario</Label>
                                    <CardTitle>{data.NAME}</CardTitle>
                                </FormGroup>
                            </Col>
                            <Col xs={6} md={3}>
                                <FormGroup>
                                    <Label>Zona</Label>
                                    <CardTitle>{data.ZONE_NAME}</CardTitle>
                                </FormGroup>
                            </Col>
                            <Col xs={6} md={3}>
                                <FormGroup >
                                    <Label>Departamento</Label>
                                    <CardTitle>{data.DEPARTMENT_NAME}</CardTitle>
                                </FormGroup>
                            </Col>
                            <Col xs={6} sm={3}>
                                <FormGroup className="text-sm-center">
                                    <Label>Hora de Entrada</Label>
                                    <CardTitle>
                                        {`${moment(data.IN_DATE).format("HH:mm:ss")}`}
                                    </CardTitle>
                                </FormGroup>
                            </Col>
                            <Col xs={6} sm={3}>
                                <FormGroup className="text-sm-center">
                                    <Label>Hora de Salida</Label>
                                    <CardTitle>
                                        {`${moment(data.OUT_DATE).format("HH:mm:ss")}`}
                                    </CardTitle>
                                </FormGroup>
                            </Col>
                            <Col xs={6} sm={3}>
                                <FormGroup className="text-sm-center">
                                    <Label>Duración</Label>
                                    <CardTitle>{data.VISIT_LENGTH} seg.</CardTitle>
                                </FormGroup>
                            </Col>
                            <Col xs={6} sm={3}>
                                <FormGroup className="text-sm-center">
                                    <Label>Latencia</Label>
                                    <CardTitle>{(data.VISIT_LENGTH / JSON.parse(data.DETAILS || "[]").length).toFixed(2)} seg.</CardTitle>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>Detalle</Label>
                                    {(seconds + 10 < JSON.parse(data.DETAILS).length) || (data.VISIT_LENGTH<40) ?
                                        null :
                                        <Button className="float-right" onClick={() => setSeconds(0)}><ReplayIcon className="icon" size={15}/></Button>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '80%', marginRight: 'auto', marginLeft: 'auto' }}>
                            <Map sizex={data.MAP_SIZE_X} sizey={data.MAP_SIZE_Y} mapImageURL={data.MAP_IMAGE_URL}>
                                <div ref={measuredRef} style={{ width: '100%', height: '100%' }}>
                                    <Bubble
                                        data={{
                                            datasets: [{
                                                label: 'Scatter Dataset 2',
                                                borderColor: '#24c7a6',
                                                data: JSON.parse(data.DETAILS || "[]").slice(seconds, seconds + 10).map(element => {
                                                    return {
                                                        x: element[0],
                                                        y: element[1],
                                                        r: element[2] > 1.5 ? (element[2] * width) / data.MAP_SIZE_X : (element[2] * width) / data.MAP_SIZE_X
                                                    }
                                                })
                                            }]
                                        }}
                                        options={{
                                            animation: false,
                                            legend: { display: false },
                                            scales: {
                                                xAxes: [{
                                                    type: 'linear',
                                                    ticks: { display: false, stepSize: 1.0, beginAtZero: true, max: data.MAP_SIZE_X, min: 0 },
                                                    gridLines: {
                                                        color: 'rgba(100,100,100,.2)',
                                                        tickMarkLength: 0
                                                    }
                                                }],
                                                yAxes: [{
                                                    type: 'linear',
                                                    ticks: { display: false, stepSize: 1.0, beginAtZero: true, max: data.MAP_SIZE_Y, min: 0 },
                                                    gridLines: {
                                                        color: 'rgba(100,100,100,.2)',
                                                        tickMarkLength: 0
                                                    }
                                                }]
                                            },
                                            maintainAspectRatio: false
                                        }} />
                                </div>
                            </Map>
                        </div>
                    </CardBody>}
            </Card>
        </React.Fragment>
    )
}

export default EventsEdit
