import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, Route } from "react-router-dom";
import { AuthProvider } from './AuthContext';
import { UserProvider } from './UserContext';
import { AbilityProvider } from './AbilityContext';
import './card-slides.css';

ReactDOM.render(
    <BrowserRouter>
        <Route render={(props) =>
            <AuthProvider {...props}>
            <UserProvider {...props}>
                <AbilityProvider {...props}>
                    <App {...props} />
                </AbilityProvider>
            </UserProvider>
        </AuthProvider>
        } />
    </BrowserRouter>
    , document.getElementById("root"));