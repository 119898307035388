import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import AlertsList from './AlertsList';
import ComplianceDateByHour from './Stats/ComplianceDateByHour';
import { useAbilities } from '../../../AbilityContext'
var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');
moment.tz.setDefault("UTC");


function Home(props) {
    const abilities = useAbilities()

    return (
        <Container fluid>
            <Row>
                <Col lg={7}>
                    <ComplianceDateByHour filter={
                        {
                            MODE: 'month',
                            FROM_DATE: moment().add(-1, 'month'),
                            END_DATE: moment(),
                        }
                    } />
                </Col>
                {abilities.can("read", "GATEWAYS") ?
                    <Col lg={5}>
                        <AlertsList {...props} />
                    </Col> : null
                }
            </Row> 
        </Container>
        )
    }
    
    export default Home
