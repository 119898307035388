import React, { useEffect } from 'react'
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import axios from "axios";
import TableSearch from '../../../../components/TableSearch';
import { useQuery } from 'react-query'
import { LoadingContainer } from '../../../../components/LoadingContainer';
var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');
moment.tz.setDefault("America/Monterrey");

const dateFormatterIn = (value, row, index, extra) => {
    return (
        value ?
            <div className={`text-center ${row.ID_IN_OUT === extra ? 'font-weight-bold' : ''}`} style={row.ENTRADA === 1 ? { color: 'green' } : { color: 'black' }}>
                {`${moment(value).format("DD-MMM-YYYY")}`}
                <br />
                {`${moment(value).format("HH:mm:ss")}`}
                <br />
            </div>
            : <div className="text-center">-</div>)
}

const dateFormatterOut = (value, row, index, extra) => {
    return (
        value ?
            <div className={`text-center ${row.ID_IN_OUT === extra ? 'font-weight-bold' : ''}`}  style={row.SALIDA === 1 ? { color: 'green' } : { color: 'black' }}>
                {`${moment(value).format("DD-MMM-YYYY")}`}
                <br />
                {`${moment(value).format("HH:mm:ss")}`}
                <br />
            </div>
            : <div className="text-center">-</div>)
}

const boldFormatter = (value, row, index, extra) => {
    return (<div className={`${row.ID_IN_OUT === extra ? 'font-weight-bold' : ''}`}>
        {value}
    </div>)
}

async function fetchEventsList() {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/events?${objectToQuerystring({ INITIAL_DATE: moment().subtract(1, 'day').format("YYYY-MM-DD HH:mm:ss"), FINAL_DATE: moment().add(1,'day').format("YYYY-MM-DD HH:mm:ss") })}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

const fetchMachine = Machine({
    id: 'fetch',
    initial: 'idle',
    states: {
        idle: {
            on: {
                ADD: 'adding'
            }
        },
        adding: {}
    },
    on: {
        DATA_CHANGED: { target: '.idle', actions: assign({ listData: (context, event) => event.data }) },
        CANCEL: '.idle',
        SUCCESS: '.idle'
    }
});

function objectToQuerystring(obj) {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

function EventsList(props) {
    const { status, data, error, isFetching, refetch } = useQuery('events', fetchEventsList, { retry: false })
    const [state, send] = useMachine(fetchMachine, { context: { listData: data } })
    const { listData = [] } = state.context;

    useEffect(() => {
        send('DATA_CHANGED', { data: data })
    }, [data])

    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    Eventos
                    {isFetching &&
                        <div className="float-right mr-2">...</div>
                    }
                </CardTitle>
            </CardHeader>
            <LoadingContainer
                isLoading={status === "loading"}
                hasError={error != null && data === null}
                onRetry={() => refetch({ force: true })}>
                {state.matches('loading') ?
                    <div style={{ minHeight: '300px' }}>
                    </div> : null}
                {listData.length === 0 ?
                    <CardBody>
                        <div style={{ height: '150px', top: '0px', bottom: '0px', left: '0px', right: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <p>No se han encontrado Eventos en este periodo</p>
                        </div>
                    </CardBody> :
                    <TableSearch
                        height="70vh"
                        keyField="ID_IN_OUT"
                        placeholder="Buscar Evento"
                        data={listData || []}
                        columns={[{
                            dataField: 'NAME',
                            text: 'Usuario',
                            sort: true,
                            formatExtraData: props.match.params.id,
                            formatter: boldFormatter
                        }, {
                            dataField: 'ZONE_NAME',
                            text: 'Zona',
                            sort: true,
                            formatExtraData: props.match.params.id,
                            formatter: boldFormatter
                        }, {
                            dataField: 'VISIT_LENGTH',
                            text: 'T',
                            sort: true,
                            formatExtraData: props.match.params.id,
                            formatter: boldFormatter
                        }, {
                            dataField: 'IN_DATE',
                            text: 'Hora de Entrada',
                            sort: true,
                            formatExtraData: props.match.params.id,
                            formatter: dateFormatterIn,
                            headerClasses: 'text-center',
                            headerStyle: () => {
                                return { width: "100px" };
                            }
                        }, {
                            dataField: 'OUT_DATE',
                            text: 'Hora de Salida',
                            sort: true,
                            formatExtraData: props.match.params.id,
                            formatter: dateFormatterOut,
                            headerClasses: 'text-center',
                            headerStyle: () => {
                                return { width: "100px" };
                            }
                        }]}
                        selectRow={{
                            mode: 'radio',
                            hideSelectColumn: true,
                            clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => {
                                props.history.push({ pathname: `/events/${parseInt(row.ID_IN_OUT, 10)}` })
                            }
                        }}
                    />}
            </LoadingContainer>
        </Card>
    )
}

export default EventsList
