import React, { useEffect } from 'react'
import { Card, CardHeader, CardBody, CardTitle, Button } from 'reactstrap'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import axios from "axios";
import TableSearch from '../../../../components/TableSearch';
import { useQuery, queryCache } from 'react-query'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import ScreensConfirmModal from './ScreensConfirmModal';

async function fetchFeedbackList() {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/feedback`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

const fetchMachine = Machine({
    id: 'confirming',
    initial: 'idle',
    states: {
        idle: {
            on: {
                CONFIRM: {
                    target: 'confirming',
                    actions: [
                        (context, event) => console.log(event),
                        assign({ selection: (context, event) => event.data.ID_TV })
                    ]
                }
            }
        },
        confirming: {}
    },
    on: {
        DATA_CHANGED: { target: '.idle', actions: assign({ listData: (context, event) => event.data }) },
        CANCEL: '.idle',
        SUCCESS: '.idle'
    }
});


function ScreensList(props) {
    const { status, data, error, isFetching, refetch } = useQuery('feedback', fetchFeedbackList, { retry: false })
    const [state, send] = useMachine(fetchMachine, { context: { listData: data, selection: null } })
    const { listData = [], selection } = state.context;

    useEffect(() => {
        send('DATA_CHANGED', { data: data })
    }, [data])


    return (
        <Card>
            {state.matches('confirming') &&
                <ScreensConfirmModal isOpen={true} code={props.match.params.code} ID_TV={selection} onSuccess={(id) => { props.history.push({ pathname: `/feedback` }) }} onCancel={() => send('CANCEL')} />}
            <CardHeader>
                <CardTitle>
                    Configurar Pantalla
                    {isFetching &&
                        <div className="float-right mr-2">...</div>
                    }
                </CardTitle>
            </CardHeader>
            <LoadingContainer
                isLoading={status === "loading"}
                hasError={error != null && data === null}
                onRetry={() => refetch({ force: true })}>
                {state.matches('loading') ?
                    <div style={{ minHeight: '300px' }}>
                    </div> : null}
                <TableSearch
                    hideSearch
                    height="70vh"
                    keyField="ID_TV"
                    placeholder="Buscar Pantalla"
                    data={listData || []}
                    columns={[{
                        text: 'Nombre',
                        dataField: 'NAME',
                        formatExtraData: props.match.params.id,
                        sort: true,
                        formatter: (data, row, index, extra) => {
                            if (row.ID_TV === parseInt(extra, 10))
                                return (<b>{data}</b>)
                            else
                                return data
                        }
                    }, {
                        dataField: 'REPORTS',
                        text: 'Descripción',
                        sort: false,
                        classes: 'text-right',
                        headerClasses: 'text-right',
                        formatExtraData: props.match.params.id,
                        formatter: (value, row, index, extra) => {
                            if (row.ID_TV === parseInt(extra, 10))
                                return (<b>{`${JSON.parse(value).length || 0} Diapositivas`}</b>)
                            else
                                return `${JSON.parse(value).length || 0} Diapositivas`
                        }
                    }]}
                    selectRow={{
                        mode: 'radio',
                        hideSelectColumn: true,
                        clickToSelect: true,
                        onSelect: (row, isSelect, rowIndex, e) => {
                            send('CONFIRM', { data: row })
                        }
                    }}
                />
            </LoadingContainer>
        </Card>
    )
}

export default ScreensList
