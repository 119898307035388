import React from 'react'
import TableSearch from '../../../components/TableSearch'
import { LoadingContainer } from '../../../components/LoadingContainer';
import { Card, CardHeader, CardTitle } from 'reactstrap'
import { useQuery } from 'react-query'
import axios from "axios";

const columns = [{
    dataField: 'DESCRIPTION',
    text: 'Descripción',
    sort: true
}];

async function fetchData(key) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/alerts`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

function AlertsList(props) {
    const { status, data, error, isFetching, refetch } = useQuery('alerts', fetchData, {refetchInterval:5000, retry: false })

    return (
        <Card>
            <CardHeader className="card-header-divider">
                <CardTitle>
                    Alertas de Servicio
                    {isFetching && <div className="float-right">...</div>}
                </CardTitle>
            </CardHeader>
            <LoadingContainer isLoading={status === "loading"}
                hasError={status === "error" && error !== null}
                isEmpty={!status === "loading" && data.length === []}
                onRetry={() =>
                    refetch({ force: true })
                }>
                <TableSearch
                    hideSearch
                    keyField="DESCRIPTION"
                    placeholder="Buscar Alerta"
                    data={data  || []}
                    columns={columns}
                    selectRow={{
                        mode: 'radio',
                        hideSelectColumn: true,
                        clickToSelect: true,
                        onSelect: (row, isSelect, rowIndex, e) => {
                            props.history.push({ pathname: `${row.URL}` })
                        }
                    }}
                />
            </LoadingContainer>
        </Card>
    )
}

export default AlertsList
