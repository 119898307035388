import React, { useState, useEffect } from 'react'
import TableSearch from '../../../../components/TableSearch'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { Container, Card, CardTitle, CardHeader, Button } from 'reactstrap'
import useRequestReducer from '../../../../hooks/useRequestReducer';
import { Can } from '../../../../AbilityContext'

const columns = [{
    dataField: 'NAME',
    text: 'Nombre',
    sort: true
}];

function ResourcesList(props) {
    const [resourcesListData, setResourcesListData] = useState([]);
    const [resourcesData, dispatchResourcesData] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/resources`
    });

    useEffect(() => {
        if (resourcesData.response) {
            setResourcesListData(resourcesData.response.data);
        }
    }, [resourcesData.response])

    return (
        <Container fluid>
            <Card>
                <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', minHeight: '70px' }}>
                    <CardHeader className="card-header-divider" >
                        <CardTitle>
                            Recursos
                            <Can I="create" a="RESOURCES">
                                <Button hidden={resourcesData.loading} color="success" className="float-right" onClick={() => props.history.push({ pathname: `/resources/new` })}>Agregar</Button>
                            </Can>
                        </CardTitle>
                    </CardHeader>
                </div>
                <LoadingContainer
                    isLoading={resourcesData.loading}
                    hasError={resourcesData.error !== null}
                    onRetry={() => dispatchResourcesData({ type: 'SEND_REQUEST' })}>
                    <TableSearch
                        keyField="ID_RESOURCE"
                        placeholder="Buscar Recurso"
                        data={resourcesListData}
                        columns={columns}
                        selectRow={{
                            mode: 'radio',
                            hideSelectColumn: true,
                            clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => {
                                props.history.push({ pathname: `${props.match.url}/${row.ID_RESOURCE}/`, state: { prevPath: props.location.pathname } })
                            }
                        }}
                    />
                </LoadingContainer>
            </Card>
        </Container>
    )
}

export default ResourcesList