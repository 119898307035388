import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Input, Container } from 'reactstrap'
import useRequestReducer from '../../../../hooks/useRequestReducer'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { BasicEditForm } from '../../../../components/BasicEditForm';

function HospitalsEdit(props) {
    const [formData, setFormData] = useState({})
    const [originalData, setOriginalData] = useState({});

    const [state] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/hospitals/${props.match.params.idHospital}`
    });
    const [updateState, dispatchUpdate] = useRequestReducer({
        method: 'put',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/hospitals/${props.match.params.idHospital}`
    });
    const [deleteState, dispatchDelete] = useRequestReducer({
        method: 'delete',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/hospitals/${props.match.params.idHospital}`
    });

    useEffect(() => {
        if (state.response) {
            setFormData(state.response.data);
            setOriginalData(state.response.data);
        }
    }, [state.response])

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        })
    };

    return (
        <Container fluid>
            <Card>
                <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', minHeight: '70px' }}>
                    <CardHeader className="card-header-divider">
                        <CardTitle>Editar Hospital
                    {!state.loading ? <Button color="danger" className="float-right" onClick={() => dispatchDelete('SEND_REQUEST')}>Eliminar</Button> : null}
                        </CardTitle>
                    </CardHeader>
                </div>
                <CardBody>
                    <LoadingContainer isLoading={state.loading || updateState.loading || deleteState.loading}>
                        <BasicEditForm onValidSubmit={() => { dispatchUpdate({ type: 'SEND_REQUEST', request: { data: formData } }) }}>
                            <FormGroup>
                                <Label className="text-sm-right">Nombre</Label>
                                <Input required type="text" name="NAME" value={formData.NAME || ""} onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup >
                                <Label className="text-sm-right">Tipo</Label>
                                <Input required type="text" name="LOGO_IMAGE_URL" value={formData.LOGO_IMAGE_URL || ""} onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup >
                                <Label className="text-sm-right">Dirección</Label>
                                <Input required type="text" name="ADDRESS" value={formData.ADDRESS || ""} onChange={handleInputChange} />
                            </FormGroup>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup >
                                        <Label className="text-sm-right">Ciudad</Label>
                                        <Input required type="text" name="CITY" value={formData.CITY || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup >
                                        <Label className="text-sm-right">Estado</Label>
                                        <Input required type="text" name="STATE" value={formData.STATE || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup >
                                        <Label className="text-sm-right">Código Postal</Label>
                                        <Input required type="number" name="ZIP_CODE" value={formData.ZIP_CODE || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup >
                                        <Label className="text-sm-right">Latitud</Label>
                                        <Input required type="number" name="LATITUDE" value={formData.LATITUDE || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup >
                                        <Label className="text-sm-right">Longitud</Label>
                                        <Input required type="number" name="LONGITUDE" value={formData.LONGITUDE || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup >
                                <Label className="text-sm-right">Logo</Label>
                                <Input required type="text" name="ID_HOSPITAL_TYPE" value={formData.ID_HOSPITAL_TYPE || ""} onChange={handleInputChange} />
                            </FormGroup>
                            {JSON.stringify(originalData) !== JSON.stringify(formData) ?
                                <FormGroup>
                                    <Button block color="primary">Guardar Cambios</Button>
                                </FormGroup>
                                : null}
                        </BasicEditForm>
                    </LoadingContainer>
                </CardBody>
            </Card >
        </Container >
    )
}

export default HospitalsEdit
