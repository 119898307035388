import React, { useEffect } from 'react'
import { Card, CardHeader, CardBody, CardTitle, Button } from 'reactstrap'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import axios from "axios";
import TableSearch from '../../../../components/TableSearch';
import { useQuery, queryCache } from 'react-query'
import FeedbackAddModal from './FeedbackAddModal'
import { LoadingContainer } from '../../../../components/LoadingContainer';

async function fetchFeedbackList() {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/feedback`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

const fetchMachine = Machine({
    id: 'fetch',
    initial: 'idle',
    states: {
        idle: {
            on: {
                ADD: 'adding'
            }
        },
        adding: {}
    },
    on: {
        DATA_CHANGED: { target: '.idle', actions: assign({ listData: (context, event) => event.data }) },
        CANCEL: '.idle',
        SUCCESS: '.idle'
    }
});


function FeedbackList(props) {
    const { status, data, error, isFetching, refetch } = useQuery('feedback', fetchFeedbackList, { retry: false })
    const [state, send] = useMachine(fetchMachine, { context: { listData: data } })
    const { listData = [] } = state.context;

    useEffect(() => {
        send('DATA_CHANGED', { data: data })
    }, [data])


    return (
        <Card>
            {state.matches('adding') &&
                <FeedbackAddModal isOpen={state.matches('adding')} onSuccess={(id) => { send('SUCCESS'); queryCache.invalidateQueries('feedback'); props.history.push({ pathname: `/feedback/${id}` }) }} onCancel={() => send('CANCEL')} />}
            <CardHeader>
                <CardTitle>
                    Pantallas
                    {listData.length > 0 && <Button disabled={!state.matches('idle') || isFetching || error != null} color="success" className="float-right" onClick={() => send('ADD')}>Nueva</Button>}
                    {isFetching &&
                        <div className="float-right mr-2">...</div>
                    }
                </CardTitle>
            </CardHeader>
            <LoadingContainer
                isLoading={status === "loading"}
                hasError={error != null && data === null}
                onRetry={() => refetch({ force: true })}>
                {state.matches('loading') ?
                    <div style={{ minHeight: '300px' }}>
                    </div> : null}
                {listData.length === 0 ?
                    <CardBody>
                        <div style={{ height: '150px', top: '0px', bottom: '0px', left: '0px', right: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <p>Agregar una Pantalla</p>
                            <Button disabled={!state.matches('idle')} className="float-right" color="success" onClick={() => send('ADD')}>Agregar</Button>
                        </div>
                    </CardBody> :
                    <TableSearch
                        hideSearch
                        height="70vh"
                        keyField="ID_TV"
                        placeholder="Buscar Pantalla"
                        data={listData || []}
                        columns={[/*{
                            text: '',
                            dataField: 'ONLINE',
                            sort: true,
                            classes: 'text-center',
                            headerClasses: 'text-center',
                            formatter: (data) => {
                                return data ? "ON" : "OFF"
                            }
                        }, */{
                            text: 'Nombre',
                            dataField: 'NAME',
                            formatExtraData: props.match.params.id,
                            sort: true,
                            formatter: (data, row, index, extra) => {
                                if (row.ID_TV === parseInt(extra, 10))
                                    return (<b>{data}</b>)
                                else
                                    return data
                            }
                        }, {
                            text: 'Activas',
                            dataField: 'ONLINE',
                            sort: true,
                            classes: 'text-center',
                            headerClasses: 'text-center',
                            formatter: (data) => {
                                return data ? data : 0
                            }
                        }, {
                            dataField: 'REPORTS',
                            text: 'Descripción',
                            sort: false,
                            classes: 'text-right',
                            headerClasses: 'text-right',
                            formatExtraData: props.match.params.id,
                            formatter: (value, row, index, extra) => {
                                if (row.ID_TV === parseInt(extra, 10))
                                    return (<b>{`${JSON.parse(value).length || 0} Diapositivas`}</b>)
                                else
                                    return `${JSON.parse(value).length || 0} Diapositivas`
                            }
                        }]}
                        selectRow={{
                            mode: 'radio',
                            hideSelectColumn: true,
                            clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => {
                                props.history.push({ pathname: `/feedback/${row.ID_TV}/` })
                            }
                        }}
                    />}
            </LoadingContainer>
        </Card>
    )
}

export default FeedbackList
