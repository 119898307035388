import React, { useState, useEffect, useContext } from 'react'
import Map from '../../../../../components/Map';
import { Scatter } from 'react-chartjs-2';
import { CardHeader, CardTitle, CardBody, Modal, Button } from 'reactstrap'
import DepartmentZonesEdit from './DepartmentZonesEdit'
import DepartmentsContext from '../DepartmentsContext';

function DepartmentZones(props) {
    const { state ,send} = useContext(DepartmentsContext)
    const { originalData: department = {} } = state.context;
    const [zones, setZones] = useState([]);
    const [selection, setSelection] = useState();

    useEffect(()=>{
        if (department.zones) {
            let result = department.zones || [];
            let dataForGraph = [];
            for (let i = 0; i < result.length; i++) {
                let vertex = JSON.parse(department.zones[i].VERTEX || '[]');
                dataForGraph.push({
                    label: `My First dataset ${i}`,
                    data: vertex.map(corner => {
                        return {
                            x: corner[0],
                            y: corner[1]
                        }
                    }),
                    pointRadius: 0,
                    fill: true,
                    backgroundColor: 'rgba(36 , 199 , 166,.8)',
                    borderColor: 'rgba(2 , 0 , 135,1)',
                    borderWidth: 1,
                    showLine: true,
                    lineTension: 0
                });
            }
            setZones(dataForGraph);
        }
    },[department.zones])


    return (<div>
        <React.Fragment>
            <CardHeader>
                <CardTitle>Zonas de Interés
                        <Button color="success" className="float-right" onClick={() => send('ADD_ZONE')}>Agregar</Button>
                    </CardTitle>
            </CardHeader>
            <CardBody>
                    <div style={{ maxWidth: '500px', marginRight: 'auto', marginLeft: 'auto' }}>
                        <Map sizex={department.MAP_SIZE_X} sizey={department.MAP_SIZE_Y} mapImageURL={department.MAP_IMAGE_URL}>
                            <Scatter
                                getElementAtEvent={
                                    element => {
                                        element.length > 0 ?
                                            setSelection(zones[element[0]._index].label)
                                            : setSelection(null)
                                    }}
                                data={{
                                    datasets: zones
                                }} options={{
                                    tooltips: {
                                        yAlign: 'bottom',
                                        callbacks: {
                                            label: function(tooltipItem, data) {
                                                var label = data.datasets[0].data[tooltipItem.index].label || '';
                                                return label;
                                            }
                                        }
                                    },
                                    animation: false,
                                    legend: { display: false },
                                    scales: {
                                        xAxes: [{
                                            type: 'linear',
                                            ticks: {
                                                display: false, stepSize: 1.0, beginAtZero: true, max: department.MAP_SIZE_X, min: 0
                                            },
                                            gridLines: {
                                                color: 'rgba(100,100,100,.2)',
                                                tickMarkLength: 0
                                            }
                                        }],
                                        yAxes: [{
                                            type: 'linear',
                                            ticks: {
                                                display: false, stepSize: 1.0, beginAtZero: true, max: department.MAP_SIZE_Y, min: 0
                                            },
                                            gridLines: {
                                                color: 'rgba(100,100,100,.2)',
                                                tickMarkLength: 0
                                            }
                                        }]
                                    },
                                    maintainAspectRatio: false
                                }} />
                        </Map>
                    </div>
            </CardBody>
        </React.Fragment>
        <Modal isOpen={selection} >
            <DepartmentZonesEdit
                zone={selection}
                onClose={() => setSelection(null)}
                selected_zone={selection}
                statusTimes={{ onlineTime: 86400, offlineTime: 0 }}
            />
        </Modal>
    </div >
    )
}

export default DepartmentZones
