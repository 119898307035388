import React from 'react'
import { Switch, Route } from 'react-router-dom'
import TagsList from './TagsList';
import { TagsNew } from './TagsNew';
import TagsEdit from './TagsEdit';
import { Can } from '../../../../AbilityContext'

function Tags(props) {
    return (
        <Can I="read" a="TAGS">
            <Switch>
                <Route exact path='/tags' component={TagsList} />
                <Route exact path='/tags/new' component={TagsNew} />
                <Route path='/tags/:id' component={TagsEdit} />
            </Switch>
        </Can>
    )
}

export default Tags
