import React, { useState } from "react";
import { Modal, ModalHeader, Card, CardHeader, CardTitle, Container, Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import DateSelector from "../../../../components/DateSelector";
import { TuneIcon } from "mdi-react";
import axios from "axios";
import { useQuery } from "react-query";
import Filter from "./Filter";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import ComplianceHistoryCard from "./ComplianceHistoryCard";
import ComplianceProfessionCard from "./ComplianceProfessionCard";
import ComplianceDepartmentCard from "./ComplianceDepartmentCard";

var moment = require("moment-timezone");
require("moment/locale/es");
moment.locale("es");
moment.tz.setDefault("America/Monterrey");

function generateComplianceQuery(filter) {
  var fecha_inicial = moment(filter.fecha).startOf(filter.mode).format("YYYY-MM-DD HH:mm:ss");
  var fecha_final = moment(filter.fecha).endOf(filter.mode).format("YYYY-MM-DD HH:mm:ss");
  var query = `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&frecuencia=${filter.frecuencia}`;
  if (filter.department) query += `&id_department=${filter.department}`;
  if (filter.profession) query += `&id_profession_type=${filter.profession}`;
  console.log(query);
  return query;
}

async function fetchData(key, query) {
  const { data } = await axios({
    method: "get",
    url: `${process.env.REACT_APP_DATA_ENDPOINT}/compliance/?${query}`,
    headers: { "X-Access-Token": localStorage.getItem("auth_token") },
  });
  return data;
}

async function fetchDepartmentsAndProfessions(context) {
  var departments = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_ENDPOINT}/api/departments`,
    headers: { "X-Access-Token": localStorage.getItem("auth_token") },
  });
  var professions = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_ENDPOINT}/api/profession_types`,
    headers: { "X-Access-Token": localStorage.getItem("auth_token") },
  });
  return { departments, professions };
}

const statsMachine = new Machine({
  initial: "initial_url",
  states: {
    initial_url: {
      on: {
        "": { target: "loading", actions: assign({ query: (context) => generateComplianceQuery(context.filter) }) },
      },
    },
    loading: {
      invoke: {
        id: "load-variables",
        src: fetchDepartmentsAndProfessions,
        onDone: {
          target: "idle",
          actions: assign({ departments: (context, event) => event.data.departments.data, professions: (context, event) => event.data.professions.data }),
        },
        onError: { target: "error" },
      },
    },
    idle: {
      on: {
        MODE_CHANGED: {
          actions: [
            assign({
              filter: (context, event) => {
                var frecuencia = context.filter.frecuencia;
                switch (event.data) {
                  case "day":
                    frecuencia = "H";
                    break;
                  case "week":
                    frecuencia = "D";
                    break;
                  case "month":
                    frecuencia = "D";
                    break;
                  case "year":
                    frecuencia = "m";
                    break;
                }
                return {
                  ...context.filter,
                  fecha: moment(),
                  mode: event.data,
                  frecuencia: frecuencia,
                };
              },
            }),
            assign({ query: (context) => generateComplianceQuery(context.filter) }),
          ],
        },
        VIEW_CHANGED: {
          actions: assign({ filter: (context, event) => ({ ...context.filter, [event.name]: event.value }) }),
        },
        PREV_DATE: {
          actions: [
            assign({
              filter: (context, event) => {
                return {
                  ...context.filter,
                  fecha: moment(context.filter.fecha).subtract(1, context.filter.mode),
                };
              },
            }),
            assign({ query: (context) => generateComplianceQuery(context.filter) }),
          ],
        },
        NEXT_DATE: {
          actions: [
            assign({
              filter: (context, event) => {
                return {
                  ...context.filter,
                  fecha: moment(context.filter.fecha).add(1, context.filter.mode),
                };
              },
            }),
            assign({ query: (context) => generateComplianceQuery(context.filter) }),
          ],
        },
        SELECT_CHANGED: {
          actions: [assign({ filter: (context, event) => ({ ...context.filter, [event.name]: event.value }) }), assign({ query: (context) => generateComplianceQuery(context.filter) })],
        },
      },
    },
    error: {},
  },
});

function Stats(props) {
  const [isFiltering, setIsFiltering] = useState(false);
  const [state, send] = useMachine(statsMachine, {
    context: {
      filter: {
        fecha: moment(),
        frecuencia: "H",
        mode: "day",
        view: "percentage",
        oportunity: null,
        //...JSON.parse(localStorage.getItem("reportes_filter")),
      },
    },
  });

  const { query, filter = {} } = state.context;
  const { mode = "month", view = "percentage", oportunity = null } = filter;
  const { status, data = {}, error, isFetching, refetch } = useQuery(["compliance", query], fetchData, { cacheTime: 60 * 60 * 1000 });
  const { HISTORY = [], PROFESSIONS = [], DEPARTMENTS = [], USERS = null, VISITS = null, TOTAL = null } = data;

  return (
    <Container fluid>
      {isFiltering && (
        <Modal isOpen={isFiltering} size="sm" centered>
          <ModalHeader toggle={() => setIsFiltering(false)}>
            <CardTitle>Filtros</CardTitle>
          </ModalHeader>
          <Filter send={send} state={state} />
        </Modal>
      )}
      <Row>
        <Col lg={4} xl={3}>
          <Card>
            <CardHeader className="">
            <CardTitle>
                Reportes
                <Button className="float-right ml-3 d-block d-lg-none">
                <TuneIcon size={18} className="icon" onClick={() => setIsFiltering(true)} />
                </Button>
                <UncontrolledDropdown className="float-right ml-3">
                <DropdownToggle className="btn  btn-secondary" caret>
                    {(() => {
                    switch (mode) {
                        case "day":
                        return "Día";
                        case "week":
                        return "Semanal";
                        case "month":
                        return "Mes";
                        case "year":
                        return "Año";
                    }
                    })()}{" "}
                    ▾
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem id="day" onClick={() => send("MODE_CHANGED", { data: "day" })}>
                    Diario
                    </DropdownItem>
                    <DropdownItem id="week" onClick={() => send("MODE_CHANGED", { data: "week" })}>
                    Semanal
                    </DropdownItem>
                    <DropdownItem id="month" onClick={() => send("MODE_CHANGED", { data: "month" })}>
                    Mensual
                    </DropdownItem>
                    <DropdownItem id="year" onClick={() => send("MODE_CHANGED", { data: "year" })}>
                    Anual
                    </DropdownItem>
                </DropdownMenu>
                </UncontrolledDropdown>
            </CardTitle>
            </CardHeader>
            <DateSelector send={send} state={state} />
            <div className="d-none d-lg-block">
              <Filter send={send} state={state} />
            </div>
          </Card>
        </Col>
        <Col lg={8} xl={9}>
          <Row>
            <Col xs={6} sm={4}>
              <SneakElement label={"Usuarios"} qty={USERS} />
            </Col>
            <Col xs={6} sm={4}>
              <SneakElement label={"Visitas"} qty={VISITS} />
            </Col>
            <Col xs={6} sm={4}>
              <SneakElement label={"Apego"} qty={TOTAL ? `${parseFloat(TOTAL).toFixed(1)}%` : "-"} />
            </Col>
          </Row>
          <ComplianceHistoryCard data={HISTORY} mode={mode} loading={status === "loading"} view={view} oportunity={oportunity} />
          <Row>
            <Col xl={6}>
              <ComplianceProfessionCard data={PROFESSIONS} loading={status === "loading"} view={view} oportunity={oportunity} />
            </Col>
            <Col xl={6}>
              <ComplianceDepartmentCard data={DEPARTMENTS} loading={status === "loading"} view={view} oportunity={oportunity} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}





function SneakElement(props) {
  return (
    <div className="widget widget-tile" style={{ paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px" }}>
      <div className="widget-chart-container float-right">
        <div className="widget-counter-group widget-counter-group-right" style={{ paddingBottom: "5px" }}>
          <div className="counter counter-big">
            <div className="value">{props.qty || "-"}</div>
            <div className="desc">{props.label || ""}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;
