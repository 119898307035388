import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Input, Container, FormFeedback } from 'reactstrap'
import useRequestReducer from '../../../hooks/useRequestReducer'
import { LoadingContainer } from '../../../components/LoadingContainer';
import { BasicEditForm } from '../../../components/BasicEditForm';
import { useUser } from '../../../UserContext';


function AccountSettings(props) {
    const user = useUser();
    const [formData, setFormData] = useState({})
    const [originalData, setOriginalData] = useState({});
    const [data, setData] = useState({ password: "", password2: "", token: user.pwtoken });
    const [match, setMatch] = useState(true)

    const [state, dispatchGet] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/users/${user.ID_USER}`
    });

    const [updateState, dispatchUpdate] = useRequestReducer({
        method: 'put',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/users/${user.ID_USER}`
    });

    useEffect(() => {
        if (state.response) {
            setFormData(state.response.data);
            setOriginalData(state.response.data);
        }
    }, [state.response])

    useEffect(() => {
        dispatchGet({ type: 'SEND_REQUEST' });
    }, [updateState.response])

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        })
    };

    const handleInputChangePassword = event => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name]: value
        })
    };

    useEffect(() => {
        if (data.password === data.password2)
            setMatch(true)
        else
            setMatch(false)
    }, [data.password, data.password2])

    const [changePassword, dispatchChangePassword] = useRequestReducer({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/reset-password/${user.ID_USER}`
    });

    useEffect(() => {
        if (changePassword.response) {
            props.history.push({ pathname: `/login` })
        }
    }, [changePassword.response]);

    return (
        <Container fluid>
            <Card>
                <CardHeader className="card-header-divider">
                        <CardTitle>Ajustes de Cuenta
                        </CardTitle>
                </CardHeader>
                <LoadingContainer isLoading={state.loading || updateState.loading}>
                <CardBody>
                        <BasicEditForm onValidSubmit={() => { dispatchUpdate({ type: 'SEND_REQUEST', request: { data: formData } }) }}>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Nombre</Label>
                                        <Input required type="text" name="NAME" value={formData.NAME || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Apellido(s)</Label>
                                        <Input required type="text" name="LAST_NAME" value={formData.LAST_NAME || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={7}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input required type="text" name="EMAIL" value={formData.EMAIL || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Label>Teléfono</Label>
                                        <Input required type="text" name="PHONE" value={formData.PHONE || ""} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {JSON.stringify(originalData) !== JSON.stringify(formData) ?
                                <FormGroup>
                                    <Button block color="primary">Guardar Cambios</Button>
                                </FormGroup>
                                : null}
                        </BasicEditForm>

                </CardBody>
                </LoadingContainer>
            </Card>
        </Container>
    )
}

export default AccountSettings
