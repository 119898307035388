import React, { useEffect } from 'react'
import { LoadingContainer } from '../../../../../components/LoadingContainer';
import useRequestReducer from '../../../../../hooks/useRequestReducer';
import { Card, CardHeader, CardTitle, CardBody, Button } from 'reactstrap'

function DepartmentDispensersEdit(props) {

    const [deleteDispenser, dispatchDeleteDispenser] = useRequestReducer({
        method: 'delete',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/dispensers_positions/${props.dispenser.ID_POS_DISPENSER}`
    })

    useEffect(() => {
        if (deleteDispenser.response) {
            props.onClose();
        }
    }, [deleteDispenser.response])

    return (
        <Card>
            <LoadingContainer isLoading={false}>
                <CardHeader className="card-header-divider">
                    <CardTitle>{props.dispenser.BLE_ADDRESS}
                        <Button className="float-right" outline color="danger" onClick={props.onClose}>Cancelar</Button>
                        <Button className="float-right mr-3" color="danger" onClick={() => { dispatchDeleteDispenser({ type: 'SEND_REQUEST' }) }}>Eliminar</Button>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                </CardBody>
            </LoadingContainer>
        </Card>
    )
}

export default DepartmentDispensersEdit
