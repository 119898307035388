import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Input, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import { useQuery, queryCache } from 'react-query'
import axios from "axios";
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { BasicEditForm } from '../../../../components/BasicEditForm';
import { TrashCanIcon, DotsVerticalIcon, InformationIcon, VectorSquareIcon, SprayBottleIcon, RouterWirelessIcon } from 'mdi-react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { Can } from '../../../../AbilityContext'
import DepartmentGateways from './DepartmentGateways/DepartmentGateways';
import DepartmentDispensers from './DepartmentDispensers/DepartmentDispensers';
import DepartmentDispenserNewModal from './DepartmentDispensers/DepartmentDispenserNewModal';
import DepartmentZones from './DepartmentZones/DepartmentZones';
import DepartmentsContext from './DepartmentsContext';
import ConfirmDepartmentsUpdateModal from './ConfirmDepartmentsUpdateModal';
import ConfirmDepartmentsDeleteModal from './ConfirmDepartmentsDeleteModal';
import Map from '../../../../components/Map';
import { Scatter } from 'react-chartjs-2';
import DepartmentGatewayNewModal from './DepartmentGateways/DepartmentGatewayNewModal';
import DepartmentZoneNewModal from './DepartmentZones/DepartmentZoneNewModal';
import DepartmentGatewaysEdit from './DepartmentGateways/DepartmentGatewaysEdit';
var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');

async function fetchData(key, ID_DEPARTMENT) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/departments/${ID_DEPARTMENT}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

const fetchMachine = Machine({
    id: 'fetch',
    initial: 'idle',
    states: {
        idle: {
            on: {
                TYPING: { actions: 'typing' },
                TYPING_NUMBER: { actions: 'typing_number' },
                UPDATE: 'updating',
                DELETE: 'deleting',
                ADD_DISPENSER: 'adding_dispenser',
                ADD_GATEWAY: 'adding_gateway',
                EDIT_GATEWAY: { target: 'editing_gateway', actions: assign({ SELECTED_GATEWAY: (context, event) => event }) },
                ADD_ZONE: 'adding_zone'
            }
        },
        updating: {},
        deleting: {},
        adding_dispenser: {},
        adding_gateway: {},
        editing_gateway: {},
        adding_zone: {}
    },
    on: {
        DATA_CHANGED: {
            target: '.idle',
            actions: assign({
                originalData: (context, event) => event.data,
                formData: (context, event) => event.data
            })
        },
        CANCEL: '.idle',
        SUCCESS: '.idle'
    }
}, {
        actions: {
            typing: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: event.value }) }),
            typing_number: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: parseFloat(event.value) }) }),
        }
    });


function DepartmentEdit(props) {
    //const [locations, setLocations] = useState([]);
    const [id, setID] = useState(props.match.params.id)

    const [refetchActive, setRefetchActive] = useState(true);

    useEffect(() => {
        if (props.match.params.id)
            setID(props.match.params.id)
    }, [props.match.params.id])

    const { status, data, error, isFetching, refetch } = useQuery(['departments', id], fetchData, { retry: false, refetchInterval: refetchActive ? 5000 : false, refetchOnWindowFocus: refetchActive })

    const [state, send] = useMachine(fetchMachine, { context: { originalData: data, formData: data } })
    const { formData = {}, originalData = {}, SELECTED_GATEWAY } = state.context;

    useEffect(() => {
        if (data) {
            send('DATA_CHANGED', { data: data })
        }
    }, [data])

    useEffect(() => {
        if (state.value == 'idle') {
            setRefetchActive(true)
        }
        else {
            setRefetchActive(false)
        }
    }, [state.value])

    const pared = .125;
    const radian = (180 / 3.1416);
    var paredes = JSON.parse(formData.OBSTACLES || '[]')

    var cuadros = [];
    for (var i = 0; i < paredes.length; i++) {
        if (paredes[i].length > 1) {
            for (var j = 0; j < paredes[i].length - 1; j++) {
                var p0 = paredes[i][j];
                var p1 = paredes[i][j + 1];
                var angulo = Math.atan((p1[1] - p0[1]) / (p1[0] - p0[0])) * radian;
                if (p1[0] < p0[0]) {
                    if (p1[1] < p0[1])
                        angulo = 180 + Math.abs(angulo)
                    else
                        angulo = 180 - Math.abs(angulo)
                }

                cuadros.push({
                    label: `Prueba ${i} ${j}`,
                    data: [
                        { x: p0[0] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.cos((angulo - 135) / radian)), y: p0[1] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.sin((angulo - 135) / radian)) },
                        { x: p1[0] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.cos((angulo - 45) / radian)), y: p1[1] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.sin((angulo - 45) / radian)) },
                        { x: p1[0] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.cos((angulo + 45) / radian)), y: p1[1] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.sin((angulo + 45) / radian)) },
                        { x: p0[0] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.cos((angulo + 135) / radian)), y: p0[1] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.sin((angulo + 135) / radian)) },
                        { x: p0[0] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.cos((angulo - 135) / radian)), y: p0[1] + (Math.sqrt(2 * Math.pow(pared, 2)) * Math.sin((angulo - 135) / radian)) },
                    ],
                    pointRadius: 0,
                    pointBackgroundColor: 'rgba(1,1,1,1)',
                    pointBorderColor: 'rgba(1,1,1,1)',
                    fill: true,
                    backgroundColor: 'rgba(2 , 0 , 135,.5)',
                    borderColor: 'rgba(2 , 0 , 135,.5)',
                    borderWidth: 1,
                    showLine: true,
                    lineTension: 0
                })
            }
        }
    }

    /*useEffect(() => {
        const arrayOfLocations = [];
        for (var x = 0; x < formData.MAP_SIZE_X; x += .25) {
            for (var y = 0; y < formData.MAP_SIZE_Y; y += .25) {
                arrayOfLocations.push({ x: x, y: y });
            }
        }
        setLocations(arrayOfLocations);
    }, [formData])*/

    return (
        <DepartmentsContext.Provider value={{ state, send }}>
            <React.Fragment>
                {state.matches('updating') &&
                    <ConfirmDepartmentsUpdateModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('departments'); }} />}
                {state.matches('deleting') &&
                    <ConfirmDepartmentsDeleteModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { props.history.push({ pathname: `/departments` }); queryCache.invalidateQueries('departments'); }} />}
                {state.matches('adding_dispenser') &&
                    <DepartmentDispenserNewModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('departments') }} />}
                {state.matches('adding_gateway') &&
                    <DepartmentGatewayNewModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('departments') }} />}
                {state.matches('editing_gateway') &&
                    <DepartmentGatewaysEdit
                        ID_GATEWAY={SELECTED_GATEWAY.ID_GATEWAY}
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('departments') }} />}
                {state.matches('adding_zone') &&
                    <DepartmentZoneNewModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('departments') }} />}
                <div className="d-xs-block d-lg-none mb-2"><Link to="/departments">{"< Ver Todas los Departamentos"}</Link></div>
                <Card>
                    <CardHeader>
                        <CardTitle>
                            Editar Departamento
                            <Can I="delete" a="DEPARTMENTS">
                                <UncontrolledButtonDropdown className="float-right">
                                    <DropdownToggle caret color="link">
                                        <DotsVerticalIcon className="icon" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem color="danger" className="float-right" disabled={!state.matches('idle') || isFetching || error !== null} onClick={() => send('DELETE')}>
                                            <TrashCanIcon size={15} className="icon" />Eliminar
                                        </DropdownItem >
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Can>
                        </CardTitle>
                    </CardHeader>
                    {(status === "loading") &&
                        <LoadingContainer isLoading={true}>
                            <div style={{ minHeight: '400px' }}></div>
                        </LoadingContainer>}
                    {(status === "error") &&
                        <LoadingContainer
                            hasError={true}
                            onRetry={() => {
                                refetch({ force: true })
                            }}>
                            <div style={{ minHeight: '400px' }}></div>
                        </LoadingContainer>}
                    {(status === "success") &&
                        <React.Fragment>
                            <Tabs className="tab-container" defaultIndex={0}>
                                <TabList style={{ display: 'flex', cursor: 'pointer' }} className="nav nav-tabs nav-tabs-classic">
                                    <Tab style={{ flex: 1 }} className="nav-item" selectedClassName="nav-item-active" disabledClassName="nav-item-disabled"><a className={`nav-link`} style={{ padding: '11px 11px' }}><InformationIcon className="icon" size={18} /><div className="d-none  d-md-inline-block d-lg-none d-xl-inline-block mr-1">General</div></a></Tab>
                                    <Tab style={{ flex: 1 }} className="nav-item" selectedClassName="nav-item-active" disabledClassName="nav-item-disabled"><a className={`nav-link`} style={{ padding: '11px 11px' }}><VectorSquareIcon className="icon" size={18} /><div className="d-none  d-md-inline-block d-lg-none d-xl-inline-block mr-1">Zonas</div><span className={`badge badge-pill badge-default"}`} style={{ fontSize: 10 }}>{formData.zones ? formData.zones.length | 0 : 0}</span></a></Tab>
                                    <Tab style={{ flex: 1 }} className="nav-item" selectedClassName="nav-item-active" disabledClassName="nav-item-disabled"><a className={`nav-link`} style={{ padding: '11px 11px' }}><RouterWirelessIcon className="icon" size={18} /><div className="d-none  d-md-inline-block d-lg-none d-xl-inline-block mr-1">Gateways</div><span className={`badge badge-pill badge-default`} style={{ fontSize: 10 }}>{formData.gateways ? formData.gateways.length | 0 : 0}</span></a></Tab>
                                    <Tab style={{ flex: 1 }} className="nav-item" selectedClassName="nav-item-active" disabledClassName="nav-item-disabled"><a className={`nav-link`} style={{ padding: '11px 11px' }}><SprayBottleIcon className="icon" size={18} /><div className="d-none  d-md-inline-block d-lg-none d-xl-inline-block mr-1">Dispensadores</div><span className={`badge badge-pill badge-default"}`} style={{ fontSize: 10 }}>{formData.dispensers ? formData.dispensers.length | 0 : 0}</span></a></Tab>
                                </TabList>
                                <div>
                                    <TabPanel className="pt-1">
                                        <CardBody>
                                            <BasicEditForm onValidSubmit={() => send('UPDATE')}>
                                                <FormGroup>
                                                    <Label>Nombre</Label>
                                                    <Input required type="text" name="NAME" value={formData.NAME || ""} onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} autoComplete="off" />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Mapa y Dimensiones</Label>
                                                    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '350px', marginRight: 'auto', marginLeft: 'auto' }}>
                                                        <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                                                            <div className="mb-3" style={{ alignSelf: 'center' }}>
                                                                <Input type="number" name="MAP_SIZE_X" style={{ maxWidth: '60px' }} value={formData.MAP_SIZE_X || ""} onChange={(event) => { send('TYPING_NUMBER', { value: event.target.value, name: event.target.name }) }} autoComplete="off" />
                                                            </div>
                                                            <div>
                                                                <Map sizex={formData.MAP_SIZE_X} sizey={formData.MAP_SIZE_Y} mapImageURL={formData.MAP_IMAGE_URL}>
                                                                    <Scatter
                                                                        data={{
                                                                            datasets: [...cuadros/*, {
                                                                            label: 'My First dataset',
                                                                            data: locations,
                                                                            pointBackgroundColor: 'rgba(0,0,0,0)',
                                                                            pointBorderColor: 'rgba(0,0,0,0)',
                                                                            pointBorderWidth: 1,
                                                                            pointRadius: 3,
                                                                            pointHoverRadius: 6,
                                                                            pointHoverBackgroundColor: 'rgba(1,1,1,1)',
                                                                            pointHoverBorderColor: 'rgba(1,1,1,1)',
                                                                            fill: false,
                                                                            showLine: false,
                                                                        }*/]
                                                                        }} options={{
                                                                            animation: false,
                                                                            legend: { display: false },
                                                                            scales: {
                                                                                xAxes: [{
                                                                                    type: 'linear',
                                                                                    ticks: { display: false, stepSize: 1.0, beginAtZero: true, max: formData.MAP_SIZE_X, min: 0 },
                                                                                    gridLines: {
                                                                                        color: 'rgba(100,100,100,.2)',
                                                                                        tickMarkLength: 0
                                                                                    }
                                                                                }],
                                                                                yAxes: [{
                                                                                    type: 'linear',
                                                                                    ticks: { display: false, stepSize: 1.0, beginAtZero: true, max: formData.MAP_SIZE_Y, min: 0 },
                                                                                    gridLines: {
                                                                                        color: 'rgba(100,100,100,.2)',
                                                                                        tickMarkLength: 0
                                                                                    }
                                                                                }]
                                                                            },
                                                                            maintainAspectRatio: false
                                                                        }} />
                                                                </Map>
                                                            </div>
                                                        </div>
                                                        <div className="ml-3" style={{ paddingTop: '48px' }}>
                                                            <Input type="number" name="MAP_SIZE_Y" style={{ maxWidth: '60px' }} value={formData.MAP_SIZE_Y || ""} onChange={(event) => { send('TYPING_NUMBER', { value: event.target.value, name: event.target.name }) }} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                {JSON.stringify(originalData) !== JSON.stringify(formData) &&
                                                    <Can I="update" a="USERS"><Button disabled={isFetching} className="mt-3" block color="primary">Guardar Cambios</Button></Can>
                                                }
                                            </BasicEditForm>
                                        </CardBody>
                                    </TabPanel>
                                    <TabPanel>
                                        <DepartmentZones />
                                    </TabPanel>
                                    <TabPanel>
                                        <DepartmentGateways />
                                    </TabPanel>
                                    <TabPanel>
                                        <DepartmentDispensers />
                                    </TabPanel>
                                </div>
                            </Tabs>
                        </React.Fragment>}
                </Card>
            </React.Fragment>
        </DepartmentsContext.Provider>
    )
}

export default DepartmentEdit
