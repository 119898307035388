import { Line } from 'react-chartjs-2';
import React, { useState } from "react"
import axios from 'axios';
import {
    useQuery
} from 'react-query'
import { LoadingContainer } from '../../../../../components/LoadingContainer';
import { Label } from 'reactstrap'

var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');

async function fetchData({ ID_GATEWAY, period }) {
    var fecha_inicial;
    var fecha_final = moment().format('YYYY-MM-DD HH:mm');
    var sampling;
    switch (period) {
        case 0:
            fecha_inicial = moment().subtract(1, 'days').format('YYYY-MM-DD HH');
            sampling = 'H'
            break;
        case 1:
            fecha_inicial = moment().subtract(7, 'days').format('YYYY-MM-DD');
            sampling = 'D'
            break;
        case 2:
            fecha_inicial = moment().subtract(30, 'days').format('YYYY-MM-DD');
            sampling = 'D'
            break;
        default:
            break;
    }

    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_DATA_ENDPOINT}/gateways_status/${ID_GATEWAY}?fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&frecuencia=${sampling}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

const options = (period) => {
    return ({
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0
        },
        responsiveAnimationDuration: 0,
        pointRadius: 0,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                        tickMarkLength: 3
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        fontSize: 10,
                        callback: function (value, index, values) {
                            if (index == 0 || index == values.length - 1)
                                return period == 0 ? [`${moment(value).format('DD-MMM')}`, `${moment(value).format('HH:mm')}`] : moment(value).format('DD-MMM');
                            else
                                return ''
                        }
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        zeroLineWidth: 5,
                        zeroLineColor: 'rgba(0,0,0,1)'
                    },
                    display: false,
                    ticks: {
                        beginAtZero: true,
                        min: -.01,
                        max: 1.1
                    }
                },
            ],
        },
        legend: {
            display: false
        }
    })

}



export default function StatusChart({ ID_GATEWAY }) {

    const [period, setPeriod] = useState(0);
    const { isFetching, isLoading, error, data = {HISTORY:[]} } = useQuery(['online-data', { ID_GATEWAY, period }], () => fetchData({ ID_GATEWAY, period }), { enabled: ID_GATEWAY })

    return (
        <div>
            <Label>Status</Label>
            <div className="float-right">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={() => setPeriod(0)}>Día</button>
                    <button onClick={() => setPeriod(1)}>Semana</button>
                    <button onClick={() => setPeriod(2)}>Mes</button>
                </div>
            </div>
            {error ?
                <div>
                    <p className="text-center mt-5 text-bold">{error.name}</p>
                    <p className="text-center mb-5">{error.message}</p>
                </div>
                :
                <LoadingContainer message={"Cargando..."} isLoading={isLoading || !data.HISTORY}>
                        <Line id='chart' data={(canvas) => {
                            const ctx = canvas.getContext("2d");
                            const gradient = ctx.createLinearGradient(0, 0, 0, 300);
                            gradient.addColorStop(0, 'rgba(102, 170, 212, 1)');
                            gradient.addColorStop(1, 'rgba(238, 252, 254, 1)');

                            return {
                                labels: data.HISTORY.map((element) => element.FECHA),
                                datasets: [
                                    {
                                        fill: 'start',
                                        backgroundColor: gradient,
                                        borderColor: "#24608f",
                                        borderWidth: 2,
                                        pointHoverRadius: 7,
                                        pointHitRadius: 5,
                                        pointRadius: 0,
                                        data: data.HISTORY.map((element) => element.STATUS),
                                    }
                                ]
                            }
                        }} options={options(period)} height={160} /> 
                </LoadingContainer>}
        </div>)
}