import React, {  useContext } from 'react'
import {  Row, Col, CardTitle, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import axios from "axios";
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { useMachine } from '@xstate/react';
import { Machine } from 'xstate';
import FeedbackContext from './FeedbackContext'

function invokeDeleteConcepto(context) {
    const { ID_TV } = context;
    return axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/feedback/${ID_TV}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    });
}

const deleteConceptoMachine = Machine({
    initial: 'confirming',
    states: {
        confirming: {
            on: {
                CONFIRM_DELETE: 'sending_data'
            }
        },
        sending_data: {
            invoke: {
                id: 'sending-data',
                src: invokeDeleteConcepto,
                onDone: {
                    actions: (context, event) => context.onSuccess() //Close Modal
                },
                onError: 'error'
            }
        },
        error: {
            on: {
                RETRY: 'confirming'
            }
        }
    }
})

function FeedbackDeleteModal(props) {
    const { state: feedbackState } = useContext(FeedbackContext)
    const { formData } = feedbackState.context;
    const [state, send] = useMachine(deleteConceptoMachine, { context: { ...formData, onSuccess: props.onSuccess } })

    return (
        <Modal isOpen={props.isOpen} size="sm" centered>
            <ModalHeader>
                <CardTitle>Eliminar Pantalla</CardTitle>
            </ModalHeader>
            <LoadingContainer isLoading={state.matches('sending_data')}>
                {state.matches('sending_data') ?
                    <div style={{ minHeight: '150px' }}>
                    </div> : null}
                {state.matches('error') ?
                    <ModalBody>
                        <p className="text-center mt-5 mb-5">Error</p>
                        <Button block color="primary" onClick={() => send('RETRY')}>Reintentar</Button>
                    </ModalBody>
                    : null}
                {state.matches('confirming') ?
                    <ModalBody>
                        <p className="text-center mb-5">¿Confirma que desea eliminar esta Pantalla?<br /><b>Esta acción no se puede deshacer</b></p>
                        <Row>
                            <Col xs={6}>
                                <Button block onClick={props.onCancel}>Cancelar</Button>
                            </Col>
                            <Col xs={6}>
                                <Button color="danger" block onClick={() => send('CONFIRM_DELETE')}>Eliminar</Button>
                            </Col>
                        </Row>
                    </ModalBody> : null}
            </LoadingContainer>
        </Modal>
    )
}

export default FeedbackDeleteModal
