import React, { useContext } from 'react'
import { Row, Col, CardTitle, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import axios from "axios";
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';


function invokeUpdate(context) {
    console.log(context)
    return axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/tv_login/${context.code}`,
        data: { ID_TV: context.ID_TV },
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    });
}

const assignMachine = Machine({
    initial: 'confirming',
    states: {
        confirming: {
            on: {
                CONFIRM_UPDATE: 'sending_data'
            }
        },
        sending_data: {
            invoke: {
                id: 'sending-data',
                src: invokeUpdate,
                onDone: {
                    actions: (context, event) => context.onSuccess() //Close Modal
                },
                onError: { target: 'error', actions: [assign({ errorData: (context, event) => event.data.response.data })] }
            }
        },
        error: {
            on: {
                RETRY: 'confirming'
            }
        }
    }
})

function ScreensConfirmModal(props) {
    const [state, send] = useMachine(assignMachine, { context: { code: props.code, ID_TV: props.ID_TV, onSuccess: props.onSuccess } })
    const { errorData = {} } = state.context

    return (
        <Modal isOpen={props.isOpen} size="sm" centered>
            <ModalHeader>
                <CardTitle>Confirmar Configuración</CardTitle>
            </ModalHeader>
            <LoadingContainer isLoading={state.matches('sending_data')}>
                {state.matches('sending_data') ?
                    <div style={{ minHeight: '150px' }}>
                    </div> : null}
                {state.matches('error') ?
                    <ModalBody>
                        <p className="text-center mt-5 text-bold">{errorData.name}</p>
                        <p className="text-center mb-5">{errorData.message}</p>
                        <Button block color="primary" onClick={() => send('RETRY')}>Reintentar</Button>
                    </ModalBody>
                    : null}
                {state.matches('confirming') ?
                    <ModalBody>
                        <p className="text-center mb-5">
                            El contenido seleccionado se mostrará en la pantalla<br />
                            ¿Desea confirmar configuración de pantalla?
                        </p>
                        <Row>
                            <Col xs={6}>
                                <Button block onClick={props.onCancel}>Cancelar</Button>
                            </Col>
                            <Col xs={6}>
                                <Button color="primary" block onClick={() => send('CONFIRM_UPDATE')}>Confirmar</Button>
                            </Col>
                        </Row>
                    </ModalBody> : null}
            </LoadingContainer>
        </Modal>
    )
}

export default ScreensConfirmModal
