import React, { useState, useEffect } from 'react'
import {  Line } from "react-chartjs-2"
import { Card, CardTitle, CardHeader } from 'reactstrap'
import moment from 'moment-timezone'
import LoadingContainer from '../../../../components/LoadingContainer';
import useRequestReducer from '../../../../hooks/useRequestReducer';

moment.locale('es');
moment.tz.setDefault("America/Monterrey");

const options = {
    maintainAspectRatio: true,
    legend: {
        display: false
    },
    scales: {
        yAxes: [{
            gridLines: {
                tickMarkLength: 0
            },
            ticks: {
                fontSize: 8,
                beginAtZero: true,
                min: 0,
                max: 100,
                callback: function (value, index, values) {
                    return value + '%';
                }
            },
            stacked: true
        }],
        xAxes: [{
            ticks: {
                display: false
            },
            gridLines: {
                display: false
            },
            stacked: true
        }]
    }
};


function ComplianceDateByHour(props) {
    const [data, setData] = useState({})
    const [raw, setRaw] = useState([]);
    const [summary, setSummary] = useState({});
    const [getData, dispatchGetData] = useRequestReducer({
        method: 'put',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/compliance/day?INITIAL_DATE=${moment().subtract(30, 'days').format("YYYY-MM-DD HH:mm:ss")}`
    })

    useEffect(() => {
        switch (props.filter.MODE) {
            default:
                dispatchGetData({
                    type: 'SEND_REQUEST',
                    request: {
                        method: 'get',
                        url: `${process.env.REACT_APP_API_ENDPOINT}/api/compliance/day?INITIAL_DATE=${moment(props.filter.FROM_DATE).format("YYYY-MM-DD HH:mm:ss")}&FINAL_DATE=${moment(props.filter.END_DATE).format("YYYY-MM-DD HH:mm:ss")}${props.filter.ID_DEPARTMENT ? `&ID_DEPARTMENT=${props.filter.ID_DEPARTMENT}` : ``}${props.filter.ID_PROFESSION_TYPE ? `&ID_PROFESSION_TYPE=${props.filter.ID_PROFESSION_TYPE}` : ``}${props.filter.ID_USER ? `&ID_USER=${props.filter.ID_USER}` : ``}${props.filter.ID_ZONE ? `&ID_ZONE=${props.filter.ID_ZONE}` : ``}`
                    }
                })
                break;
        }
    }, [props.filter])

    useEffect(() => {
        if (getData.response) {
            setRaw(getData.response.data);
            let labels = [];
            switch (props.filter.MODE) {
                default:
                    labels = getData.response.data.map((element) => moment(element.DATE).format('H'))
                    break;
            }

            let goods;
            switch (props.filter.COMPUTE) {
                default:
                    goods = getData.response.data.map((element) => element.COUNT ? (element.ENTRADA + element.SALIDA) / (element.COUNT * 2) * 100 : 0)
                    break;
            }

            let colorsgood = getData.response.data.map(() => '#24c7a6')
            setData({
                datasets: [
                    { type: 'line', data: goods, pointRadius: 2, fill: false, backgroundColor: colorsgood, label: 'OK' }
                ],
                labels: labels
            })
        }
    }, [getData.response])

    useEffect(() => {
        var totalElements = raw.filter(element => element.COUNT !== null || element.COUNT > 0).map(element => {
            return element.COUNT
        }).reduce((total, num) => {
            return total += num;
        }, 0)

        var totalEntradas = raw.filter(element => element.COUNT !== null || element.COUNT > 0).map(element => {
            return element.ENTRADA
        }).reduce((total, num) => {
            return total += num;
        }, 0)

        var totalSalidas = raw.filter(element => element.COUNT !== null || element.COUNT > 0).map(element => {
            return element.SALIDA
        }).reduce((total, num) => {
            return total += num;
        }, 0)

        switch (props.filter.COMPUTE) {
            case "in":
                setSummary({
                    total: ((totalEntradas) / (totalElements) * 100).toFixed(1),
                })
                break;
            case "out":
                setSummary({
                    total: ((totalSalidas) / (totalElements) * 100).toFixed(1),
                })
                break;
            default:
                setSummary({
                    total: ((totalEntradas + totalSalidas) / (totalElements * 2) * 100).toFixed(1),
                })
                break;
        }

    }, [raw])

    return (
        <Card>
            <CardHeader className="card-header-divider">
                <CardTitle>
                    Último mes
                </CardTitle>
            </CardHeader>
            <LoadingContainer
                isLoading={getData.loading}
                isEmpty={!getData.loading && !(raw.filter(element => element.COUNT > 0 && element.COUNT !== null).length > 0)}
                hasError={getData.error !== null}
                onRetry={() =>
                    dispatchGetData({ type: 'SEND_REQUEST' })}>
                <div className="mt-3">
                    <div className="widget-chart-container float-right">
                        <div className="widget-counter-group widget-counter-group-right">
                            {summary.total ?
                                <div className="counter counter-big">
                                    <div className="value">{!Number.isNaN(parseFloat(summary.total)) ? `${summary.total}%` : '-'}</div>
                                    <div className="desc">Total</div>
                                </div> : null}
                        </div>
                    </div>
                    <Line
                        data={data}
                        options={options} />
                </div>
            </LoadingContainer>
        </Card>
    )
}

export default ComplianceDateByHour
