import React from 'react'
import { Switch, Route } from 'react-router-dom'
import GatewaysList from './GatewaysList';
import GatewaysEdit from './GatewaysEdit';
import { Can } from '../../../../AbilityContext'


function Gateways(props) {
    return (
        <Can I="read" a="GATEWAYS">
            <Switch>
                <Route exact path='/gateways' component={GatewaysList} />
                <Route path='/gateways/:id' component={GatewaysEdit} />
            </Switch>
        </Can>
    )
}

export default Gateways
