import React from 'react'
import {Button} from 'reactstrap'
import {ChevronRightIcon} from 'mdi-react'

function ButtonLabel(props) {
    var localProps = { ...props };
    delete localProps.isButton
    if (props.isButton)
        return (
            <Button className="text-left mb-2 pt-1" {...localProps} style={{ ...localProps.style, lineHeight: 'normal', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                <div>
                    {props.children}
                </div>
                <ChevronRightIcon style={{ flexShrink: 0 }} className="icon" />
            </Button>
        )
    else
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        )

}

export default ButtonLabel
