import React from 'react'
import {  FormGroup,  Input, Label, Row, Col, Modal, ModalHeader, ModalBody, CardTitle, Button } from 'reactstrap'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import axios from "axios";
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { BasicEditForm } from '../../../../components/BasicEditForm';

function invokePost(context) {
    return axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/users`,
        data: context.formData,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    });
}

function invokeLoad(context) {
    return axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/profession_types`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    });
}

const addMachine = Machine({
    initial: 'loading',
    states: {
        loading: {
            invoke: {
                id: 'loading-data',
                src: 'loadData',
                onDone: {
                    target: 'selecting_data',
                    actions: assign({ profession_types: (context, event) => event.data.data })
                },
                onError: 'error_loading'
            }
        },
        error_loading: {
            on: {
                RETRY: 'loading'
            }
        },
        selecting_data: {
            on: {
                TYPING: { actions: 'typing' },
                CONFIRM_DATA: 'sending_data',
                SELECT_CHANGE: { actions: 'select_changed' },
            }
        },
        sending_data: {
            invoke: {
                id: 'sending-data',
                src: 'postData',
                onDone: {
                    target: '',
                    actions: (context, event) => { context.onSuccess(event.data.data.insertId) } //Close Modal
                },
                onError: 'error'
            }
        },
        error: {
            on: {
                RETRY: 'selecting_data'
            }
        }
    }
}, {
        actions: {
            typing: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: event.value }) }),
            select_changed: assign({
                formData: (context, event) => {
                    const value = parseInt(event.value, 10)
                    return { ...context.formData, [event.name]: value }
                }
            })
        },
        services: {
            loadData: invokeLoad,
            postData: invokePost
        }
    })


function UsersCreate(props) {
    const [state, send] = useMachine(addMachine, { context: { onSuccess: props.onSuccess, formData: {} } })
    const { formData, profession_types=[] } = state.context
    console.log(profession_types)
    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader toggle={props.onCancel}>
                <CardTitle>Nuevo Usuario</CardTitle>
            </ModalHeader>
            {state.matches('error') || state.matches('error_loading') ?
                <ModalBody>
                    <p className="text-center mt-5 mb-5">Error</p>
                    <Button block color="primary" onClick={() => send('RETRY')}>Reintentar</Button>
                </ModalBody>
                :
                <LoadingContainer message={state.matches("sending_data") ? "Creando Usuario..." : "Cargando..."} isLoading={state.matches('loading') || state.matches('sending_data')}>
                    {state.matches('sending_data') ?
                        <div style={{ minHeight: '150px' }}>
                        </div> : null}
                    {state.matches('selecting_data') ?
                        <ModalBody>
                            <BasicEditForm onValidSubmit={() => send('CONFIRM_DATA')}>
                                <Row form>
                                    <Col xs={12} sm={6}>
                                        <FormGroup>
                                            <Label>Nombre(s) *</Label>
                                            <Input required type="text" name="NAME" value={formData.NAME || ""} autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FormGroup>
                                            <Label>Apellido(s) *</Label>
                                            <Input required type="text" name="LAST_NAME" value={formData.LAST_NAME || ""} autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>Profesión *</Label>
                                            <Input required type="select" name="ID_PROFESSION_TYPE" value={formData.ID_PROFESSION_TYPE || ""} onChange={(event) => { send('SELECT_CHANGE', { value: event.target.value, name: event.target.name }) }} autoComplete="off">
                                                <option value="" disabled> Seleccione una Profesión</option>
                                                {profession_types.map((element) => {
                                                    return <option key={element.ID_PROFESSION_TYPE} value={element.ID_PROFESSION_TYPE}>{element.NAME}</option>
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FormGroup>
                                            <Label>Email <i>(opcional)</i></Label>
                                            <Input type="email" name="EMAIL" value={formData.EMAIL || ""} autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FormGroup>
                                            <Label>Teléfono <i>(opcional)</i></Label>
                                            <Input type="tel" name="PHONE" value={formData.PHONE || ""} autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <Button className="mt-3" block color="success">Crear Usuario</Button>
                                    </Col>
                                </Row>
                            </BasicEditForm>
                        </ModalBody> : null}
                </LoadingContainer>}
        </Modal>
    )
}

export default UsersCreate
