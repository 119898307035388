import React from 'react'
import { useAuth } from './AuthContext'
import { createContextualCan } from '@casl/react'
import { Ability } from '@casl/ability'

const AbilityContext = React.createContext()

const AbilityProvider = props => {
    var abilities = [];
    useAuth().abilities.map(abilityArray => {
        return Object.keys(abilityArray).map(function (key, index) {
            if (abilityArray[key] && abilityArray[key].length > 0)
                abilities.push({ subject: key, actions: abilityArray[key] })
            return "";
        });
    })
    return (<AbilityContext.Provider value={new Ability(abilities)} {...props} />)
}

const Can = createContextualCan(AbilityContext.Consumer)
const useAbilities = () => React.useContext(AbilityContext)

export { AbilityProvider, Can, useAbilities }