import React, { useState, useEffect } from "react";
import "../assets/css/app.css"
import logo from "../assets/img/aidy_white.png"
import { LoadingContainer } from "../components/LoadingContainer";
import { BasicEditForm } from '../components/BasicEditForm';
import { Card, CardHeader, CardBody, FormGroup, Input, Button, Label } from 'reactstrap'
import { CloseCircleOutlineIcon } from 'mdi-react';
import useRequestReducer from "../hooks/useRequestReducer";
import background from "../assets/img/login-background.jpg"

function ForgotPassword(props) {
    const [data, setData] = useState({ email: "" });

    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name]: value
        })
    };

    const [sendEmail, dispatchSendEmail] = useRequestReducer({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/forgot-password`
    });

    useEffect(() => {
        if (sendEmail.response) {
            console.log(sendEmail.response)
        }
    }, [sendEmail.response]);

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflow: 'auto',
            background: 'black'
        }}>
            <img src={background} alt="" style={{ height: '100vh', width: '100%', objectFit: 'cover', opacity: 0.5 }} />
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: "0", left: "0" }}>
                <div className="splash-container forgot-password">
                    <Card>
                        <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 0%, #020087 100%)', minHeight: '70px' }}>
                            <CardHeader>
                                <img className="logo-img" src={logo} alt="logo" width="225" height="#{conf.logoHeight}"></img>
                                <span className="splash-description" style={{ color: 'white' }}>Recuperar Contraseña</span>
                            </CardHeader>
                        </div>
                        <LoadingContainer isLoading={sendEmail.loading}>
                            {sendEmail.response ?
                                <CardBody className="text-center">
                                    <Label>
                                        Se ha enviado un Email para Recuperar su Contraseña.
                                        En caso de no encontrarlo. Revise su Buzón de Spam
                                    </Label>
                                </CardBody> :
                                <CardBody>
                                    {sendEmail.error ?
                                        <div className="alert alert-danger alert-simple alert-dismissible" role="alert">
                                            <button className="close" type="button" data-dismiss="alert" aria-label="Close"><span className="mdi mdi-close" aria-hidden="true"></span></button>
                                            <div className="icon"> <CloseCircleOutlineIcon /></div>
                                            <div className="message"><strong>{sendEmail.error.data.message}</strong></div>
                                        </div>
                                        : null}
                                    <Label>Te enviaremos un email para reestablecer tu contraseña.</Label>
                                    <BasicEditForm
                                        onValidSubmit={() => { dispatchSendEmail({ type: 'SEND_REQUEST', request: { data: { ...data } } }) }}>
                                        <FormGroup row>
                                            <Input
                                                className="text-center"
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                value={data.email}
                                                onChange={handleInputChange}
                                                required />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Button color="primary" size="xl" block>Solicitar Contraseña</Button>
                                        </FormGroup>
                                    </BasicEditForm>
                                </CardBody>}
                        </LoadingContainer>
                    </Card>
                    <div className="splash-footer">&copy; 2018 Aidy Technologies</div>
                </div>
            </div>
        </div>)
}

export default ForgotPassword;