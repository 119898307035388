import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DispensersList from './DispensersList';
import DispensersEdit from './DispensersEdit';
import { Can } from '../../../../AbilityContext'

function Dispensers() {
    return (
        <Can I="read" a="DISPENSERS">
            <Switch>
                <Route exact path='/dispensers' component={DispensersList} />
                <Route path='/dispensers/:id' component={DispensersEdit} />
            </Switch>
        </Can>
    )
}

export default Dispensers
