import React, { useState, useEffect } from 'react'
import { BasicEditForm } from '../../../../components/BasicEditForm'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import useRequestReducer from '../../../../hooks/useRequestReducer';
import { Container, Button, ButtonGroup, Card, CardTitle, CardHeader, CardBody, Col, Label, FormGroup, FormFeedback, Input } from 'reactstrap'
import { Can } from '../../../../AbilityContext'

function RolesEdit(props) {
    const [originalData, setOriginalData] = useState({ PERMISSIONS: {} });

    const [resources, setRecursos] = useState([]);
    const [resourcesData, dispatchResourcesData] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/resources`
    });

    useEffect(() => {
        if (resourcesData.response)
            setRecursos(resourcesData.response.data);
    }, [resourcesData.response])

    const [formData, setFormData] = useState({ PERMISSIONS: {} });
    const [getData, dispatchGet] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/roles/${props.match.params.id}`
    });

    useEffect(() => {
        if (getData.response) {
            setOriginalData({ ...getData.response.data, PERMISSIONS: JSON.parse(getData.response.data.PERMISSIONS) });
            setFormData({ ...getData.response.data, PERMISSIONS: JSON.parse(getData.response.data.PERMISSIONS) });
        }
    }, [getData.response])

    const [updateData, dispatchUpdate] = useRequestReducer({
        method: 'put',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/roles/${props.match.params.id}`
    });

    const [deleteData, dispatchDelete] = useRequestReducer({
        method: 'delete',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/roles/${props.match.params.id}`
    });

    useEffect(() => {
        if (deleteData.response) {
            if (deleteData.response.status === 200) {
                props.history.push({ pathname: `/roles` })
            }
        }
    }, [deleteData.response])

    useEffect(() => {
        dispatchGet({ type: 'SEND_REQUEST' });
    }, [updateData.response])

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        })
    };

    const selectedPermission = (recurso, permiso) => {
        let original = formData.PERMISSIONS;
        if (original.hasOwnProperty(recurso)) {
            const indexPermiso = original[recurso].indexOf(permiso);
            if (indexPermiso < 0) {
                original[recurso].push(permiso);
            } else {
                original[recurso].splice(indexPermiso, 1);
            }
        }
        else {
            original = { ...original, [recurso]: [permiso] }
        }
        setFormData({ ...formData, PERMISSIONS: original })
    }

    return (
        <Container fluid>
            <Card>
                <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', minHeight: '70px' }}>
                    <CardHeader className="card-header-divider" >
                        <CardTitle>
                            Editar Rol
                            {!getData.loading && getData.state==null ? <Can I="delete" a="ROLES"><Button color="danger" className="float-right" onClick={() => dispatchDelete('SEND_REQUEST')}>Eliminar</Button></Can> : null}
                        </CardTitle>
                    </CardHeader>
                </div>
                <CardBody>
                    <LoadingContainer
                        isLoading={getData.loading  || resourcesData.loading || updateData.loading || deleteData.loading}
                        hasError={getData.error !== null || resourcesData.error !== null}
                        onRetry={() => {
                            dispatchGet({ type: 'SEND_REQUEST' })
                            dispatchResourcesData({ type: 'SEND_REQUEST' })
                        }}>
                        <BasicEditForm
                            onValidSubmit={() => dispatchUpdate({ type: 'SEND_REQUEST', request: { data: { ...formData, PERMISSIONS: JSON.stringify(formData.PERMISSIONS) } } })} >
                            <Can I="update" a="ROLES" passThrough>
                                {can =>
                                    <fieldset disabled={!can}>
                                        <FormGroup row>
                                            <Label sm={3} className="text-sm-right">Nombre</Label>
                                            <Col sm={8} lg={6}><Input type="text" name="NAME" value={formData.NAME || ""} onChange={handleInputChange} required />
                                                <FormFeedback>Es necesario proporcionar un Nombre al Rol</FormFeedback></Col>
                                        </FormGroup>
                                        <CardTitle>Permisos</CardTitle>
                                        <hr />
                                        {resources.map(element => {
                                            return (<div key={element.ID_RESOURCE}>
                                                <FormGroup row>
                                                    <Label sm={3} className="text-sm-right">{element.NAME}</Label>
                                                    <Col sm={8} lg={6}>
                                                        <ButtonGroup>
                                                            {JSON.parse(element.PERMISSIONS).map(permiso => {
                                                                return <Button key={`${permiso}${element.ID_RESOURCE}`}
                                                                    onClick={() => selectedPermission(element.NAME, permiso)}
                                                                    active={(formData.PERMISSIONS[element.NAME] || []).includes(permiso) || false}
                                                                >{permiso}
                                                                </Button>
                                                            })}
                                                        </ButtonGroup>
                                                    </Col>
                                                </FormGroup>
                                            </div>)
                                        })}
                                        {JSON.stringify(originalData) !== JSON.stringify(formData) ?
                                            <FormGroup>
                                                <Can I="update" a="ROLES"><Button block color="primary">Guardar Cambios</Button></Can>
                                            </FormGroup>
                                            : null}
                                    </fieldset>}
                            </Can>
                        </BasicEditForm>
                    </LoadingContainer>
                </CardBody>
            </Card>
        </Container>
    )
}

export default RolesEdit