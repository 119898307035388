import React, { useEffect, useState } from 'react'
import {  Card, CardHeader, CardTitle, CardBody, Button,  FormGroup, Label, Input, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import axios from "axios";
import TableSearch from '../../../../components/TableSearch';
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { TrashCanIcon, DotsVerticalIcon, ChartBarIcon } from 'mdi-react'
import { useQuery,  queryCache } from 'react-query'
import { BasicEditForm } from '../../../../components/BasicEditForm';
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import AddSlideModal from './AddSlideModal';
import FeedbackDeleteModal from './FeedbackDeleteModal';
import FeedbackUpdateModal from './FeedbackUpdateModal';
import FeedbackContext from './FeedbackContext'

const fetchMachine = Machine({
    id: 'fetch',
    initial: 'idle',
    states: {
        idle: {
            on: {
                TYPING: { actions: 'typing' },
                EDIT_SLIDE: 'editing_slide',
                ADD_SLIDE: 'adding_slide',
                UPDATE: 'updating',
                DELETE: 'deleting',
                DELETE_SLIDE: {
                    actions: assign({
                        formData: (context, event) => {
                            const { formData } = context;
                            const formDataString = JSON.parse(formData.REPORTS);
                            formDataString.splice(event.data, 1);
                            return { ...formData, REPORTS: JSON.stringify(formDataString) };
                        }
                    })
                }
            }
        },
        editing_slide: {},
        adding_slide: {
            on: {
                SUCCESS: {
                    target: 'idle', actions: assign({
                        formData: (context, event) => {
                            const { formData } = context;
                            const formDataString = JSON.parse(formData.REPORTS);
                            formDataString.push(event.data);
                            return { ...formData, REPORTS: JSON.stringify(formDataString) };
                        }
                    })
                }
            }
        },
        updating: {},
        deleting: {}
    },
    on: {
        DATA_CHANGED: { target: '.idle', actions: assign({ originalData: (context, event) => event.data, formData: (context, event) => event.data }) },
        CANCEL: '.idle',
        SUCCESS: '.idle'
    }
}, {
        actions: {
            typing: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: event.value }) }),
        }
    });

async function fetchFeedback(key, ID_TV) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/feedback/${ID_TV}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

function typeToString(type) {
    switch (type) {
        case 'history':
            return 'Historial'
        case 'department':
            return 'Departamento'
        case 'profession':
            return 'Profesión'
        case 'top':
            return 'Top'
        case 'batteries':
            return 'Lista de Baterías'
        default:
            return 'Desconocido'
    }
}

function periodToString(period) {
    switch (period) {
        case undefined:
        case null:
        case '':
            return ''
        case 'day':
            return 'Diario'
        case 'week':
            return 'Semanal'
        case 'month':
            return 'Mensual'
        case 'year':
            return 'Anual'
        default:
            return 'Desconocido'
    }
}




function FeedbackEditState(props) {
    const [id, setID] = useState(props.match.params.id)
    useEffect(() => {
        if (props.match.params.id)
            setID(props.match.params.id)
    }, [props.match.params.id])

    const { status, data, error, isFetching, refetch } = useQuery(['feedback', id], fetchFeedback, { retry: false })
    const [state, send] = useMachine(fetchMachine, { context: { originalData: data, formData: data } })
    const { formData = {}, originalData = {} } = state.context;

    useEffect(() => {
        if (data)
            send('DATA_CHANGED', { data: data })
    }, [data])

    return (

        <React.Fragment>
            <FeedbackContext.Provider value={{ state, send }}>
                {state.matches('deleting') &&
                    <FeedbackDeleteModal isOpen={true} onCancel={() => send('CANCEL')} onSuccess={() => { props.history.push({ pathname: `/feedback` }); queryCache.invalidateQueries('feedback'); }} />}
                {state.matches('updating') &&
                    <FeedbackUpdateModal isOpen={true} onCancel={() => send('CANCEL')} onSuccess={() => { queryCache.invalidateQueries('feedback'); }} />}
                {state.matches('adding_slide') &&
                    <AddSlideModal isOpen={true} onCancel={() => send('CANCEL')} onSuccess={(data) => send('SUCCESS', { data: data })} />}
                <Button color="link" className="d-xs-block d-lg-none mb-2 mt-0 pt-0" onClick={() => props.history.push({ pathname: `/feedback` })}>
                    {"< Ver Todas las Pantallas"}
                </Button>
                {(status === "loading" || status === "error") &&
                    <LoadingContainer
                        isLoading={status === "loading"}
                        hasError={error != null}
                        onRetry={() => refetch({ force: true })}>
                        <div style={{ minHeight: '470px' }}></div>
                    </LoadingContainer>}
                {status === "success" &&
                    <Card>
                        <CardHeader className="card-header-divider">
                            <CardTitle>
                                {`Editar Pantalla`}
                                <UncontrolledButtonDropdown className="float-right">
                                    <DropdownToggle caret color="link">
                                        <DotsVerticalIcon className="icon" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem color="danger" className="float-right" disabled={!state.matches('idle') || isFetching || error != null} onClick={() => send('DELETE')}>
                                            <TrashCanIcon size={15} className="icon" />Eliminar
                                        </DropdownItem >
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                {isFetching &&
                                    <div className="float-right mr-2">...</div>
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <BasicEditForm onValidSubmit={() => console.log("Submit")}>
                                <FormGroup>
                                    <Label>Nombre</Label>
                                    <Input required type="text" name="NAME" autoComplete="off" value={formData.NAME || ""} onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                                </FormGroup>
                                <FormGroup style={{ marginBottom: '0px' }}>
                                    <Label>Contenido</Label>
                                    {JSON.parse(formData.REPORTS || "[]").length > 0 &&
                                        <UncontrolledButtonDropdown className="float-right">
                                            <DropdownToggle caret color="success" disabled={!state.matches('idle') || isFetching || error != null}>
                                                Agregar
                                        </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => send('ADD_SLIDE')} style={{ display: 'flex', alignItems: 'center' }}><ChartBarIcon size={15} className="icon" /><div>Gráfica</div></DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>}
                                </FormGroup>
                                <div style={{ marginRight: '-20px', marginLeft: '-20px' }}>
                                    {JSON.parse(formData.REPORTS || "[]").length === 0 ?
                                        <CardBody>
                                            <div style={{ height: '150px', top: '0px', bottom: '0px', left: '0px', right: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <p>Agregar Contenido</p>
                                                <UncontrolledButtonDropdown className="float-right">
                                                    <DropdownToggle caret color="success" disabled={!state.matches('idle') || isFetching || error != null}>
                                                        Agregar
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => send('ADD_SLIDE')} style={{ display: 'flex', alignItems: 'center' }}><ChartBarIcon size={15} classname="icon" /><div className="ml-2">Gráfica</div></DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                        </CardBody> :
                                        <TableSearch
                                            keyField="ID_TV"
                                            hideSearch
                                            placeholder="Buscar Pantalla"
                                            data={JSON.parse(formData.REPORTS || "[]").map((element) => ({ ...element, ID_TV: `${element.type}${element.period}` }))}
                                            columns={[{
                                                text: 'Tipo',
                                                dataField: 'ID_TV',
                                                sort: true,
                                                formatter: (value, row) => {
                                                    return (
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <div className="ml-2 mr-3"><ChartBarIcon /></div>
                                                            <div>
                                                                <div className="mb-1" style={{ fontSize: '15px' }}>{typeToString(row.type)} - {periodToString(row.period)}</div>
                                                                <div className="font-weight-light">{!row.filters.ID_DEPARTMENT || row.filters.ID_DEPARTMENT.length === 0 ? "Todos los Departamentos" : `${row.filters.ID_DEPARTMENT.length} Departamento(s)`}</div>
                                                                <div className="font-weight-light">{!row.filters.ID_PROFESSION_TYPE || row.filters.ID_PROFESSION_TYPE.length === 0 ? "Todos los Tipos de Usuario" : `${row.filters.ID_PROFESSION_TYPE.length} Tipo(s) de Usuario`}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }, {
                                                text: '',
                                                dataField: '',
                                                isDummyField: true,
                                                headerStyle: () => {
                                                    return { width: "1px" };
                                                },
                                                formatter: (row, index) => {
                                                    return (
                                                        <UncontrolledButtonDropdown>
                                                            <DropdownToggle size="sm" color="link" caret>
                                                                <DotsVerticalIcon className="icon" />
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {/*<DropdownItem onClick={() => send('EDIT_SLIDE')}>Editar</DropdownItem>*/}
                                                                <DropdownItem onClick={() => send('DELETE_SLIDE', { data: index })}>Eliminar</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    )
                                                }
                                            }]}
                                            selectRow={{
                                                mode: 'radio',
                                                hideSelectColumn: true,
                                                clickToSelect: true,
                                                onSelect: (row, isSelect, rowIndex, e) => {
                                                    //props.history.push({ pathname: `/feedback/${row.ID_TV}/` })
                                                }
                                            }}
                                        />}
                                </div>
                                {JSON.stringify(originalData) !== JSON.stringify(formData) &&
                                    <Button disabled={isFetching} className="mt-3" block color="primary" onClick={() => send('UPDATE')}>Guardar Cambios</Button>
                                }
                            </BasicEditForm>
                        </CardBody>
                    </Card>}
            </FeedbackContext.Provider>
        </React.Fragment>
    )
}


export default FeedbackEditState
