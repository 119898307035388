import React from 'react'
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Row, Col, Container, Card,CardHeader } from 'reactstrap'
import tagimage from "../../assets/img/tagimage.png"

import { chunkArray, titleCase } from './utilities'

const BatteriesSlide = (props) => {
    return (
        <Container style={{ height: '100%' }}>
            <Card style={{ height: '100%' }}>
                <Row style={{ height: '100%' }}>
                    <Col xs={4} style={{ background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        {/*MENSAJE PARA MOSTRAR CON LAS PILAS*/}
                        <CardHeader>
                            <p className="text-center" style={{ fontSize: '1.35vw', color: 'white' }}>
                                <b>Tags Inactivos</b>
                            </p>
                        </CardHeader>
                        <img src={tagimage} alt="tag" style={{ maxWidth: '40%', objectFit: 'contain' }}/>
                        <p className="text-center font-weight-light" style={{maxWidth:'80%',color:'white',fontSize: '1.1vw',lineHeight:'normal'}}>Si su Aidy no tiene batería o presenta un problema, por favor acuda a Epidemiología Hospitalaria para resolverlo</p>
                    </Col>
                    {chunkArray(props.data, 10).map((column,idxCol) => {
                        const rest = Array.from(Array(10 - column.length))
                        return <Col key={idxCol} className="py-5 font-weight-light" xs={4} style={{ fontSize: '1.3vw', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            {column.map((user,index) => {
                                return <div key={`${idxCol} ${index}`} style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
                                    <div>{titleCase(`${user.LAST_NAME} ${user.NAME}`)}</div>
                                </div>
                            })}
                            {rest.map((element,index) => <div key={index} style={{ flex: 1 }}></div>)}
                        </Col>
                    })}
                </Row>
            </Card>
        </Container>
    )
}

export default BatteriesSlide