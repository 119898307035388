import React, { useRef } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { MagnifyIcon } from 'mdi-react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NoDataIndicator } from './NoDataIndicator';
import { InputGroup, InputGroupAddon } from 'reactstrap'
const { SearchBar } = Search;

function TableSearch(props) {
    const refProps = useRef(props);
    const generalProps=props
    return (
        <ToolkitProvider
            {...props}
            search>
            {props => {
                return (
                    <div style={{ cursor: 'pointer' }}>
                        {!refProps.current.hideSearch ?
                            <div className="mx-3 mt-3">
                                <InputGroup size="sm">
                                    <InputGroupAddon addonType="prepend">
                                        <span className="input-group-text" id="basic-addon1"><MagnifyIcon /></span>
                                    </InputGroupAddon>
                                    <SearchBar
                                        {...props.searchProps} className="form-control form-control-sm"
                                        placeholder={refProps.current.placeholder} style={{ flex: 'auto' }} />
                                </InputGroup>
                            </div> : null}
                        <div style={generalProps.height ? { maxHeight: generalProps.height, overflowX: 'auto', overflowY: 'scroll' } : null}>
                            <BootstrapTable
                                {...props.baseProps} bordered={false} hover
                                selectRow={refProps.current.selectRow}
                                noDataIndication={() => <NoDataIndicator />} />
                        </div>
                    </div>
                )
            }}
        </ToolkitProvider>
    )
}

export default TableSearch
