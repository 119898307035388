import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import DepartmentsList from './DepartmentsList'
import DepartmentsRTLS from './DepartmentsRTLS'
import { ClientSocket} from "use-socketio";

function Departments(props) {
    return (
        <Container fluid style={{ position: 'relative' }}>
            <Row>
                <Col xs={12} lg={6} xl={5}>
                    <Route exact path='/rtls/' key={'/rtls'}>
                        {({ match }) => {
                            return (
                                <CSSTransition
                                    in={match != null}
                                    timeout={300}
                                    classNames={!match ? `card-view` : `card-view-back`}
                                    unmountOnExit
                                >
                                    <div className={`background d-block d-lg-none`}>
                                        <DepartmentsList {...props} />
                                    </div>
                                </CSSTransition>
                            )
                        }}
                    </Route>
                    <Route exact path='/rtls/:id' key={'/feedback2'}>
                        {({ match }) => {
                            return (
                                <div className={`background d-none d-lg-block`}>
                                    <DepartmentsList {...props} />
                                </div>
                            )
                        }}
                    </Route>
                </Col>
                <Col xs={12} lg={6} xl={7}>
                    <Route exact path='/rtls/:id' key={`/rtls/:id`}>
                        {({ match }) => {
                            return (
                                <CSSTransition
                                    in={match != null}
                                    timeout={300}
                                    classNames={match ? "card-view" : "card-view-back"}
                                    unmountOnExit
                                >
                                    <div className="background">
                                        {/*<DepartmentsRTLS {...props} />*/}
                                        <ClientSocket url={`${process.env.REACT_APP_RTLS_ENDPOINT}`}><DepartmentsRTLS {...props}/></ClientSocket>
                                    </div>
                                </CSSTransition>
                            )
                        }}
                    </Route>
                    </Col>
            </Row>
        </Container>
    )
}


export default Departments
