import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Map from '../../../../components/Map';
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { Card, CardSubtitle, CardHeader, CardTitle, CardBody } from 'reactstrap'
import { Bubble } from 'react-chartjs-2'
import { useSize } from 'react-hook-size';
import { useSocket } from "use-socketio";
import axios from "axios";
import { useQuery } from 'react-query'

async function fetchData(key, id) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/departments/${id}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}


function DepartmentsRTLS(props) {
    let ref = React.useRef()
    let { width } = useSize(ref);
    const [data, setData] = useState([]);
    const { status, data: department = {}, error, refetch } = useQuery(['departments', props.match.params.id], fetchData, { refetchOnWindowFocus: false, retry: false })

    useEffect(() => {
        setData([])
    }, [props.match.params.id])

    useSocket(`RTLS/${props.match.params.id}`, tags => {
        if (process.env.NODE_ENV == 'development') {
            var particles = [];
            var dispensadas = [];
            tags.forEach(tag => {
                particles.push({
                    label: `${tag.tag_address}`,
                    //borderColor: tag.color ? tag.color : '#24c7a6',
                    borderColor: tag.dispensed ? 'rgba(0,0,255,1)' : '#24c7a6',
                    data: tag.particles.map(element => {
                        return { x: element.x, y: element.y, r: .15 * width / department.MAP_SIZE_X }
                    })
                })
                if (tag.dispensed)
                    for (var [_dispenser, _disp] of Object.entries(tag.dispensed)) {
                        console.log(_disp)
                        dispensadas.push({
                            label: `Label ${tag.tag_address},${_dispenser}`,
                            data: [{ x: tag.position.x, y: tag.position.y, r: 10 }, {
                                x: _disp.X,
                                y: _disp.Y,
                                r: 10
                            }],
                            fill: true,
                            pointRadius: 10,
                            backgroundColor: 'rgb(0, 0, 255)',
                            borderColor: 'rgba(0, 0, 255, 1)',
                        })
                    }
            })
            setData(particles.concat(dispensadas))
        }
        else {
            var varianzas = [];
            var posiciones = [];
            var dispensadas = [];
            tags.forEach(tag => {
                varianzas.push({
                    label: `${tag.tag_address}v`,
                    borderColor: '#24c7a6',
                    data: [{
                        x: tag.position.x,
                        y: tag.position.y,
                        variance: tag.position.variance,
                        r: tag.position.variance > 1.5 ? (tag.position.variance * width) / department.MAP_SIZE_X : (1.5 * width) / department.MAP_SIZE_X
                    }]
                })
                posiciones.push({
                    backgroundColor: tag.dispensed ? 'rgba(0,0,255,1)' : '#24c7a6',
                    label: tag.tag_address,
                    data: [{
                        x: tag.position.x,
                        y: tag.position.y,
                        variance: tag.position.variance,
                        r: .5 * width / department.MAP_SIZE_X
                    }]
                })

                if (tag.dispensed)
                    for (var [_dispenser, _disp] of Object.entries(tag.dispensed)) {
                        dispensadas.push({
                            label: `Label ${tag.tag_address},${_dispenser}`,
                            data: [{ x: tag.position.x, y: tag.position.y, r: 10 }, {
                                x: _disp.X,
                                y: _disp.Y,
                                r: 10
                            }],
                            fill: true,
                            pointRadius: 10,
                            backgroundColor: 'rgb(0, 0, 255)',
                            borderColor: 'rgba(0, 0, 255, 1)',
                        })
                    }
            })
            setData(varianzas.concat(posiciones).concat(dispensadas));
        }

    });

    return (
        <React.Fragment>
            <div className="d-xs-block d-lg-none mb-2"><Link to="/rtls">{"< Ver Todos los Departamentos"}</Link></div>
            <Card>
                <CardHeader>
                    <CardTitle>
                        RTLS
                    </CardTitle>
                    <CardSubtitle>{department.NAME || ""}</CardSubtitle>
                </CardHeader>
                <CardBody>
                    <LoadingContainer
                        isLoading={status === "loading"}
                        hasError={error !== null}
                        onRetry={() => refetch()}>
                        <Map sizex={department.MAP_SIZE_X} sizey={department.MAP_SIZE_Y} mapImageURL={department.MAP_IMAGE_URL}>
                            <div ref={ref} style={{
                                width: '100%',
                                height: '100%',
                            }}>
                                <Bubble
                                    data={{ datasets: data }}
                                    options={{
                                        animation: false,
                                        events: [],
                                        legend: {
                                            display: false
                                        },
                                        scales: {
                                            xAxes: [{
                                                type: 'linear',
                                                ticks: {
                                                    display: false,
                                                    stepSize: 1.0,
                                                    beginAtZero: true,
                                                    max: department.MAP_SIZE_X,
                                                    min: 0
                                                },
                                                gridLines: {
                                                    color: 'rgba(100,100,100,.2)',
                                                    tickMarkLength: 0
                                                }
                                            }],
                                            yAxes: [{
                                                type: 'linear',
                                                ticks: {
                                                    display: false,
                                                    stepSize: 1.0,
                                                    beginAtZero: true,
                                                    max: department.MAP_SIZE_Y,
                                                    min: 0
                                                },
                                                gridLines: {
                                                    color: 'rgba(100,100,100,.2)',
                                                    tickMarkLength: 0
                                                }
                                            }]
                                        },
                                        maintainAspectRatio: false
                                    }} />
                            </div>
                        </Map>
                    </LoadingContainer>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default DepartmentsRTLS
