import React, { useState, useEffect } from 'react'
import "../assets/css/app.css"
import "../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css"
import logo from "../assets/img/aidy_white.png"
import { Bar, HorizontalBar } from "react-chartjs-2"
import { Row, Col, Container, CardSubtitle, Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { LoadingContainer } from '../components/LoadingContainer'
import useRequestReducer from '../hooks/useRequestReducer';
import moment from 'moment-timezone'
import covid1 from "../assets/img/covid1.jpeg"
import covid2 from "../assets/img/covid2.jpeg"
import covid3 from "../assets/img/covid3.jpeg"
moment.locale('es');
moment.tz.setDefault("America/Monterrey");


function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}



function ChartConsumer(props) {
    switch (props.view) {
        case 'bar':
            return (
                <Bar
                    data={props.data}
                    options={{
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                stacked: true,
                                gridLines: {
                                    tickMarkLength: 5
                                }
                            }],
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    display: false,
                                    beginAtZero: true,
                                    maxTicksLimit: 5,
                                    suggestedMin: 0,
                                    suggestedMax: 100
                                }
                            }]
                        }
                    }}
                />)
        case 'horizontal_bar':
            return (
                <HorizontalBar
                    data={props.data}
                    options={{
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                stacked: true,
                                ticks: {
                                    display: false,
                                    beginAtZero: true,
                                    maxTicksLimit: 5,
                                    suggestedMin: 0,
                                    suggestedMax: 100
                                }
                            }],
                            yAxes: [{
                                stacked: true,
                                gridLines: {
                                    tickMarkLength: 5
                                }
                            }]
                        }
                    }}
                />)
        case 'top':
            return (
                <Container>
                    <Row>
                        <Col sm={5}>
                            <div style={{ padding: '40px' }}>
                                <img src="https://drive.google.com/uc?id=1MYswYwNulrHHLExioLb_Fn5l9rONKcSW" alt="" style={{ maxWidth: '100%' }}></img>
                            </div>
                        </Col>
                        <Col sm={7}>
                            <p style={{ marginTop: '5px', fontSize: '11px', lineHeight: '110%' }}>
                                El HIMFG agradece el esfuerzo del personal que ha tenido un desempeño destacado en el programa <b>Vamos Por el Cien</b> en este departamento.
                            </p>
                            {props.data.slice(0, 5).map((element, index) => {
                                return <div key={index}>
                                    <div style={{
                                        marginRight: '5px',
                                        float: 'left',
                                        borderRadius: '50%',
                                        width: '25px',
                                        height: '25px',
                                        padding: '3px',
                                        background: '#fff',
                                        border: '2px solid #666',
                                        color: '#666',
                                        textAlign: 'center'
                                    }}>{index + 1}</div>
                                    <h5 style={{ height: '25px', paddingTop: '5px', marginTop: '5px' }}>{titleCase(element.NAME)}</h5>
                                </div>
                            })}
                            <p style={{ marginTop: '10px', fontSize: '11px', textAlign: 'center' }}>
                                Todos juntos, estamos cada vez mas cerca de la meta
                            </p>
                            <p style={{ marginTop: '7px', fontSize: '13px', textAlign: 'center' }}>
                                <b>¡MUCHAS FELICIDADES!</b>
                            </p>
                        </Col>
                    </Row>
                </Container>
            )
        default:
            return (<div>Tipo de Grafica Desconocida</div>)
    }
}


function CarouselScreen(props) {
    const [data, setData] = useState([])
    const [getData, dispatchGetData] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/tv/${props.match.params.idDepartment}`
    })

    useEffect(() => {
        if (getData.response) {
            setData(getData.response.data)
        }
    }, [getData.response])

    useEffect(() => {
        let timeout = setInterval(() => {
            if (!getData.loading) {
                dispatchGetData({
                    type: 'SEND_REQUEST',
                    request: {
                        method: 'get',
                        url: `${process.env.REACT_APP_API_ENDPOINT}/api/tv/${props.match.params.idDepartment}`
                    }
                })
            }
        }, 60000)
        return () => clearInterval(timeout)
    }, [getData.loading])

    return (
        <React.Fragment>
            <div className="container-fluid pb-5" style={{ background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', margin: 'auto', height: '85px', width: '100%', paddingTop: '10px' }}>
                <img className="logo-img" src="https://drive.google.com/uc?id=1CIgCCe54w1HPwQAg2wreaLfkg4UVdyjs" alt="logo" width="50" height="#{conf.logoHeight}" style={{ float: 'left', display: 'block', marginTop: '0px', marginLeft: '5', marginRight: 'auto' }} />
                <img className="logo-img" src={logo} alt="logo" width="80" height="#{conf.logoHeight}" style={{ float: 'right', display: 'block', marginTop: '17px', marginLeft: 'auto', marginRight: '5' }} />
            </div>
            <Container fluid>
                <Row>
                    {/*<Col className="text-center" xs={3} style={{ backgroundColor: 'rgba(249,243,237,1)' }}>
                        <img className="mt-2" style={{ maxWidth: '80%' }} src="http://calidad.salud.gob.mx/site/descargas/img/2020-Salud_logo-HZ.gif" />
                        <h5 style={{ color: 'rgba(105,28,50,1)' }}>Caso sospechoso:</h5>
                        <p style={{ fontSize: '.9rem', color: 'rgba(100,100,100,1)' }}><b>Persona de cualquier edad que presente enfermedad respiratoria, aguda, leve o grave, y que cuente con alguno de los siguientes antecedentes hasta 14 días antes del inicio de síntomas:<br />
                            Haber estado en contacto con un caso confirmado o bajo investigación de COVID-19<br />
                            Viaje o estancia en países con transmisión local comunitaria* de CONAVID-19<br />
                            *China, Hong Kong, Corea del Sur, Japón, Italia, Irán y Singapur</b></p>
                    </Col>*/}
                    <Col xs={12}>
                        {data.length > 0 ?
                            <CarouselProvider
                                naturalSlideWidth={100}
                                naturalSlideHeight={100}
                                totalSlides={data.filter(element=>element.view!=='batteries').length + 3}
                                isPlaying={true}
                                interval={15000}>
                                <Slider>
                                    {[
                                        <Slide>
                                            <Container className="mt-3">
                                                <Card className="mx-2">
                                                    <CardBody>
                                                        <img alt="covid1" src={covid1} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                                                    </CardBody>
                                                </Card>
                                            </Container>
                                        </Slide>,
                                        <Slide>
                                            <Container className="mt-3">
                                                <Card className="mx-2">
                                                    <CardBody>
                                                        <img alt="covid2" src={covid2} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                                                    </CardBody>
                                                </Card>
                                            </Container>
                                        </Slide>,
                                        <Slide>
                                            <Container className="mt-3">
                                                <Card className="mx-2">
                                                    <CardBody>
                                                        <img alt="covid3" src={covid3} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                                                    </CardBody>
                                                </Card>
                                            </Container>
                                        </Slide>,
                                        ...data.map((element, index) => {                            
                                            if(element.view!=='batteries')
                                                return (
                                                <Slide index={index} key={index}>
                                                    <Container className="mt-3">
                                                        <Card className="mx-2">
                                                            {element.title ?
                                                                <CardHeader>
                                                                    <CardTitle className="text-center">
                                                                        <b>{element.title}</b>
                                                                    </CardTitle>
                                                                </CardHeader> : null}
                                                            <CardBody>
                                                                {element.subtitle ? element.subtitle.map((sub, index) => {
                                                                    return <CardSubtitle className="text-center text-muted" key={index}>{sub}</CardSubtitle>
                                                                }) : null}
                                                                <ChartConsumer {...element} />
                                                            </CardBody>
                                                        </Card>
                                                    </Container>
                                                </Slide>
                                            )
                                        })]}
                                </Slider>
                            </CarouselProvider>
                            : <Container className="mt-3">
                                <LoadingContainer isLoading={true}>
                                    <Card className="mx-2" style={{ height: '600px' }}>
                                        <CardHeader>
                                            <CardTitle className="text-center">
                                                <b>Loading...</b>
                                            </CardTitle>
                                        </CardHeader>
                                    </Card>
                                </LoadingContainer>
                            </Container>}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default CarouselScreen
