import { useEffect, useReducer } from 'react'
import axios from "axios";

function reducer(state, action) {
    switch (action.type) {
        case "NOT_LOAD_IN_CHARGE":
            return {
                ...state,
                loading: false
            }
        case "SEND_REQUEST":
            return {
                ...state,
                request: { ...state.request, ...action.request },
                response: null,
                error: null,
                loading: true,
                send_request: true
            }
        case "HAS_LOADED":
            return {
                ...state,
                response: action.response,
                error: null,
                loading: false,
                send_request: false
            }
        case "HAS_ERROR":
            return {
                ...state,
                response: null,
                error: action.error,
                loading: false,
                send_request: false
            }
        default:
            return state
    }
}

function useRequestReducer(initialState) {
    const [state, dispatch] = useReducer(reducer, {
        request: { ...initialState },
        response: null,
        error: null,
        loading: true,
        send_request: false
    });

    useEffect(() => {
        switch (state.request.method) {
            case 'get':
            case 'GET':
                if (!state.request.dontSend) {
                    dispatch({ type: 'SEND_REQUEST' });
                }
                else {
                    dispatch({ type: 'NOT_LOAD_IN_CHARGE' });
                }
                break;
            default:
                dispatch({ type: 'NOT_LOAD_IN_CHARGE' });
        }
    }, [])

    useEffect(() => {
        if (state.send_request) {
            axios({
                ...state.request,
                headers: { "X-Access-Token": localStorage.getItem('auth_token') }
            })
                .then((res) => {
                    dispatch({ type: 'HAS_LOADED', response: res })
                })
                .catch((err) => {
                    dispatch({ type: 'HAS_ERROR', error: err})
                })
        }
    }, [state.send_request])

    return [state, dispatch];
}

export default useRequestReducer
