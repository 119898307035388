
import React, { useState } from 'react'
import "../assets/css/app.css"
import logo from "../assets/img/aidy_white.png"
import { BasicEditForm } from '../components/BasicEditForm';
import { LoadingContainer } from '../components/LoadingContainer';
import { Card, CardHeader, CardBody, FormGroup, Input, Button, FormFeedback, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import background from "../assets/img/login-background.jpg"
import { useAuth } from '../AuthContext'
import { CloseCircleOutlineIcon } from 'mdi-react'

function Login(props) {
    const auth = useAuth();
    const [loginData, setLoginData] = useState({ user: ``, pw: `` })

    const handleInputChange = event => {
        const { name, value } = event.target;
        setLoginData({
            ...loginData,
            [name]: value
        })
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflow: 'auto',
            background: 'black'
        }}>
            <img src={background} alt="" style={{ height: '100vh', width: '100%', objectFit: 'cover', opacity: 0.5 }} />
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: "0", left: "0" }}>
                <div className="splash-container">
                    <Card>
                        <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 0%, #020087 100%)', minHeight: '70px' }}>
                            <CardHeader>
                                <img className="logo-img" src={logo} alt="logo" width="225" height="#{conf.logoHeight}"></img>
                                <span className="splash-description" style={{ color: 'white' }}>Iniciar Sesión</span>
                            </CardHeader>
                        </div>
                        {auth.postLogin.error ?
                            <div className="alert alert-danger alert-simple alert-dismissible" role="alert">
                                <button className="close" type="button" data-dismiss="alert" aria-label="Close"><span className="mdi mdi-close" aria-hidden="true"></span></button>
                                <div className="icon"> <CloseCircleOutlineIcon /></div>
                                <div className="message"><strong>{auth.postLogin.error.response ? auth.postLogin.error.response.data.message : "Error de Servidor"}</strong></div>
                            </div>
                            : null}
                        <LoadingContainer isLoading={auth.postLogin.loading}>
                            <CardBody>

                                <BasicEditForm
                                    onValidSubmit={() => { auth.login(loginData) }}>
                                    <FormGroup>
                                        <Input
                                            name="user"
                                            type="email"
                                            className="text-center"
                                            value={loginData.user}
                                            onChange={handleInputChange}
                                            placeholder="Email"
                                            autoComplete="off"
                                            required
                                        />
                                        <FormFeedback className="text-center">Ingrese un Email válido</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            name="pw"
                                            type="password"
                                            className="text-center"
                                            value={loginData.pw}
                                            onChange={handleInputChange}
                                            placeholder="Password"
                                            autoComplete="off"
                                            required
                                        />
                                        <FormFeedback className="text-center">Ingrese la contraseña</FormFeedback>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col xs={6}>
                                        </Col>
                                        <Col xs={6} className="text-right">
                                            <Link to='/forgot-password'>Recuperar Contraseña</Link>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Button color="primary" size="xl" block>Iniciar Sesión</Button>
                                    </FormGroup>
                                </BasicEditForm>
                            </CardBody>
                        </LoadingContainer>
                    </Card>
                    <div className="splash-footer">&copy; 2018 Aidy Technologies</div>
                </div>
            </div>
        </div>
    )
}

export default Login