import React, { useState, useEffect } from 'react'
import TableSearch from '../../../../components/TableSearch'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { Button, Card, CardHeader, CardTitle, Container } from 'reactstrap'
import useRequestReducer from '../../../../hooks/useRequestReducer';
import { Can } from '../../../../AbilityContext'

const columns = [{
    dataField: 'BLE_ADDRESS',
    text: 'BLE Address',
    sort: true
}, {
    dataField: 'HARDWARE',
    text: 'Hardware',
    sort: true
}, {
    dataField: 'FIRMWARE',
    text: 'Firmware',
    sort: true
}];

function TagsList(props) {
    const [listData, setListData] = useState([]);
    const [tags] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/tags/`
    });

    useEffect(() => {
        if (tags.response)
            setListData(tags.response.data);
    }, [tags.response])

    return (
        <Container fluid>
            <Card>
                <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', minHeight: '70px' }}>
                    <CardHeader className="card-header-divider">
                        <CardTitle>Tags
                            <Can I="create" a="TAGS">
                                <Button hidden={tags.loading} color="success" className="float-right" onClick={() => props.history.push({ pathname: `/tags/new` })}>Agregar</Button>
                            </Can>
                        </CardTitle>
                    </CardHeader>
                </div>
                <LoadingContainer isLoading={tags.loading}>
                    <TableSearch
                        keyField="ID_GATEWAY"
                        placeholder="Buscar Tag"
                        data={listData}
                        columns={columns}
                        selectRow={{
                            mode: 'radio',
                            hideSelectColumn: true,
                            clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => {
                                props.history.push({ pathname: `/tags/${row.ID_TAG}` })
                            }
                        }}
                    />
                </LoadingContainer>
            </Card>
        </Container>
    )
}

export default TagsList
