import React, { useState, useEffect } from 'react'
import logo from "../../assets/img/aidy_white.png"
import himfg_logo from "../../assets/img/himfg_logo.png"
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Badge } from 'reactstrap'
/*import covid1 from "../../assets/img/covid1.jpeg"
import covid2 from "../../assets/img/covid2.jpeg"
import covid3 from "../../assets/img/covid3.jpeg"*/
import covidbocas from "../../assets/img/covidbocas.png"
import recordatorio1 from "../../assets/img/recordatorio1.png"
import recordatorio2 from "../../assets/img/recordatorio2.png"
import { useQuery } from 'react-query'
import axios from "axios";
import { LoadingContainer } from '../../components/LoadingContainer';
import BarSlide from './BarSlide'
import HorizontalBarSlide from './HorizontalBarSlide'
import TopSlide from './TopSlide'
import BatteriesSlide from './BatteriesSlide'
import { chunkArray } from './utilities'
import Heatmap from './Heatmap';
import mqtt from 'mqtt';
import { v4 as uuidv4 } from 'uuid';

async function fetchTV(key, id) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/tv/${id}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

function ChartConsumer(props) {
    switch (props.view) {
        case 'bar':
            return <BarSlide {...props} />
        case 'horizontal_bar':
            return <HorizontalBarSlide {...props} />
        case 'top':
            return <TopSlide {...props} />
        case 'batteries':
            return <BatteriesSlide {...props} />
        case 'heatmap':
            return <Heatmap {...props} />
        default:
            return (<div>Tipo de Grafica Desconocida</div>)
    }
}

function Carousel(props) {
    const { status, data = [], error, isFetching } = useQuery(['tv', props.match.params.idDepartment], fetchTV, { retry: false, refetchOnWindowFocus: false, refetchOnReconnect: true, refetchInterval: 30000 })

    const [slides, setSlides] = useState([])

    //MQTT RELATED 
    /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    useEffect(() => {
        const session = uuidv4()
        const client = mqtt.connect("wss://broker.aidytech.com:8083", {
            keepalive: 60,
            will: {
                topic: `himfg/screens/${props.match.params.idDepartment}/${session}`,
                payload: "offline",
                qos: 0,
                retain: true
            }
        });
        client.options.clientId = session
        client.on('connect', () => client.publish(`himfg/screens/${props.match.params.idDepartment}/${session}`, "online", { retain: true }))
    }, []);
    /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

    useEffect(() => {
        if (data) {
            let newSlides = []
            for (var i = 0; i < data.length; i++) {
                if (data[i].view === "batteries") {
                    const divided = chunkArray(data[i].data, 20)
                    for (var j = 0; j < divided.length; j++) {
                        newSlides.push({ ...data[i], data: divided[j] })
                    }
                } else {
                    newSlides.push(data[i])
                }
            }
            setSlides(newSlides)
        }
    }, [isFetching])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div style={{ height: '85px', alignItems: 'center', display: 'flex', justifyContent: 'space-between', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)' }}>
                <img className="logo-img ml-2" src={himfg_logo} alt="logo" width="50" height="#{conf.logoHeight}" />
                <div style={{ display: 'flex' }}>
                    {isFetching && status !== "loading" && <Badge style={{ height: 'fit-content', alignSelf: 'center', backgroundColor: 'white' }} color="default">Actualizando...</Badge>}
                    {!isFetching && error && <Badge style={{ height: 'fit-content', alignSelf: 'center' }} color="warning">Reconectando...</Badge>}
                    <img className="logo-img mx-2" src={logo} alt="logo" width="80" height="#{conf.logoHeight}" style={{ objectFit: 'contain' }} />
                </div>
            </div>
            <div style={{ backgroundColor: 'lightgrey', flex: 1, overflow: 'hidden', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                {status === "loading" &&
                    <LoadingContainer isLoading={true}>
                        <div style={{ height: '100%' }}></div>
                    </LoadingContainer>}
                {slides.length > 0 &&
                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={45}
                        totalSlides={slides.length + 2}
                        isPlaying={true}
                        interval={10000}>
                        <Slider>
                            {[
                                ...slides.map((element, index) => {
                                    return (
                                        <Slide index={index} key={index}>
                                            <ChartConsumer {...element} />
                                        </Slide>
                                    )
                                }),
                                <Slide key={slides.length}>
                                    <img alt="recordatorio1" src={recordatorio1} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                </Slide>,
                                <Slide key={slides.length}>
                                    <img alt="recordatorio2" src={recordatorio2} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                </Slide>
                            ]}
                        </Slider>
                    </CarouselProvider>}
            </div>
        </div>
    )
}

export default Carousel