import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './DashboardPages/Home'
import Users from './DashboardPages/Users/Users';
import Resources from './DashboardPages/Resources/Resources';
import Roles from './DashboardPages/Roles/Roles';

import Tags from './DashboardPages/Tags/Tags';
import Gateways from './DashboardPages/Gateways/Gateways';
import Dispensers from './DashboardPages/Dispensers/Dispensers';
import HospitalsEdit from './DashboardPages/Hospitals/HospitalsEdit';
import AccountSettings from './DashboardPages/AccountSettings'
import Departments from './DashboardPages/Departments/Departments';
import RTLS from './DashboardPages/RTLS/RTLS';
import Stats from './DashboardPages/Stats/Stats';
import Events from './DashboardPages/Events/Events';
import Feedback from './DashboardPages/Feedback/Feedback';
import ScreensList from './DashboardPages/ScreensConfiguration/ScreensList';
const Main = (props) => {
  return (
    <main>
      <Switch>
        {/*Add all Routes relative to '/' */}
        {/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
        <Route exact path='/events/:id' component={Events} />
        <Route exact path='/events' component={Events} />
        

        <Route exact path='/departments/:id' component={Departments} />
        <Route exact path='/departments' component={Departments} />

        <Route exact path='/rtls/:id' component={RTLS} />
        <Route exact path='/rtls' component={RTLS} />

        <Route path='/tags' component={Tags} />
        <Route path='/gateways' component={Gateways} />
        <Route path='/dispensers' component={Dispensers} />

        <Route exact path='/users/:id' component={Users} />
        <Route exact  path='/users' component={Users} />
        <Route path='/resources' component={Resources} />
        <Route path='/roles' component={Roles} />

        <Route path='/account-settings' component={AccountSettings} />
        <Route exact path='/hospitals/:idHospital' component={HospitalsEdit} />
        <Route exact path='/stats' component={Stats}/>

        <Route path='/feedback/:id' component={Feedback}/>
        <Route path='/feedback' component={Feedback}/>

        <Route exact path='/screens/:code' component={ScreensList}/>
        {/*...Here*/}
        {/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
        {/*<Route path='/index' component={Home} />
        <Route exact path='/' component={Home} />*/}
        <Route path='/index'><Redirect to={'/stats'}/></Route>
        <Route path='/'><Redirect to={'/stats'}/></Route>
        <Route component={()=><div>Not Found</div>} />
      </Switch>
    </main>
  )
}



export default Main
