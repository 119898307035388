import React, { useState, useEffect } from 'react'
import { BasicEditForm } from '../../../../components/BasicEditForm'
import { LoadingContainer } from '../../../../components/LoadingContainer';
import useRequestReducer from '../../../../hooks/useRequestReducer';
import { Container, Button, Card, CardTitle, CardHeader, CardBody, Col, Label, FormGroup, FormFeedback, Input } from 'reactstrap'
import CreatableSelect from 'react-select/lib/Creatable';

function ResourcesNew(props) {
    const [newValue, setNewValue] = useState("");
    const [formData, setFormData] = useState({ PERMISSIONS: [] });
    const [createData, dispatchCreate] = useRequestReducer({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/resources/`
    });

    useEffect(() => {
        if (createData.response) {
            if (createData.response.status === 200) {
                props.history.push({ pathname: `/resources/${createData.response.data.insertId}` })
            }
        }
    }, [createData.response])

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        })
    };

    //Select Related
    const createOption = label => ({
        label,
        value: label,
    });

    const handleSelectorInputChange = inputValue => {
        setNewValue(inputValue)
    };

    const handleKeyDown = (event) => {
        if (!newValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setFormData({ ...formData, PERMISSIONS: [...formData.PERMISSIONS, createOption(newValue)] });
                setNewValue("");
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    return (
        <Container fluid>
            <Card>
                <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', minHeight: '70px' }}>
                    <CardHeader className="card-header-divider" >
                        <CardTitle>
                            Nuevo Recurso
                    </CardTitle>
                    </CardHeader>
                </div>
                <CardBody>
                    <LoadingContainer isLoading={createData.loading}>
                        <BasicEditForm
                            onValidSubmit={() => dispatchCreate({ type: 'SEND_REQUEST', request: { data: { ...formData, PERMISSIONS: JSON.stringify(formData.PERMISSIONS.map(element => element.value)) } } })} >
                            <FormGroup row>
                                <Label sm={3} className="text-sm-right">Nombre</Label>
                                <Col sm={8} lg={6}><Input type="text" name="NAME" value={formData.NAME || ""} onChange={handleInputChange} required />
                                    <FormFeedback>Es necesario proporcionar un Nombre al Resource</FormFeedback></Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} className="text-sm-right">Permisos</Label>
                                <Col sm={8} lg={6}>
                                    <CreatableSelect
                                        placeholder="Escriba las opciones y presione Enter"
                                        components={{
                                            DropdownIndicator: null
                                        }}
                                        isClearable
                                        isMulti
                                        menuIsOpen={false}
                                        onChange={(value) => setFormData({ ...formData, PERMISSIONS: value })}
                                        onInputChange={handleSelectorInputChange}
                                        onKeyDown={handleKeyDown}
                                        value={formData.PERMISSIONS}
                                    />
                                    <FormFeedback>Indique los Permisos</FormFeedback></Col>
                            </FormGroup>
                            <FormGroup>
                                <Button block color="success">Nuevo Recurso</Button>
                            </FormGroup>
                        </BasicEditForm>
                    </LoadingContainer>
                </CardBody>
            </Card>
        </Container>
    )
}

export default ResourcesNew
