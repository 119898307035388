
import React from 'react'
import { Link } from 'react-router-dom'
import "../../assets/css/app.css"
import { TelevisionIcon, AccountKeyIcon, HomeIcon, HomeMapMarkerIcon, ChipIcon, ChartBarStackedIcon, CalendarCheckIcon, AccountIcon } from 'mdi-react';
import { SubMenu } from "../../components/SubMenu"
import { Can, useAbilities } from '../../AbilityContext'

function Menu(props) {
    const abilities = useAbilities()

    return (
        <div>
            <ul className="sidebar-elements">
                {/*Add Sidebar Links on this block*/}
                {/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
                <li className="divider p-2" />
                {abilities.can("read", "REPORTS") ?
                    <li><Link to='/stats'><ChartBarStackedIcon className="icon" />Reportes</Link></li> : null}
                {/*abilities.can("read", "EVENTS") ?
                    <li><Link to='/events'><CalendarCheckIcon className="icon" />Eventos</Link></li> : null*/}
                {abilities.can("read", "USERS") ?
                    <li><Link to='/users'><AccountIcon className="icon" />Usuarios</Link></li>: null}
                {abilities.can("read", "DEPARTMENTS") ?
                    <li><Link to='/departments'><HomeMapMarkerIcon className="icon" />Departamentos</Link></li> : null}
                {abilities.can("read", "FEEDBACK") ? <li><Link to='/feedback'><TelevisionIcon className="icon" />Pantallas</Link></li> : null}
                {(abilities.can("read", "DISPENSERS") || abilities.can("read", "GATEWAYS") || abilities.can("read", "TAGS")) ||
                    (abilities.can("read", "RESOURCES") || abilities.can("read", "ROLES"))  ?
                    <li className="divider">Configuración General</li>
                    : null}
                {(abilities.can("read", "DISPENSERS") || abilities.can("read", "GATEWAYS") || abilities.can("read", "TAGS")) ?
                    <SubMenu title="Hardware" icon={<ChipIcon className="icon" />}>
                        <Can I="read" a="DISPENSERS"><li><Link to='/dispensers'>Dispensers</Link></li></Can>
                        <Can I="read" a="GATEWAYS"><li><Link to='/gateways'>Gateways</Link></li></Can>
                        <Can I="read" a="TAGS"><li><Link to='/tags'>Tags</Link></li></Can>
                    </SubMenu> : null}
                {(abilities.can("read", "RESOURCES") || abilities.can("read", "ROLES")) ?
                    <SubMenu title="Control de Acceso" icon={<AccountKeyIcon className="icon" />}>
                        <Can I="read" a="ROLES"><li><Link to='/roles'>Roles</Link></li></Can>
                        <Can I="read" a="RESOURCES"><li><Link to='/resources'>Recursos</Link></li></Can>
                    </SubMenu> : null}
                <li className="divider p-2" />
                {/*...Here*/}
                {/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
            </ul>
        </div>
    )
}

export default Menu

