import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ResourcesList from './ResourcesList';
import ResourcesEdit from './ResourcesEdit';
import ResourcesNew from './ResourcesNew';
import { Can } from '../../../../AbilityContext'

function Resources() {
    return (
        <Can I="read" a="RESOURCES">
            <Switch>
                <Route exact path='/resources' component={ResourcesList} />
                <Route exact path='/resources/new' component={ResourcesNew} />
                <Route path='/resources/:id' component={ResourcesEdit} />
            </Switch>
        </Can>
    )
}

export default Resources
