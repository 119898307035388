import React from 'react'
import { HorizontalBar } from "react-chartjs-2"
import 'pure-react-carousel/dist/react-carousel.es.css';
import {  Container, CardSubtitle, Card, CardHeader, CardTitle, CardBody } from 'reactstrap'

const HorizontalBarSlide = (props) => {
    return (
        <Container style={{ height: '100%' }}>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {props.title ?
                    <CardHeader>
                        <CardTitle className="text-center">
                            <b>{props.title}</b>
                        </CardTitle>
                    </CardHeader> : null}
                {props.subtitle ? props.subtitle.map((sub, index) => {
                    return <CardSubtitle className="text-center text-muted" key={index}>{sub}</CardSubtitle>
                }) : null}
                <CardBody style={{ height: '100%', flex: 1, width: '80%', alignSelf: 'center' }}>
                    <HorizontalBar
                        data={props.data}
                        options={{
                            maintainAspectRatio: false,
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    ticks: {
                                        display: false,
                                        beginAtZero: true,
                                        maxTicksLimit: 5,
                                        suggestedMin: 0,
                                        suggestedMax: 100
                                    }
                                }],
                                yAxes: [{
                                    stacked: true,
                                    gridLines: {
                                        tickMarkLength: 5
                                    }
                                }]
                            }
                        }}
                    />
                </CardBody>
            </Card>
        </Container>
    )
}

export default HorizontalBarSlide