import React from 'react'
import { Switch, Route } from 'react-router-dom'
import RolesList from './RolesList';
import RolesEdit from './RolesEdit';
import RolesNew from './RolesNew';
import { Can } from '../../../../AbilityContext'

function Roles() {
    return (
        <Can I="read" a="ROLES">
            <Switch>
                <Route exact path='/roles' component={RolesList} />
                <Route exact path='/roles/new' component={RolesNew} />
                <Route path='/roles/:id' component={RolesEdit} />
            </Switch>
        </Can>
    )
}

export default Roles
