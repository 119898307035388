import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col, Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Input, InputGroup, InputGroupAddon, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import { useQuery, queryCache } from 'react-query'
import axios from "axios";
import { LoadingContainer } from '../../../../components/LoadingContainer';
import { BasicEditForm } from '../../../../components/BasicEditForm';
import { Battery40Icon, InformationIcon, TagIcon,  TrashCanIcon, DotsVerticalIcon,BellSleepIcon } from 'mdi-react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import UsersContext from './UsersContext'
import ConfirmUsersUpdateModal from './ConfirmUsersUpdateModal';
import ConfirmUsersDeleteModal from './ConfirmUsersDeleteModal';
import ConfirmAttachTagModal from './ConfirmAttachTagModal';
import ConfirmDeattachTagModal from './ConfirmDeattachTagModal';
import { Can } from '../../../../AbilityContext'
import ConfirmHideAlertsModal from './ConfirmHideAlertsModal';
import ConfirmShowAlertsModal from './ConfirmShowAlertsModal';
var moment = require('moment-timezone');
require('moment/locale/es');
moment.locale('es');

async function fetchProfessions(key) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/profession_types`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

async function fetchRoles(key) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/roles`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

async function fetchData(key, ID_USER) {
    const { data } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/users/${ID_USER}`,
        headers: { "X-Access-Token": localStorage.getItem('auth_token') }
    })
    return data;
}

const fetchMachine = Machine({
    id: 'fetch',
    initial: 'idle',
    states: {
        idle: {
            on: {
                TYPING: { actions: 'typing' },
                TYPING_TAG: { actions: 'typing_tag' },
                SELECT_CHANGE: { actions: 'select_changed' },
                CHECKBOX_CHANGE: { actions: 'checkbox_changed' },
                UPDATE: 'updating',
                DELETE: 'deleting',
                HIDE_ALERTS: 'hiding_alerts',
                SHOW_ALERTS: 'showing_alerts',
                ATTACH_TAG: 'attaching_tag',
                DEATTACH_TAG: 'deattaching_tag'
            }
        },
        updating: {},
        deleting: {},
        hiding_alerts: {},
        showing_alerts: {},
        attaching_tag: {},
        deattaching_tag: {}
    },
    on: {
        DATA_CHANGED: {
            target: '.idle',
            actions: assign({
                originalData: (context, event) => event.data,
                formData: (context, event) => event.data,
                tagData: (context, event) => event.data.TAG,
                tagFormData: (context, event) => event.data.TAG
            })
        },
        CANCEL: '.idle',
        SUCCESS: '.idle'
    }
}, {
        actions: {
            typing: assign({ formData: (context, event) => ({ ...context.formData, [event.name]: event.value }) }),
            typing_tag: assign({ tagFormData: (context, event) => ({ ...context.tagFormData, [event.name]: event.value }) }),
            select_changed: assign({
                formData: (context, event) => {
                    const value = parseInt(event.value, 10)
                    return { ...context.formData, [event.name]: value }
                }
            }),
            //TODO: Fix when data reloads on background, this changes
            checkbox_changed: assign({
                formData: (context, event) => {
                    if (event.target.checked) {
                        let original = context.formData[event.target.name];
                        original.push(parseInt(event.target.id, 10));
                        return { ...context.formData, [event.target.name]: original }
                    }
                    else {
                        var index = context.formData[event.target.name].indexOf(parseInt(event.target.id, 10));
                        if (index > -1) {
                            let original = context.formData[event.target.name];
                            original.splice(index, 1);
                            return { ...context.formData, [event.target.name]: original }
                        }
                    }
                }
            })
        }
    });


function UsersEdit(props) {
    const [id, setID] = useState(props.match.params.id)
    useEffect(() => {
        if (props.match.params.id)
            setID(props.match.params.id)
    }, [props.match.params.id])

    const { status, data, error, isFetching, refetch } = useQuery(['users', id], fetchData, { retry: false })
    const { status: professionStatus, data: profession_types, refetch: refetchProfession } = useQuery(['profession_types'], fetchProfessions, { retry: false })
    const { status: rolesStatus, data: roles, refetch: refetchRoles } = useQuery(['roles'], fetchRoles, { retry: false })

    const [state, send] = useMachine(fetchMachine, { context: { originalData: data, formData: data, tagData: data ? data.TAG : null, tagFormData: data ? data.TAG : null } })
    const { formData = {}, originalData = {}, tagData = null, tagFormData = null } = state.context;

    useEffect(() => {
        if (data) {
            send('DATA_CHANGED', { data: data })
        }
    }, [data])

    return (
        <UsersContext.Provider value={{ state, send }}>
            <React.Fragment>
                {state.matches('updating') &&
                    <ConfirmUsersUpdateModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('users'); }} />}
                {state.matches('deleting') &&
                    <ConfirmUsersDeleteModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { props.history.push({ pathname: `/users` }); queryCache.invalidateQueries('users'); }} />}
                {state.matches('hiding_alerts') &&
                    <ConfirmHideAlertsModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('users'); }} />}
                {state.matches('showing_alerts') &&
                    <ConfirmShowAlertsModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('users'); }} />}
                {state.matches('attaching_tag') &&
                    <ConfirmAttachTagModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('users'); }} />}
                {state.matches('deattaching_tag') &&
                    <ConfirmDeattachTagModal
                        isOpen={true}
                        onCancel={() => send('CANCEL')}
                        onSuccess={() => { queryCache.invalidateQueries('users'); }} />}
                <div className="d-xs-block d-lg-none mb-2"><Link to="/users">{"< Ver Todas los Usuarios"}</Link></div>
                
                {status === "success" && 
                    tagData &&
                    !(moment(data.ALERT_DELAY) > moment()) &&
                    moment(tagData.LAST_RAW_DATA_DATE || "") < moment().subtract(7, 'days') &&
                    <div className="alert alert-contrast alert-warning mb-2" role="alert">
                        <div className="icon"><Battery40Icon /></div>
                        <div className="message">
                            <strong>Sin batería/Inactivo.</strong> Este Usuario ha estado inactivo desde el <b>{`${moment(tagData.LAST_RAW_DATA_DATE).format("DD MMMM")}`}</b>. <br />Si desea <b>deshabilitar temporalmente</b> sus alertas, haga click <Button className="p-0 ml-1" color="link" onClick={() => send('HIDE_ALERTS')}>aquí</Button>
                        </div>
                    </div>}
                {status === "success" && 
                    tagData &&
                    (moment(data.ALERT_DELAY) > moment()) &&
                    moment(tagData.LAST_RAW_DATA_DATE || "") < moment().subtract(7, 'days') &&
                    <div className="alert alert-contrast alert-dark mb-2" role="alert">
                        <div className="icon"><BellSleepIcon /></div>
                        <div className="message">
                           Las alertas de este Usuario están desactivadas hasta el <strong>{`${moment(formData.ALERT_DELAY).format("DD MMMM")}`}</strong><br/>Si desea <b>reactivar</b> sus alertas, haga click <Button className="p-0 ml-1" color="link" onClick={() => send('SHOW_ALERTS')}>aquí</Button>
                        </div>
                    </div>}
                <Card>
                    <CardHeader>
                        <CardTitle>
                            Editar Usuario
                            <Can I="delete" a="USERS">
                                <UncontrolledButtonDropdown className="float-right">
                                    <DropdownToggle caret color="link">
                                        <DotsVerticalIcon className="icon" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem color="danger" className="float-right" disabled={!state.matches('idle') || isFetching || error !== null} onClick={() => send('DELETE')}>
                                            <TrashCanIcon size={15} className="icon" />Eliminar
                                        </DropdownItem >
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Can>
                        </CardTitle>
                    </CardHeader>
                    {(status === "loading" || professionStatus === "loading" || rolesStatus === "loading") &&
                        <LoadingContainer isLoading={true}>
                            <div style={{ minHeight: '400px' }}></div>
                        </LoadingContainer>}
                    {(status === "error" || professionStatus === "error" || rolesStatus === "error") &&
                        <LoadingContainer
                         hasError={true} 
                        onRetry={() => {
                            refetch({ force: true })
                            refetchProfession({ force: true })
                            refetchRoles({ force: true })
                            }}>
                            <div style={{ minHeight: '400px' }}></div>
                        </LoadingContainer>}
                    {(status === "success" && professionStatus === "success" && rolesStatus === "success") &&
                        <React.Fragment>

                            <Tabs className="tab-container" defaultIndex={0}>
                                <TabList style={{ display: 'flex', cursor: 'pointer' }} className="nav nav-tabs nav-tabs-classic">
                                    <Tab style={{ flex: 1 }} className="nav-item" selectedClassName="nav-item-active" disabledClassName="nav-item-disabled"><a className={`nav-link`} ><InformationIcon className="icon" size={18} /><div className="d-inline-block">General</div></a></Tab>
                                    <Tab style={{ flex: 1 }} className="nav-item" selectedClassName="nav-item-active" disabledClassName="nav-item-disabled"><a className={`nav-link`} ><TagIcon className="icon" size={18} /><div className="d-inline-block">Tag</div></a></Tab>
                                </TabList>
                                <CardBody>
                                    <TabPanel className="pt-2">
                                        <BasicEditForm onValidSubmit={() => send('UPDATE')}>
                                            <Row form>
                                                <Col xs={12} sm={6}>
                                                    <FormGroup>
                                                        <Label>Nombre(s) *</Label>
                                                        <Input required type="text" name="NAME" value={formData.NAME || ""} autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <FormGroup>
                                                        <Label>Apellido(s) *</Label>
                                                        <Input required type="text" name="LAST_NAME" value={formData.LAST_NAME || ""} autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12}>
                                                    <FormGroup>
                                                        <Label>Profesión *</Label>
                                                        <Input required type="select" name="ID_PROFESSION_TYPE" value={formData.ID_PROFESSION_TYPE || ""} onChange={(event) => { send('SELECT_CHANGE', { value: event.target.value, name: event.target.name }) }} autoComplete="off">
                                                            <option value="" disabled> Seleccione una Profesión</option>
                                                            {profession_types.map((element) => {
                                                                return <option key={element.ID_PROFESSION_TYPE} value={element.ID_PROFESSION_TYPE}>{element.NAME}</option>
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <FormGroup>
                                                        <Label>Email</Label>
                                                        <Input required={formData.EMAIL !== "" || formData.EMAIL === null} type="email" name="EMAIL" value={formData.EMAIL || ""} autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <FormGroup>
                                                        <Label>Teléfono</Label>
                                                        <Input type="tel" name="PHONE" value={formData.PHONE || ""} autoComplete="off" onChange={(event) => { send('TYPING', { value: event.target.value, name: event.target.name }) }} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12}>
                                                    <FormGroup>
                                                        <Label>Roles</Label>
                                                        {roles.map(role => {
                                                            return <FormGroup key={role.ID_ROLE || ""} check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        name="ROLES"
                                                                        checked={formData.ROLES ? formData.ROLES.indexOf(parseInt(role.ID_ROLE, 10)) > -1 ? true : false : false}
                                                                        id={`${role.ID_ROLE}`}
                                                                        onChange={(event) => send({ ...event, type: 'CHECKBOX_CHANGE' })}
                                                                    />
                                                                    {role.NAME}
                                                                </Label>
                                                            </FormGroup>
                                                        })}
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12}>
                                                    {JSON.stringify(originalData) !== JSON.stringify(formData) &&
                                                        <Can I="update" a="USERS"><Button disabled={isFetching} className="mt-3" block color="primary">Guardar Cambios</Button></Can>
                                                    }
                                                </Col>
                                            </Row>
                                        </BasicEditForm>
                                    </TabPanel>
                                    <TabPanel>
                                        <Row form>
                                            <Col xs={12}>
                                                <FormGroup>
                                                    <Label>Tag Address</Label>
                                                    <InputGroup>
                                                        <Input required type="text"
                                                            disabled={tagData === null ? false : true}
                                                            name="BLE_ADDRESS"
                                                            value={tagFormData ? tagFormData.BLE_ADDRESS : ""}
                                                            onChange={(event) => { send('TYPING_TAG', { value: event.target.value, name: event.target.name }) }}
                                                            autoComplete="off" />
                                                        <InputGroupAddon addonType="append">
                                                            {formData.TAG ?
                                                                <Button onClick={() => send('DEATTACH_TAG')} color="danger">
                                                                    Deslindar
                                                            </Button> :
                                                                <Button onClick={() => send('ATTACH_TAG')} color="success">
                                                                    Asignar
                                                            </Button>}
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                                {formData.TAG &&
                                                    <FormGroup>
                                                        <Label>Última actualización</Label>
                                                        <CardTitle>{`${tagData ? moment(tagData.LAST_RAW_DATA_DATE).format("DD-MMM-YYYY HH:mm:ss") : ""}`}</CardTitle>
                                                    </FormGroup>}
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                </CardBody>
                            </Tabs>
                        </React.Fragment>}
                </Card>
            </React.Fragment>
        </UsersContext.Provider>
    )
}

export default UsersEdit
