import React from 'react'
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Row, Col, Container ,Card,CardHeader} from 'reactstrap'
import { titleCase } from './utilities'
import trophy from "../../assets/img/trophy.png"

const TopSlide = (props) => {
    return (
        <Container style={{ height: '100%' }}>
            <Card style={{height:'100%'}}>
                <Row style={{height:'100%'}}>
                    <Col xs={5} style={{ background: 'linear-gradient(to top left, #24c7a6 -50%, #020087 100%)', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CardHeader>
                            <p className="text-center" style={{fontSize: '1.35vw',color:'white'}}>
                                <b>{props.title}</b>
                            </p>
                            {props.subtitle ? props.subtitle.map((sub, index) => {
                                return <p style={{fontSize: '1.1vw',color:'white',marginBottom:'0px'}} className="text-center font-weight-light" key={index}>{sub}</p>
                            }) : null}
                        </CardHeader> 
                        <div style={{width:'60%' }}>
                            <img src={trophy} alt="" style={{ maxWidth: '100%',objectFit:'contain' }}></img>
                        </div>
                    </Col>
                    <Col xs={7} style={{height:'100%',display:'flex'}}>
                        <div style={{marginRight:'auto',marginLeft:'auto',maxWidth:'80%', height:'80%',display:'flex',flexDirection:'column',justifyContent:'space-between',alignSelf:'center'}}>
                            <p style={{ marginTop: '5px',fontSize: '1.1vw', lineHeight: '110%' }}>
                                El HIMFG agradece el esfuerzo del personal que ha tenido un desempeño destacado en el programa <b>Vamos Por el Cien</b> en este departamento.
                            </p>
                            <div style={{flex:6,display:'flex',flexDirection:'column',justifyContent:'space-evenly',alignItems:'center'}}>
                                 {props.data.slice(0, 5).map((element, index) => {
                                    return <div key={index} style={{display:'flex',alignItems:'baseline',width:'70%'}}>
                                        <div className="mr-2" style={{
                                            borderRadius: '50%',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center',
                                            width: '3vw',
                                            height: '3vw',
                                            background: '#fff',
                                            border: '2px solid #666',
                                            color: '#666',
                                            textAlign: 'center',
                                            fontSize: '1.35vw'
                                        }}>{index + 1}</div>
                                        <p style={{fontSize: '1.35vw'}}>{titleCase(element.NAME)}</p>
                                    </div>
                                })}
                            </div>
                            <div style={{fontSize: '1.1vw',textAlign:'center'}}>
                                <div>
                                    Todos juntos, estamos cada vez mas cerca de la meta
                                    </div>
                                <div>
                                    <b>¡MUCHAS FELICIDADES!</b>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card>
        </Container>
    )
}

export default TopSlide