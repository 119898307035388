import React, { useEffect, useState } from 'react'
import useRequestReducer from './hooks/useRequestReducer';

const AuthContext = React.createContext()

function AuthProvider(props) {
    const [data, setData] = useState({})
    const [abilities, setAbilities] = useState([]);

    const [postLogin, dispatchPostLogin] = useRequestReducer({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/login`
    })

    useEffect(() => {
        if (postLogin.response) {
            localStorage.setItem('auth_token', postLogin.response.data.token);
            setData({ user: postLogin.response.data.user })
            setAbilities(postLogin.response.data.permissions)
            const { from } = props.location.state || { from: { pathname: "/" } };
            props.history.push({ pathname: from.pathname })
        }
    }, [postLogin.response])

    // code for pre-loading the user's information if we have their token in localStorage goes here
    const [validateToken] = useRequestReducer({
        method: 'get',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/token-verify`
    })

    useEffect(() => {
        if (validateToken.response) {
            setData({ user: validateToken.response.data.user })
            setAbilities(validateToken.response.data.permissions)
            const { from } = props.location.state || { from: { pathname: props.location.pathname } };
            props.history.push({ pathname: from.pathname })
        }
    }, [validateToken.response, validateToken.error])

    // 🚨 this is the important bit.
    // Normally your provider components render the context provider with a value.
    // But we post-pone rendering any of the children until after we've determined
    // whether or not we have a user token and if we do, then we render a spinner
    // while we go retrieve that user's information.

    if (validateToken.request && !validateToken.response && !validateToken.error) {
        return <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Cargando...</div>
    }

    const login = (loginData) => {
        dispatchPostLogin({ type: 'SEND_REQUEST', request: { data: { ...loginData } } })
    } // make a login request
    const logout = () => {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('reportes_filter')
        localStorage.removeItem('eventos_filter')
        props.history.push({ pathname: '/' })
        setData({})
    } // clear the token in localStorage and the user data

    // note, I'm not bothering to optimize this `value` with React.useMemo here
    // because this is the top-most component rendered in our app and it will very
    // rarely re-render/cause a performance problem.
    return (
        <AuthContext.Provider value={{ data, login, logout, abilities, postLogin }} {...props} />
    )
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }

