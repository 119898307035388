import React, { useState, useEffect, useRef } from 'react'
import avatar from '../assets/img/avatar.png'
import { useAuth } from '../AuthContext'
import {useUser} from '../UserContext'
import {Link} from 'react-router-dom'
import { AccountSettingsIcon } from 'mdi-react';

function UserMenu(props) {
    const [showMenu, setShowMenu] = useState(false);
    const node = useRef(null);
    const auth=useAuth();
    const user= useUser();
    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false)
        return () => document.removeEventListener('mousedown', handleClick, false)
    }, [])

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }

    const handleClick = (e) => {
        if (!node.current.contains(e.target)) {
            setShowMenu(false)
        }
    }

    const logoutButton = () => {
        auth.logout();
    }

    return (
        <li className={showMenu ? "nav-item dropdown show" : "nav-item dropdown"} ref={node}>
            <a className="nav-link dropdown-toggle" role="button" onClick={toggleMenu}>
                <img src={avatar} alt="Avatar" />
                <span className="user-name">{`${user.NAME} ${user.LAST_NAME} `}</span>
            </a>
            <div className={showMenu ? "dropdown-menu show" : "dropdown-menu"} role="menu">
                <div className="user-info">
                    <div className="user-name">{`${user.NAME} ${user.LAST_NAME} `}</div>
                </div>
                <Link className="dropdown-item" to='/account-settings' onClick={()=>setShowMenu(false)}><AccountSettingsIcon className="icon" />Ajustes de Cuenta</Link>
                <div className="p-1">
                    <button className="btn btn-danger btn-block" onClick={logoutButton}>Cerrar Sesión</button>
                </div>
            </div>
        </li>
    )
}

export default UserMenu