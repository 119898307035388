import React from 'react';
import Dashboard from './pages/Dashboard/Dashboard';
import { Route, Switch,Redirect } from "react-router-dom"
import Login from "./pages/Login"
import ResetPassword from "./pages/ResetPassword"
import ForgotPassword from "./pages/ForgotPassword"
import { useUser } from './UserContext'
import CarouselScreen from './pages/CarouselScreen';
import Carousel from './pages/Carousel/Carousel';
import { ReactQueryDevtools } from 'react-query-devtools'
import DeactivateTag from './pages/DeactivateTag';

const App = (props) => {
  const user = useUser()
  
  return (
      <React.Fragment>
      <ReactQueryDevtools initialIsOpen={false} />
      <Switch>
        <Route exact path='/deactivate' component={DeactivateTag} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/reset-password/:idUser' component={ResetPassword} />
        <Route path='/tv/:idDepartment' render={(props)=>user?<Carousel {...props}/>:<Redirect to={{pathname: "/login",state: { from: props.location }}} />}/>
        <Route path='/' render={()=>user?<Dashboard {...props}/>:<Redirect to={{pathname: "/login",state: { from: props.location }}} />} />
      </Switch>
      </React.Fragment>
  );
}

export default App;



