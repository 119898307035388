import React, { Component } from 'react'

export class NoDataIndicator extends Component {
    render() {
        return (
            <div>
                <h2 className="text-center">Sin Resultados</h2>
                <p className="text-center">Intente con un criterio de busqueda distinto</p>
            </div>
        )
    }
}

export default NoDataIndicator
