import React, { Component } from 'react'
import './nodata.css';
import './haserror.css';
import { Button } from 'reactstrap'

export class LoadingContainer extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.hasError ?
                    <div className={"be-error " + (this.props.hasError === true ? " be-error-active" : "")}>
                        {this.props.children}
                        <div className="be-message-error">
                            <div>Ocurrio un Error</div>
                            <br />
                            <Button color="primary" block onClick={() => this.props.onRetry()}>Reintentar</Button>
                        </div>
                    </div>
                    : (
                        this.props.isEmpty ?
                            <div className={"be-empty " + (this.props.isEmpty === true ? " be-empty-active" : "")}>
                                {this.props.children}
                                <div className="be-message">
                                    No hay Datos disponibles
                        </div>
                            </div>
                            :
                            <div className={"be-loading " + (this.props.isLoading === true ? " be-loading-active" : "")}>
                                {this.props.children}
                                <div className="be-spinner">
                                    <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://-www.w3.org/2000/svg">
                                        <circle fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="30" className="circle"></circle>
                                    </svg>
                                </div>
                            </div>)}
            </React.Fragment>
        )
    }
}

export default LoadingContainer