
import React from "react";
import DashboardLayout from "../../components/DashboardLayout"
import Menu from "./Menu";
import Main from "./Main"

function Dashboard(props) {
    return (
        <DashboardLayout
            left_bar={<Menu {...props}/>}
            content={<Main {...props}/>}
        />)
}
export default Dashboard;
