import React, { useState, useEffect } from "react";
import "../assets/css/app.css"
import logo from "../assets/img/aidy_white.png"
import { LoadingContainer } from "../components/LoadingContainer";
import { BasicEditForm } from '../components/BasicEditForm';
import { Card, CardHeader, CardBody, FormGroup, Input, Button, Label, FormFeedback } from 'reactstrap'
import { CloseCircleOutlineIcon } from 'mdi-react';
import useRequestReducer from "../hooks/useRequestReducer";
import background from "../assets/img/login-background.jpg"
import querySearch from "stringquery";

function ResetPassword(props) {
    const [data, setData] = useState({ password: "", password2: "", token: querySearch(props.location.search).t });
    const [match, setMatch] = useState(true)
    const [changed, setChanged] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name]: value
        })
    };

    useEffect(() => {
        if (data.password === data.password2)
            setMatch(true)
        else
            setMatch(false)
    }, [data.password, data.password2])


    const [changePassword, dispatchChangePassword] = useRequestReducer({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/reset-password/${props.match.params.idUser}`
    });

    useEffect(() => {
        if (changePassword.response) {
            setChanged(true);
        }
    }, [changePassword.response]);

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflow: 'auto',
            background: 'black'
        }}>
            <img src={background} alt="" style={{ height: '100vh', width: '100%', objectFit: 'cover', opacity: 0.5 }} />
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: "0", left: "0" }}>
                <div className="splash-container forgot-password">
                    <Card>
                        <div style={{ color: 'white', background: 'linear-gradient(to top left, #24c7a6 0%, #020087 100%)', minHeight: '70px' }}>
                            <CardHeader>
                                <img className="logo-img" src={logo} alt="logo" width="225" height="#{conf.logoHeight}"></img>
                                <span className="splash-description" style={{ color: 'white' }}>Crear Nueva Contraseña</span>
                            </CardHeader>
                        </div>
                        <LoadingContainer isLoading={changePassword.loading}>
                            {changed ?
                                <CardBody className="text-center">
                                    <Label>La Contraseña ha sido cambiada satisfactoriamente</Label>
                                    <Button color="success" size="xl" block onClick={() => props.history.push({ pathname: `/login` })}>Iniciar Sesión</Button>
                                </CardBody> :
                                <CardBody>
                                    {changePassword.error ?
                                        <div className="alert alert-danger alert-simple alert-dismissible" role="alert">
                                            <button className="close" type="button" data-dismiss="alert" aria-label="Close"><span className="mdi mdi-close" aria-hidden="true"></span></button>
                                            <div className="icon"> <CloseCircleOutlineIcon /></div>
                                            <div className="message"><strong>{changePassword.error.data.message}</strong></div>
                                        </div>
                                        : null}
                                    <BasicEditForm
                                        onValidSubmit={() => {
                                            if (match === true) {
                                                console.log("Eviando..")
                                                dispatchChangePassword({ type: 'SEND_REQUEST', request: { url: `${process.env.REACT_APP_API_ENDPOINT}/api/reset-password/${props.match.params.idUser}`, data: { ...data } } })
                                            }
                                        }}>
                                        <FormGroup row>
                                            <Input
                                                className="text-center"
                                                type="password"
                                                placeholder="Nueva Contraseña"
                                                name="password"
                                                value={data.password}
                                                onChange={handleInputChange}
                                                required />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Input
                                                className="text-center"
                                                type="password"
                                                placeholder="Confirmar Contraseña"
                                                name="password2"
                                                value={data.password2}
                                                onChange={handleInputChange}
                                                required
                                                invalid={!match}
                                            />
                                            <FormFeedback>Las Contraseñas no coinciden</FormFeedback>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Button color="success" size="xl" block>Confirmar</Button>
                                        </FormGroup>
                                    </BasicEditForm>
                                </CardBody>}
                        </LoadingContainer>
                    </Card>
                    <div className="splash-footer">&copy; 2018 Aidy Technologies</div>
                </div>
            </div>
        </div>)
}

export default ResetPassword;