import React, { useState, useEffect } from "react";
import { LoadingContainer } from "../../../../components/LoadingContainer";
import { BasicEditForm } from "../../../../components/BasicEditForm";
import { ModalBody, ButtonGroup, FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import useRequestReducer from "../../../../hooks/useRequestReducer";

require("react-input-range/lib/css/index.css");

function Filter(props) {
  const { state, send } = props;
  const { departments = [], professions = [], filter = {} } = state.context;
  const { department="", profession="", oportunity="", view="total" } = filter;
  return (
    <React.Fragment>
      <BasicEditForm onValidSubmit={() => send("LOL")}>
        <ModalBody>
          <LoadingContainer isLoading={state.matches("loading")}>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label>Vista</Label>
                  <br />
                  <ButtonGroup style={{ display: "flex" }}>
                    <Button style={{ flex: 1 }} size="md" active={view=='total'} onClick={() => send("VIEW_CHANGED", { name: "view", value: "total" })}>
                      Total
                    </Button>
                    <Button style={{ flex: 1 }} size="md" active={view=='percentage'} onClick={() => send("VIEW_CHANGED", { name: "view", value: "percentage" })}>
                      Porcentaje
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label>Tipo de Momento</Label>
                  <Input name="oportunity" type="select" value={oportunity} onChange={(event) => send("VIEW_CHANGED", { name: event.target.name, value: event.target.value })}>
                    <option value="">Todas las Oportunidades</option>
                    <option key={1} value={"in"}>
                      (1) Oportunidades de Entrada
                    </option>
                    <option key={2} value={"out"}>
                      (5) Oportunidades de Salida
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label>Departamento</Label>
                  <Input name="department" type="select" value={department} onChange={(event) => send("SELECT_CHANGED", { name: event.target.name, value: event.target.value })}>
                    <option value="">Todas los Departamentos</option>
                    {departments.map((department) => {
                      return (
                        <option key={department.ID_DEPARTMENT} value={department.ID_DEPARTMENT}>
                          {department.NAME}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label>Tipo de Usuario</Label>
                  <Input name="profession" type="select" value={profession} onChange={(event) => send("SELECT_CHANGED", { name: event.target.name, value: event.target.value })}>
                    <option value="">Todos los Tipos</option>
                    {professions.map((profession) => {
                      return (
                        <option key={profession.ID_PROFESSION_TYPE} value={profession.ID_PROFESSION_TYPE}>
                          {profession.NAME}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              {/*<Col xs={12}>
                <FormGroup>
                  <Button block color="primary" onClick={()=>send('FILTER_SUBMIT')}>
                    Aplicar Filtros
                  </Button>
                </FormGroup>
                </Col>*/}
            </Row>
          </LoadingContainer>
        </ModalBody>
      </BasicEditForm>
    </React.Fragment>
  );
}

export default Filter;
