import React from "react";
import "./Map.css"

function Map(props) {
    return (
        <div className="map-container" style={{'paddingTop': `${(props.sizey/props.sizex)*100}%`}}>
            <div className="map-background" style={{backgroundImage: `url(${props.mapImageURL})`}}>
                {props.children}
            </div>
        </div>
    );
}

export default Map;