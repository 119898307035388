import React, { Component } from 'react'

export class BasicEditForm extends Component {
    state = {
        wasValidated: false,
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ wasValidated: true });
        if (!event.target.checkValidity()) {
            console.log("La Form es invalida")
            return;
        }
        console.log("La Form es valida")
        this.props.onValidSubmit();
        this.setState({ wasValidated: false });
    }

    render() {
        return (
            <form {...this.props} className={"needs-validation" + (this.state.wasValidated === true ? " was-validated" : "")} noValidate onSubmit={this.handleSubmit}>
                {this.props.children}
            </form>
        )
    }
}

export default BasicEditForm
